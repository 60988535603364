import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import SettingTabs from 'components/settingTabs/SettingTabs';
import { DashboardLayout } from 'layouts';
import { SETTINGS } from 'utils/constants';
import { getProfileSettingsMenuItems } from 'utils/helpers/settingsHelpers';
import { usePageTitle } from 'utils/hooks';

function EditProfileTabs() {
  usePageTitle(SETTINGS);
  const location = useLocation();
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    return getProfileSettingsMenuItems(t);
  }, [t]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <div className="settings-container">
      <SettingTabs.MobileMenu menuItems={menuItems} />
      <DashboardLayout.DashboardLayoutHeader
        title={t('profile.title')}
        subTitle={t('profile.subTitle')}
      />
      <SettingTabs>
        <SettingTabs.DesktopMenu menuItems={menuItems} />
        <SettingTabs.Content>
          <Outlet />
        </SettingTabs.Content>
      </SettingTabs>
    </div>
  );
}

export default EditProfileTabs;
