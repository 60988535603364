import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Filters } from 'components';
import { DATE_RANGE_FILTER } from 'components/filters/filterTypes';
import { Icon } from 'components/ui';

function SelectedComparisonFilter({
  comparisonMethod,
  filterValue,
  filterIndex,
  selectedComparisonFilters,
  onChangeSelectedComparisonFilter,
}) {
  const { t } = useTranslation();
  const disabledItems = useMemo(() => {
    const items = {};
    selectedComparisonFilters.forEach((item, index) => {
      if (item.value && filterIndex !== index) {
        items[item.value] = true;
      }
    });
    return items;
  }, [selectedComparisonFilters]);

  const onFilterChange = (value) => {
    const filters = [...selectedComparisonFilters];
    filters[filterIndex] = value[comparisonMethod.id];
    onChangeSelectedComparisonFilter(filters);
  };

  const onRemoveFilter = (e) => {
    e?.stopPropagation();
    const filters = [...selectedComparisonFilters];
    filters.splice(filterIndex, 1);
    onChangeSelectedComparisonFilter(filters);
  };
  const filter = { [comparisonMethod.id]: filterValue };
  return (
    <Filters
      filtersCommonClassName="full-width"
      addMainWrapper={false}
      addControlsWrapper={false}
      disabledItems={disabledItems}
      isRemoveable={selectedComparisonFilters.length > 2}
      onRemove={onRemoveFilter}
      value={filter}
      onChange={onFilterChange}
      isRoundedDropDown
      isClearable={false}
      branchPlaceholder={`${t('filters.chooseLocation')} ${filterIndex + 1}`}
      experiencePlaceholder={`${t('filters.chooseExperience')} ${
        filterIndex + 1
      }`}
      regionPlaceholder={`${t('filters.chooseRegion')} ${filterIndex + 1}`}
      dateRangePlaceholder={
        filter[DATE_RANGE_FILTER]?.label ?? t('filters.dateRange')
      }
    />
  );
}

export default function SelectedComparisonFilters({
  selectedComparisonFilters,
  onChangeSelectedComparisonFilter,
  addSelectedComparisonFilters,
  comparisonMethod,
}) {
  return (
    <>
      {selectedComparisonFilters.map((filter, index) => (
        <SelectedComparisonFilter
          key={index}
          filterIndex={index}
          filterValue={filter}
          selectedComparisonFilters={selectedComparisonFilters}
          onChangeSelectedComparisonFilter={onChangeSelectedComparisonFilter}
          comparisonMethod={comparisonMethod}
        />
      ))}
      {selectedComparisonFilters.length > 2 ? null : (
        <button
          type="button"
          onClick={addSelectedComparisonFilters}
          className="add-comparison-filter"
        >
          <Icon type="add" />
        </button>
      )}
    </>
  );
}
