import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import { Modal } from 'antd';
import _ from 'lodash';

import { chartColors } from 'utils/constants';
import {
  calculatePointsRadius,
  convertToGraphData,
  generateRandomNumber,
} from 'utils/helpers/candleStickHelpers';

import './_charts.scss';

/**
 * Expected payload
 *
const series = [
    {
        data: [
            {
                y: 'A',
                x: {[star]:count,...},
                rating_stats: {min: 3, q1: 3, median: 3, mean: 3, q3: 4, max: 4},
            }
        ]
    }
]
 */

const getChartWidth = () => {
  const chart = document.querySelector('.chartCard .chartContainer');
  if (chart)
    return (
      document.querySelector('.chartCard .chartContainer').clientWidth - 15.2 // Padding width = 15.2
    );
  return 0;
};

export default function CandleStickChart({
  data,
  onClick,
  onModalClose,
  lineColor = '#ccc',
  xAxis = [1, 5],
  showModal = false,
  title,
  xLabel = '',
  yLabel = '',
}) {
  const { t } = useTranslation();
  const ref = useRef();
  const modalRef = useRef();
  const randomId = useRef(generateRandomNumber(1, 100));
  const [chartWidth, setChartWidth] = useState(getChartWidth());
  const margin = {
    top: 5,
    right: 50,
    bottom: 50,
    left: 30,
  };

  const paneWidth = chartWidth;
  const paneHeight = 300 - margin.top - margin.bottom;

  const drawGraph = (id, width, height) => {
    const graphData = convertToGraphData(data);

    // const color = d3.scaleSequential().interpolator(d3.interpolateInferno).domain([4, 8]);
    const maxItemRadius = 8;
    const x = d3.scaleLinear().domain(xAxis);
    const y = d3
      .scaleBand()
      .range([height, 0])
      .domain(data.map((obj) => obj.y).reverse())
      .padding(0.4);

    const tooltip = d3
      .select(id)
      .append('div')
      .style('display', 'none')
      .attr('class', 'graph-tooltip')
      .style('font-size', '12px');

    const svg = d3
      .select(id)
      .append('svg')
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom);

    const chartArea = svg.append('g');

    // x-axis legend
    svg
      .append('g')
      .attr('transform', `translate(${width / 2 - 40},${height + 50})`)
      .append('text')
      .style('font-size', '0.75rem')
      .text(xLabel);

    // y-axis
    chartArea
      .append('g')
      .attr('id', `${title}-vertical-options`)
      .attr('transform', 'translate(0,10)')
      .attr('class', 'y-axis')
      .call(d3.axisRight(y).tickSize(0))
      .select('.domain')
      .remove();

    setTimeout(() => {
      const verticalOptions = document.getElementById(
        `${title}-vertical-options`,
      );
      if (verticalOptions) {
        const verticalOptionsWidth =
          verticalOptions.getBoundingClientRect().width;
        x.range([verticalOptionsWidth + 10, width - 15]);
      }

      // x-axis
      chartArea
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x).ticks(5))
        .select('.domain')
        .remove();
      // y-axis legend
      svg
        .append('g')
        .attr('transform', `translate(-40,${height / 2 + 20})`)
        .append('text')
        .style('font-size', '0.75rem')
        .style('transform', 'rotate(270deg)')
        .text(yLabel);

      chartArea
        .selectAll('vertLines')
        .data(graphData)
        .enter()
        .append('line')
        .attr('x1', (d) => x(d.value.min))
        .attr('x2', (d) => x(d.value.max))
        .attr('y1', (d) => y(d.key) + y.bandwidth() / 2)
        .attr('y2', (d) => y(d.key) + y.bandwidth() / 2)
        .attr('stroke', lineColor)
        .style('width', 80);

      // rectangle for the main box
      chartArea
        .selectAll('boxes')
        .data(graphData)
        .enter()
        .append('rect')
        .attr('x', (d) => x(d.value.q1))
        .attr('width', (d) => x(d.value.q3) - x(d.value.q1))
        .attr('y', (d) => y(d.key))
        .attr('height', y.bandwidth())
        .attr('stroke', lineColor)
        .style('fill', (d, index) => {
          return chartColors[index % chartColors.length];
        })
        .style('opacity', 0.8)
        .on('mouseover', function (d) {
          tooltip
            .transition()
            .duration(200)
            .style('display', 'flex')
            .style('height', 'auto');
          tooltip
            .html(
              `<span><strong>${d.key}</strong></span>\
          <span>${t('min')}: ${d.value.min} </br> 25%: ${d.value.q1} </br> ${t(
                'mean',
              )}: ${d.value.mean} </br> 75%: ${d.value.q3} </br> ${t('max')}: ${
                d.value.max
              }</span>`,
            )
            .style('left', `${d3.mouse(this)[0] + 40}px`)
            .style('top', `${d3.mouse(this)[1] - 135}px`)
            .on('mousemove', () => {
              tooltip.style('display', 'flex');
            });
        })
        .on('mousemove', function () {
          tooltip
            .style('left', `${d3.mouse(this)[0] + 40}px`)
            .style('top', `${d3.mouse(this)[1] - 135}px`);
        })
        .on('mouseleave', () => {
          tooltip.transition().duration(200).style('display', 'none');
        });

      // Show the mean
      chartArea
        .selectAll('meanLines')
        .data(graphData)
        .enter()
        .append('line')
        .attr('y1', (d) => y(d.key))
        .attr('y2', (d) => y(d.key) + y.bandwidth() / 2)
        .attr('x1', (d) => x(d.value.mean))
        .attr('x2', (d) => x(d.value.mean))
        .attr('stroke', lineColor)
        .style('width', 80);

      for (let ind = 0; ind < data.length; ind += 1) {
        const i = data[ind];
        const pointsRadiuses = calculatePointsRadius(i.x, maxItemRadius);

        chartArea
          .selectAll('indPoints')
          .data(Object.keys(i.x))
          .enter()
          .append('circle')
          .attr('fill', '#51546F')
          .attr('cx', (d) => x(d))
          .attr('cy', () => y(i.y) + y.bandwidth() / 2)
          .attr('r', (d) => {
            const minR = maxItemRadius / Object.keys(i.x).length;
            return pointsRadiuses[d] || minR;
          })
          .style('cursor', 'pointer')
          .on('click', (d) => onClick({ label: i.y, value: d }))
          .on('mouseover', function (d) {
            tooltip.transition().duration(200).style('display', 'flex');
            tooltip.html(
              `<span>${t('count')}: ${i.x[d]}</span><span><strong>${
                i.y
              }</strong></span>`,
            );
            tooltip
              .style('left', `${d3.mouse(this)[0] + 30}px`)
              .style('top', `${d3.mouse(this)[1] - 74}px`)
              .on('mousemove', () => {
                tooltip.style('display', 'flex');
              })
              .on('mouseover', () => {
                tooltip.style('display', 'flex');
              });
          })
          .on('mousemove', function () {
            tooltip
              .style('left', `${d3.mouse(this)[0] + 30}px`)
              .style('top', `${d3.mouse(this)[1] - 74}px`);
          })
          .on('mouseleave', () => {
            tooltip.transition().duration(200).style('display', 'none');
          });
      }
    }, 0);

    // adds ... after the sliced text
    function formatLabel() {
      const self = d3.select(this);
      const text = self.text();
      self.text(`${text.slice(0, 23)}${text.length > 23 ? '...' : ''}`);
    }

    // format labels on y axis
    svg
      .selectAll('.y-axis>.tick>text')
      .style('text-anchor', 'start')
      .each(formatLabel);

    // tooltips on y axis
    svg
      .selectAll('.y-axis>.tick>text')
      .append('title')
      .text((d) => {
        return d;
      });
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (showModal) {
        if (modalRef.current) modalRef.current.innerHTML = '';
        drawGraph(
          `#modal_my_dataviz_${randomId.current}`,
          paneWidth,
          paneHeight,
        );
      }
      if (ref.current) ref.current.innerHTML = '';
      drawGraph(`#my_dataviz_${randomId.current}`, paneWidth, paneHeight);
    }
    return () => {
      if (ref.current) ref.current.innerHTML = '';
      if (modalRef.current) modalRef.current.innerHTML = '';
    };
  }, [data, showModal, chartWidth]);

  // resize chart on window resize
  useEffect(() => {
    let timer = null;
    window.addEventListener('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setChartWidth(getChartWidth());
      }, 1000);
    });
  }, []);

  if (_.isEmpty(data)) return <span>{t('noDataFound')}</span>;

  return (
    <>
      {showModal ? (
        <Modal
          bodyStyle={{ overflow: 'auto', maxHeight: '70vh' }}
          open={showModal}
          onCancel={onModalClose}
          closable
          footer={null}
        >
          <div id={`modal_my_dataviz_${randomId.current}`} ref={modalRef} />
        </Modal>
      ) : null}
      <div
        id={`my_dataviz_${randomId.current}`}
        className="dataviz-chart"
        ref={ref}
      />
    </>
  );
}
