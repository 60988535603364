import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { postComment } from 'redux/reviewsListing/reviewsListingActions';

import ReviewComment from './ReviewComment';

export default function ReviewComments({ comments, feedbackId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeComment, setActiveComment] = useState('');

  const handlePostComment = () => {
    if (activeComment) {
      dispatch(postComment({ feedbackId, data: { text: activeComment } }));
      setActiveComment('');
    }
  };

  return (
    <div className="feedback-comments">
      {comments.map((comment) => {
        return <ReviewComment key={comment.id} comment={comment} />;
      })}
      <textarea
        cols="30"
        rows="5"
        placeholder="Leave a comment"
        className="comment-input"
        value={activeComment}
        onChange={(event) => setActiveComment(event.target.value)}
      />
      <button
        className="button button-back button-primary feedback-header-controls-email-button"
        type="button"
        onClick={handlePostComment}
      >
        {t('postComment')}
      </button>
    </div>
  );
}
