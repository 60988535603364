import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';

import ExperienceDropdown from 'components/experienceDropdown/ExperienceDropdown';
import { Modal } from 'components/ui';
import { LocationAndRegionContext } from 'pages/locationsAndRegions/LocationsAndRegions';
import {
  getExperiencesByLocation,
  updateFormtoExperience,
} from 'redux/experience/experienceActions';
import {
  setCustomSurveyForms,
  setSurveyDetail,
  setTemplateSurveyForms,
} from 'redux/surveys/surveysSlice';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import FeedbackFormsByExperience from './FeedbackFormsByExperience';

const translationKey =
  'settings.locations.linkedExperiences.linkExperienceModal';

function LinkLocationExperienceModal({
  onClose,
  isOpen,
  linkedExperienceRef,
  entitiyLinkedExperiences,
  entitiyLinkedExperiencesHash,
  navigateToFeedbackFormDetailPage,
  setActiveExperinceKey,
  isOnboarding,
}) {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const dispatch = useDispatchWithErrors();
  const {
    allExperiences: { data: allExperiences },
  } = useSelector((s) => s.experience);

  const [linkExperienceLoading, setLinkExperienceLoading] = useState(false);
  const [selectedLinkedExperience, setSelectedLinkedExperience] = useState(
    linkedExperienceRef.current,
  );

  const prevSelectedSurveyId = useRef(linkedExperienceRef.current?.survey_id);
  const { fetchLocationsRef } = useContext(LocationAndRegionContext);

  const onCloseModal = () => {
    if (linkExperienceLoading) return;
    onClose();
  };

  const handleLinkExperience = async () => {
    let newExperienceAdded = true;
    const updatedLinkedExperiences = entitiyLinkedExperiences.map((exp) => {
      let expData = exp;
      if (exp.id === selectedLinkedExperience?.id) {
        newExperienceAdded = false;
        expData = selectedLinkedExperience;
      }
      return {
        experience: expData.experience.id,
        survey_id: expData.survey_id,
        id: expData.id,
      };
    });
    if (newExperienceAdded && selectedLinkedExperience)
      updatedLinkedExperiences.push({
        experience: selectedLinkedExperience.experience.id,
        survey_id: selectedLinkedExperience.survey_id,
      });
    setLinkExperienceLoading(true);
    const { isError } = await dispatch(
      updateFormtoExperience({
        locationId,
        data: updatedLinkedExperiences,
      }),
    );
    if (!isError) {
      const { data } = await dispatch(
        getExperiencesByLocation({
          entity_id: locationId,
          is_onboarding: isOnboarding,
        }),
      );
      const activeExperinceKey =
        selectedLinkedExperience?.id ?? data[0]?.entity_experiences[0]?.id;
      if (activeExperinceKey) {
        setActiveExperinceKey(`${activeExperinceKey}`);
      }
      if (!isOnboarding && fetchLocationsRef) {
        fetchLocationsRef.current = true;
      }
      if (
        isOnboarding &&
        prevSelectedSurveyId.current !== selectedLinkedExperience.survey_id
      ) {
        dispatch(setSurveyDetail(null));
      }
    }
    setLinkExperienceLoading(false);
    onClose();
  };

  const handleChangeExperienceType = ({
    value: experienceId,
    label: experienceName,
  }) => {
    let surveyId = null;
    let linkedExperienceId = null;
    const linkedExperienceData = entitiyLinkedExperiencesHash[experienceId];
    if (linkedExperienceData) {
      surveyId = linkedExperienceData.survey_id;
      linkedExperienceId = linkedExperienceData.id;
    }
    const newLinkedExperienceData = {
      experience: {
        id: experienceId,
        name: experienceName,
      },
      survey_id: surveyId,
      id: linkedExperienceId,
    };
    linkedExperienceRef.current = newLinkedExperienceData;
    setSelectedLinkedExperience(newLinkedExperienceData);
  };

  useEffect(() => {
    if (!selectedLinkedExperience && allExperiences.length) {
      const experience = allExperiences[0];
      const defaultSelectedSurvey = entitiyLinkedExperiencesHash[
        experience.id
      ] ?? { experience };
      linkedExperienceRef.current = defaultSelectedSurvey;
      setSelectedLinkedExperience(defaultSelectedSurvey);
    }
    return () => {
      dispatch(setCustomSurveyForms());
      dispatch(setTemplateSurveyForms());
    };
  }, []);

  return (
    <Modal
      className="location-experience-modal"
      onClose={onCloseModal}
      open={isOpen}
      backDropClose={false}
    >
      <Modal.Header
        title={t(`${translationKey}.title`)}
        onClose={onCloseModal}
      />
      <Modal.Body>
        <div className="location-experience-modal-body">
          <div className="all-experiences-dropdown-container">
            <h6>{t(`${translationKey}.allExperiences.title`)}</h6>
            <p className="mb-2">
              {t(`${translationKey}.allExperiences.subTitle`)}
            </p>
            <ExperienceDropdown
              fetchExperiences={false}
              handleChangeExperience={handleChangeExperienceType}
              selectedExperience={{
                value: selectedLinkedExperience?.experience?.id || undefined,
              }}
            />
          </div>
          <FeedbackFormsByExperience
            linkedExperience={selectedLinkedExperience}
            setLinkedExperience={setSelectedLinkedExperience}
            navigateToFeedbackFormDetailPage={navigateToFeedbackFormDetailPage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryAction={handleLinkExperience}
        primaryLabel="Save"
        disablePrimaryAction={
          linkExperienceLoading ||
          !selectedLinkedExperience?.experience ||
          isEmpty(selectedLinkedExperience?.experience) ||
          !selectedLinkedExperience.survey_id
        }
        isLoading={linkExperienceLoading}
      />
    </Modal>
  );
}

export default LinkLocationExperienceModal;
