import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { loginAPICall } from 'apis/authentication';
import { singleParentEntitySelectAPICall } from 'apis/cxmeter';
import {
  setParentEntity,
  setToken,
  setUser,
} from 'redux/authentication/authenticationSlice';
import { FORBIDDEN_CODE, URLS } from 'utils/constants';
import { storeParentEntity, storeTokens, storeUser } from 'utils/helpers';

export default function useLogin(callback, deps) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const postLogin = useCallback(async (user, token) => {
    storeTokens(token);
    storeUser(user);
    dispatch(setUser(user));
    if (user.is_superuser) {
      navigate(URLS.ORGANIZATION_URL, { replace: true });
    } else {
      const { orgs, selectedOrg } = await singleParentEntitySelectAPICall();
      if (orgs.length === 0) {
        navigate(URLS.REGISTER_ORG_URL, { replace: true });
        dispatch(setToken(token));
      } else if (selectedOrg) {
        dispatch(setParentEntity(selectedOrg));
        dispatch(setToken(token));
        ReactGA.set({ o_id: selectedOrg.id });
        storeParentEntity(selectedOrg);
        navigate(state?.redirectTo ?? URLS.DASHBOARD_URL, { replace: true });
      }
    }
  }, []);

  const login = useCallback(async (data, userInfo = null) => {
    try {
      setIsLoggingIn(true);
      if (!userInfo) userInfo = await loginAPICall(data);
      const { user } = userInfo;
      if (user == null) {
        setIsLoggingIn(false);
        return;
      }
      if (!user.is_active) {
        toast.error('Please verify your account');
        navigate(URLS.VERIFY_EMAIL_URL, { state: data });
        return;
      }
      await postLogin(user, userInfo.token);
    } catch (error) {
      let loginError = null;
      const { data: res, status } = error.response ?? {};
      // Special case when user is inactive
      if (res?.deactivated && status === FORBIDDEN_CODE) {
        loginError = { detail: res.message };
      } else {
        loginError = res;
      }
      setIsLoggingIn(false);
      throw loginError;
    }
  }, deps);

  return { loading: isLoggingIn, login, postLogin };
}
