import React from 'react';
import { useTranslation } from 'react-i18next';

import './_orderSummary.scss';

function OrderSummary({ plan, costs }) {
  const { t } = useTranslation();

  return (
    <div className="planWrapper">
      <div className="orderSummary">
        <h3>{t('onboarding.payment.summary')}</h3>
        <table>
          <thead className="costRow">
            <tr>
              <th>{t('items')}</th>
              {costs.items && costs.items[0]?.key !== 'overall_cost' && (
                <th className="costD">{t('quantity')}.</th>
              )}
              <th className="costD">{t('cost')}</th>
            </tr>
          </thead>
          <tbody>
            {costs.items &&
              (costs.items[0]?.key === 'overall_cost' ? (
                <tr className="costRow">
                  <td>
                    {plan.title} {t('plan')}
                  </td>
                  <td className="costD">
                    <p />
                    <p>${plan.overall_cost}</p>
                  </td>
                </tr>
              ) : (
                costs.items.map((cost) => (
                  <tr key={cost.key} className="costRow">
                    <td>{cost.key}</td>
                    <td className="costD">{cost.qty}</td>
                    <td className="costD">
                      <p>
                        {cost.qty} X ${cost.cost}
                      </p>
                      <p>${cost.qty * cost.cost || 0}</p>
                    </td>
                  </tr>
                ))
              ))}
          </tbody>
        </table>
        <div className="costTotalWrapper">
          <div className="costTotalRow">
            <p>{t('total')}</p>
            <p>
              $
              {costs.items.reduce(
                (total, { qty, cost }) => total + (qty ?? 1) * (cost ?? 0),
                0,
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
