import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';

import { OrganizationForm } from 'components/forms';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { getIndustryTypes } from 'redux/settings/settingsActions';
import { BASIC_DETAIL_STEP, URLS } from 'utils/constants';
import { dataURLtoFile } from 'utils/helpers';
import {
  useOnboardingRedirect,
  usePageTitle,
  useScreenshot,
} from 'utils/hooks';
import { regOrganizationSchema } from 'utils/validations';

import useOrganizationSubmitHandler from './useOrganizationSubmitHandler';

import './_regOrganisation.scss';

export default function RegOrganisation() {
  const { t } = useTranslation();
  const { isLoading: isSubmitting, onSubmit } = useOrganizationSubmitHandler();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [, takeScreenshot] = useScreenshot();
  const { user } = useSelector((s) => s.authentication);
  const form = useFormik({
    initialValues: {
      name: '',
      logo: '',
      type: {},
      email: '',
      phone_number: '',
      tnc: !user.is_google_login,
    },
    onSubmit: async ({ type: { id }, ...rest }) => {
      const values = {
        ...rest,
        industry_type: id,
      };
      let logo = null;
      if (!values.logo) {
        logo = dataURLtoFile(await takeScreenshot(ref.current));
      }
      if (!values.email) {
        delete values.email;
      }
      if (!values.phone_number) {
        delete values.phone_number;
      }
      onSubmit({
        ...values,
        logo: values.logo ? values.logo : logo,
      });
    },
    validationSchema: regOrganizationSchema,
  });

  useOnboardingRedirect();
  usePageTitle('Organization Setup');

  useEffect(() => {
    dispatch(getIndustryTypes({ is_onboarding: true }));
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BASIC_DETAIL_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.orgDetail.pageTitle')}
          subtitle={t('onboarding.orgDetail.pageSubTitle')}
        >
          <OrganizationForm form={form} avatarRef={ref} />
          {user.is_google_login && (
            <label className="checkbox">
              <input
                name="tnc"
                id="tnc"
                type="checkbox"
                checked={form.values.tnc ? true : null}
                onChange={form.handleChange}
              />
              <span>
                <Trans
                  i18nKey="signup.agreement"
                  components={{
                    tncLink: (
                      <Link
                        to={URLS.TERMS_AND_CONDITIONS_URL}
                        target="_blank"
                      />
                    ),
                    privacyPolicyLink: (
                      <Link to={URLS.PRIVACY_POLICY_URL} target="_blank" />
                    ),
                  }}
                />
              </span>
              {form.touched.tnc && (
                <span className="txt-error">{form.errors.tnc}</span>
              )}
            </label>
          )}
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={isSubmitting}
          onClickContinue={form.submitForm}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
