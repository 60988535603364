import React from 'react';

import { SurveyThumbnail } from 'components/surveyThumbnail';

export default function SurveySelector({ onChange, value, experiences = [] }) {
  return (
    <div className="survey-modal-experiences">
      {experiences.length === 0 ? (
        <p>
          There is no Customer Experience added for this location. Please add a
          customer experience first.
        </p>
      ) : null}
      {experiences.map(({ experience, ...linkedExperience }) => (
        <SurveyThumbnail
          selected={value === linkedExperience.id}
          onChange={() => onChange(linkedExperience.id)}
          key={linkedExperience.id}
          icon={linkedExperience.experience_icon}
          data={{
            ...linkedExperience,
            title: experience.name,
          }}
        />
      ))}
    </div>
  );
}
