import {
  containsLettersRegex,
  onlyLettersAndNumbersRegex,
} from 'utils/constants/regex';

import Yup from './Yup';
import { addressInfoSchema } from './common';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

const branchSetupSchema = ({ isRegionRequired = false } = {}) => {
  return Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(
        t('blank', 'locationName'),
        t('required', 'locationName'),
      )
      .matches(
        onlyLettersAndNumbersRegex,
        t('lettersNumbersOnly', 'locationName'),
      )
      .matches(containsLettersRegex, t('numbersOnlyNotAllowed', 'locationName'))
      .range(
        1,
        65,
        t('minLength', 'locationName', 1),
        t('maxLength', 'locationName', 65),
      ),
    region: isRegionRequired
      ? Yup.mixed().required(t('required', 'region'))
      : Yup.mixed().nullable(),
    ...addressInfoSchema(),
  });
};

export default branchSetupSchema;
