import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import { LoginWithGoogle } from 'components';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { signupUser } from 'redux/authentication/authenticationActions';
import { SIGNUP, URLS } from 'utils/constants';
import { toFormData } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { signupSchema } from 'utils/validations';

function Signup() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatchWithErrors();

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      tnc: false,
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line no-use-before-define
      await handleSubmit(values);
    },
    validationSchema: signupSchema,
  });

  async function handleSubmit(data) {
    setLoading(true);
    const signupData = toFormData(data);
    const { isError } = await dispatch(signupUser(signupData), formik);
    if (!isError) {
      toast.success('Signup successful');
      navigate(URLS.VERIFY_EMAIL_URL, {
        state: { email: data.email, password: data.password },
      });
    }
    setLoading(false);
  }

  usePageTitle(SIGNUP);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title={t('signup.pageTitle')}>
          <div className="auth-content">
            <LoginWithGoogle />
            <div className="auth-divider">{t('or')}</div>
            <h2 className="onboarding-field-title">{t('signup.withEmail')}</h2>
            <Form onSubmit={formik.handleSubmit} className="auth-form">
              <FormikLabeledInput
                formik={formik}
                inputName="first_name"
                placeholder={t('inputFields.firstName')}
              />
              <FormikLabeledInput
                formik={formik}
                inputName="last_name"
                placeholder={t('inputFields.lastName')}
              />
              <FormikLabeledInput
                formik={formik}
                type="email"
                inputName="email"
                placeholder={t('inputFields.emailAddress')}
              />
              <FormikLabeledInput
                formik={formik}
                type="password"
                inputName="password"
                placeholder={t('inputFields.password')}
              />
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <label className="checkbox">
                <input
                  name="tnc"
                  id="tnc"
                  type="checkbox"
                  checked={!!formik.values.tnc}
                  onChange={formik.handleChange}
                />
                <span>
                  <Trans
                    i18nKey="signup.agreement"
                    components={{
                      tncLink: (
                        <Link
                          to={URLS.TERMS_AND_CONDITIONS_URL}
                          target="_blank"
                        />
                      ),
                      privacyPolicyLink: (
                        <Link to={URLS.PRIVACY_POLICY_URL} target="_blank" />
                      ),
                    }}
                  />
                </span>
                {formik.touched.tnc && (
                  <span className="txt-error">{formik.errors.tnc}</span>
                )}
              </label>
              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                {t('signup.cta')}
              </button>
            </Form>
            <span className="askLogin">
              <Trans
                i18nKey="signup.switchToLogin"
                components={{
                  loginLink: <Link to={URLS.LOGIN_URL} />,
                }}
              />
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}

export default Signup;
