import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { activateAccountAPICall } from 'apis/authentication';
import Loader from 'components/loader/Loader';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { URLS, VERIFICATION } from 'utils/constants';
import { useLogin, usePageTitle } from 'utils/hooks';

export default function SuccessfulSignUp() {
  const { t } = useTranslation();
  const { loading, login } = useLogin();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onProceed = () => login({}, response);

  const activateAccount = async () => {
    try {
      const res = await activateAccountAPICall({
        email: searchParams.get('email'),
        token: searchParams.get('token'),
      });
      setResponse(res);
      toast.success(res);
      setIsLoading(false);
    } catch (e) {
      navigate(URLS.LOGIN_URL);
      toast.error(e.response.data);
    }
  };

  usePageTitle(VERIFICATION);

  useEffect(() => {
    if (searchParams.size < 2) {
      navigate(URLS.LOGIN_URL);
    } else {
      activateAccount();
      login({}, response);
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form
          title={t('signup.successfulSignup.title')}
          subtitle={t('signup.successfulSignup.subTitle')}
        >
          <button
            aria-busy={loading ? true : null}
            className="button button-primary"
            type="button"
            onClick={onProceed}
          >
            {t('signup.successfulSignup.cta')}
          </button>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
