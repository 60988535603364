import i18next from 'i18next';
import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const { t } = i18next;
const tfe = translateFieldError;

const signupSchema = () => {
  const tncError = t('signup.tncError');
  return Yup.object().shape({
    first_name: Yup.string()
      .requiredTrimmed(tfe('blank', 'firstName'), tfe('required', 'firstName'))
      .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'firstName'))
      .range(
        2,
        64,
        tfe('minLength', 'firstName', 2),
        tfe('maxLength', 'firstName', 64),
      ),
    last_name: Yup.string()
      .requiredTrimmed(tfe('blank', 'lastName'), tfe('required', 'lastName'))
      .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'lastName'))
      .range(
        2,
        64,
        tfe('minLength', 'lastName', 2),
        tfe('maxLength', 'lastName', 64),
      ),
    email: Yup.string().required(tfe('required', 'email')).customEmail(),
    tnc: Yup.boolean().oneOf([true], tncError),
    password: Yup.string()
      .required(tfe('required', 'password'))
      .min(8, tfe('minLengthPassword', 'password', 8))
      .max(128, tfe('maxLengthPassword', 'password', 128))
      .matches(/\D/, tfe('numbersOnlyNotAllowed', 'password')),
  });
};

export default signupSchema;
