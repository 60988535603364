/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import { SortableItem } from 'components/dragAndDrop';
import { Switch } from 'components/switch';
import { markupToText } from 'utils/helpers';

import './_configurationWidget.scss';

function ConfigurationWidget({
  data,
  showDiscountinuedWidgets,
  toggleQuestionSelection,
  toggleSingleSurveyQuestions,
  currentSurveyIndex,
}) {
  const { t } = useTranslation();
  const handleToggleSingleSurveyQuestions = ({ target: { checked } }) => {
    toggleSingleSurveyQuestions(checked, currentSurveyIndex);
  };

  const handleQuestionStateChange = (questionIndex) => (e) => {
    toggleQuestionSelection(
      e.target.checked,
      currentSurveyIndex,
      questionIndex,
    );
  };

  const isAllQuestionsEnabled = useMemo(() => {
    return !data.questions.find((q) => !q.is_enabled);
  }, [data.questions]);

  return (
    <div className="configWidgetContainer">
      <Collapse
        className="section-configuration-collapse"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: '1',
            label: (
              <div className="section-configuration-info-container">
                <div className="section-configuration-title">
                  <h5>
                    {data.survey_title} -{data.experience}
                  </h5>
                  <Switch
                    checked={isAllQuestionsEnabled}
                    onChange={handleToggleSingleSurveyQuestions}
                  />
                </div>
                <small>
                  <b>{t('locations')}: </b>
                  <span>
                    {data.entities.map(({ name }) => name).join(', ')}
                  </span>
                </small>
              </div>
            ),
            children: (
              <div className="configWidgetQuestionList">
                {data.questions.map((question, index) => {
                  return question.is_from_history &&
                    !showDiscountinuedWidgets ? null : (
                    <SortableItem key={question.id} id={question.id}>
                      <div className="configWidgetQuestion">
                        <p
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: markupToText(question.label),
                          }}
                        />
                        {question.is_from_history ? (
                          <small className="discontinued-label">
                            - {t('discontinued')}
                          </small>
                        ) : null}
                        <Switch
                          checked={question.is_enabled}
                          onChange={handleQuestionStateChange(index)}
                        />
                      </div>
                    </SortableItem>
                  );
                })}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ConfigurationWidget;
