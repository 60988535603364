import Yup from './Yup';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

const loginSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required(t('required', 'email')).customEmail(),
    password: Yup.string().required(t('required', 'password')),
  });
};

export default loginSchema;
