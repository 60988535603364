/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { cloneDeep } from 'lodash';

import i18next from 'i18next';

import {
  ATTACHMENT,
  ATTACHMENTS,
  CHECKBOX,
  RADIO,
  RATING,
  TEXT,
} from '../constants/questionTypes';
import { copyContentStateWithPrefix } from './richTextEditorHelpers';

const { t } = i18next;

export function createMarkup(contentState) {
  if (typeof contentState === 'string') {
    return {
      __html: DOMPurify.sanitize(contentState),
    };
  }
  contentState = contentState || ContentState.createFromText('');
  const html = stateToHTML(contentState);
  return {
    __html: DOMPurify.sanitize(html),
  };
}

export const getContentStateFromHtmlString = (htmlString = '') => {
  if (!htmlString) return htmlString;
  const htmlBlocks = convertFromHTML(htmlString);
  return ContentState.createFromBlockArray(
    htmlBlocks.contentBlocks,
    htmlBlocks.entityMap,
  );
};

export function getSectionId(len) {
  return `section-${len + 1}`;
}

export function getQuestionId(sectionId, len) {
  return `${sectionId}-question-${len + 1}`;
}

export function getOptionId(questionId, len) {
  return `${questionId}-option-${len + 1}-${dayjs().valueOf()}`;
}

export function formatSurveyOption(id, option_id, label, is_other_option) {
  return { id, option_id, label, is_other_option };
}

export const isNotEmptyContentState = (label = '') => {
  if (!label) return false;

  if (typeof label === 'string') {
    return !!label.trim();
  }
  const plainText = label.getPlainText();
  return !!plainText.trim();
};

export const isSectionQuestionsLabeled = (questions) => {
  return questions.some(
    (q) =>
      isNotEmptyContentState(q.label) ||
      q.options.some((option) => isNotEmptyContentState(option.label)),
  );
};

export const getOptionalRichtextHtml = (text) => {
  return isNotEmptyContentState(text) ? createMarkup(text).__html : '';
};

export const getPlainTextFromContentState = (contentState) => {
  if (!contentState) return contentState;
  if (typeof contentState === 'string') return contentState;
  return contentState?.getPlainText ? contentState.getPlainText() : '';
};

export const isChoicesQuestion = (type) =>
  type === CHECKBOX || type === RADIO || type === RATING;

export const getDuplicateSection = (section, surveySectionsCount) => {
  const sectionId = getSectionId(surveySectionsCount);
  const duplicateSection = {
    ...section,
    id: sectionId,
    section_uuid: null,
    questions: section.questions.map((question, qIndex) => {
      const questionId = getQuestionId(sectionId, qIndex);
      const appendCopyText = isNotEmptyContentState(question.label);
      const questionLabel = copyContentStateWithPrefix(
        question.label,
        appendCopyText ? t('copyOf') : '',
      );
      return {
        ...question,
        id: questionId,
        question_uuid: null,
        label: questionLabel,
        options: question.options.map((option, oIndex) => {
          const optionId = getOptionId(questionId, oIndex);
          const optionLabel = copyContentStateWithPrefix(option.label);
          return {
            ...option,
            id: optionId,
            option_id: null,
            label: optionLabel,
          };
        }),
      };
    }),
  };
  return duplicateSection;
};

export function formatSurveyQuestion({
  attachment_types = [],
  id,
  is_required = true,
  label,
  max_number_of_choices = 3,
  min_number_of_choices = 1,
  options = [],
  question_type = TEXT,
  question_uuid = undefined,
  is_active = true,
} = {}) {
  const attachmentTypes = [...attachment_types];
  if (question_type === ATTACHMENT && attachmentTypes.length < 1) {
    attachmentTypes.push(ATTACHMENTS.IMAGE);
  }
  let questionOptions = options;
  if (questionOptions.length < 1 && isChoicesQuestion(question_type)) {
    const choiceId = getOptionId(id, questionOptions.length);
    const optionLabel = copyContentStateWithPrefix(''); // will return instance of Draftjs ContentState
    const choice = formatSurveyOption(choiceId, undefined, optionLabel);
    questionOptions = [choice];
  }

  const question = {
    id,
    label,
    question_type,
    options: questionOptions,
    attachment_types: attachmentTypes,
    is_required,
    max_number_of_choices,
    min_number_of_choices,
    is_active,
  };

  // we have to send question_uuid only when max_choice for multiple-answer
  // is changed
  if (question_uuid) {
    question.question_uuid = question_uuid;
  }

  return question;
}

export function formatSurveySection(id, questions) {
  return { id, questions };
}

// check if the survey has atleast one rating question in other sections
export function hasOneRatingQuestion(sections, id) {
  let ratingQuestion = false;
  sections.forEach((section) => {
    if (section.id !== id) {
      section.questions.forEach((question) => {
        if (
          question.question_type === 'rating' &&
          question.is_required === true &&
          question.options.length >= 3
        ) {
          ratingQuestion = true;
        }
      });
    }
  });
  return ratingQuestion;
}

export const cleanSimilarityErrors = (options, similarityErrorIds) => {
  const currentOtionsIds = {};
  options.forEach((o) => {
    currentOtionsIds[o.id] = true;
  });
  const tempSimilarityErrorIds = { ...similarityErrorIds };
  Object.keys(tempSimilarityErrorIds).forEach((key) => {
    if (!currentOtionsIds[key]) {
      delete tempSimilarityErrorIds[key];
    } else {
      Object.keys(tempSimilarityErrorIds[key]).forEach((nestedKey) => {
        if (!currentOtionsIds[nestedKey]) {
          delete tempSimilarityErrorIds[key][nestedKey];
        }
      });
    }
  });
  return tempSimilarityErrorIds;
};

export function cleanSurvey(survey, edit = false) {
  const cleanedSurvey = cloneDeep(survey);
  cleanedSurvey.welcome_text =
    cleanedSurvey.welcome_text === null
      ? null
      : getOptionalRichtextHtml(cleanedSurvey.welcome_text);
  cleanedSurvey.respondent_question.label = getOptionalRichtextHtml(
    cleanedSurvey.respondent_question.label,
  );
  cleanedSurvey.sections.forEach((section, idx) => {
    delete section.id;
    if (!edit) delete section.section_uuid;
    section.order_number = idx;
    section.questions.forEach((question) => {
      delete question.id;
      if (!edit) delete question.question_uuid;
      question.label = createMarkup(question.label).__html;
      question.options = question.options.map((option, i) => ({
        option_id: option.option_id || null,
        label: createMarkup(option.label).__html,
        order_number: i,
        is_other_option: option.is_other_option,
      }));
    });
  });
  return cleanedSurvey;
}

export function convertToRawSurvey(survey) {
  const rawSurvey = { ...survey };
  rawSurvey.sections.forEach((section) => {
    section.id = section.section_uuid;
    section.questions.forEach((question) => {
      question.id = question.question_uuid;
      question.options = question.options.map((option, idx) => {
        return {
          id: getOptionId(question.id, idx),
          option_id: option.option_id,
          label: option.is_other_option
            ? getPlainTextFromContentState(
                getContentStateFromHtmlString(option.label),
              )
            : option.label,
          order_number: option.order_number,
          is_other_option: option.is_other_option,
        };
      });
    });
  });

  return rawSurvey;
}

export function validateSurvey(survey) {
  if (!hasOneRatingQuestion(survey.sections, 0)) {
    toast.error(t('formBuilder.genericErrors.requiredRatingQuestion'));
    return false;
  }
  return true;
}
