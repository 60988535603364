import i18next from 'i18next';

const { t } = i18next;

const translateFieldError = (errorKey, fieldKey, count = null) => {
  return t(`fieldErrors.${errorKey}`, {
    field: t(`inputFields.${fieldKey}`),
    count,
  });
};

export default translateFieldError;
