import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import {
  deleteInviteTemplateById,
  getInviteTemplates,
} from 'redux/emailManagement/emailManagementActions';
import { DELETE_TEMPLATE_PREFIX } from 'utils/constants/manageEmails';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';
import { getEmailTemplatesColumns } from 'utils/helpers/emailsManagementHelper';

function Templates() {
  const dispatch = useDispatch();
  const {
    inviteTemplates: { data: inviteTemplates, loading },
    deleteEmailItems: { loading: deleteInviteTemplatesLoading },
  } = useSelector((s) => s.emailManagement);

  const { params, onChangePagination } = useContext(SettingsPageContext);
  const {
    inviteTemplateIdRef,
    setIsOpenInviteTemplateModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(ManageEmailsContext);

  const onViewTemplate = (template) => {
    inviteTemplateIdRef.current = template.id;
    setIsOpenInviteTemplateModal(true);
  };

  const onComposeEmail = (template) => {
    inviteEmailDataRef.current = {
      invite: {
        subject: template.name,
        email_json_data: template.email_json_data ?? template.html_message,
        entity_experience: template.entity_experience,
      },
    };
    setIsOpenSendEmailModal(true);
  };

  const onDeleteTemplate = (template) => {
    dispatch(
      deleteInviteTemplateById({
        params,
        itemId: template.id,
        prefix: DELETE_TEMPLATE_PREFIX,
      }),
    );
  };

  useEffect(() => {
    dispatch(getInviteTemplates({ ...params }));
  }, [params]);

  return (
    <div>
      <Table
        className="left-align"
        scroll={{ x: 'max-content' }}
        columns={getEmailTemplatesColumns(
          deleteInviteTemplatesLoading,
          onViewTemplate,
          onComposeEmail,
          onDeleteTemplate,
        )}
        dataSource={attachKeysToDataSource(inviteTemplates.results)}
        loading={loading}
        pagination={{
          ...getAntdPaginationProps({
            data: inviteTemplates,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
    </div>
  );
}

export default Templates;
