/* eslint-disable react/no-unstable-nested-components */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select } from 'antd';
import { debounce, isEmpty, isNil } from 'lodash';

import { DEFAULT_PAGINATED_DROPDOWN_SIZE } from 'utils/constants';
import { useEffectAfterMount } from 'utils/hooks';

import './_selectAndCreateOptionDropdown.scss';

const SelectAndCreateOptionDropdown = forwardRef(
  (
    {
      addOptionLabel = 'Add item',
      addOptionPlaceholder = 'Please enter item',
      disabled = false,
      disabledItems = {},
      disableVirtualScroll,
      dropdownError,
      dropdownStyle = {},
      isNewOptionLoading = false,
      isPaginationLoading = false,
      isRemoteSearchable = false,
      labelInValue = false,
      labelKey = 'name',
      listHeight,
      maxTagCount,
      newOptionError,
      newOptionValue,
      nextDataAction = null,
      onAddNewOption,
      onChange,
      onChangeNewOption,
      onSelect,
      onTabChange,
      options,
      pageSize = DEFAULT_PAGINATED_DROPDOWN_SIZE,
      paginationData = null,
      placeholder,
      placement,
      popupContainerRef = document.body,
      popupMatchSelectWidth = true,
      removeFocusStyle = true,
      selectedOption, // { value: number, label: string } in case of single option
      selectTabs = [],
      selectMode,
      showAddOption = false,
      showSearch = true,
      styles = {},
      suffixIcon,
      useParentAsPopupContainer = true,
      valueKey = 'id',
      wrapperClassName = '',
      wrapperStyles = {},
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(selectTabs[0]);
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState();

    const activeTabRef = useRef(activeTab);
    const activeTabScrollRef = useRef(null);
    const tabChangedRef = useRef(false);

    const paginationLoading =
      activeTab?.isPaginationLoading || isPaginationLoading;
    const nextAction = nextDataAction ?? activeTab?.nextDataAction;

    const handleNewOptionChange = useCallback(
      (value) => {
        onChangeNewOption?.(value);
      },
      [onChangeNewOption],
    );

    const handleRemoteSearch = useCallback(
      debounce((search) => {
        if (!isRemoteSearchable || !nextAction) return;
        dispatch(nextAction({ page: 1, size: pageSize, search }));
      }, 400),
      [isRemoteSearchable, nextAction, dispatch, pageSize], // Dependencies
    );

    useEffectAfterMount(() => {
      if (tabChangedRef.current) {
        tabChangedRef.current = false;
      } else {
        handleRemoteSearch(searchValue);
      }
    }, [searchValue]);

    const handleSearchChange = (value) => {
      setSearchValue(value);
    };

    const handleAddItem = useCallback(
      (e) => {
        e.preventDefault();
        onAddNewOption?.();
      },
      [onAddNewOption],
    );

    const handleScrollToEnd = useCallback(
      (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight < scrollHeight - 1) return;

        const pagination = paginationData ?? activeTab?.paginationData;

        if (!pagination || !nextAction || paginationLoading) return;

        const { count, results } = pagination;

        if (count > results.length) {
          const nextPage = Math.ceil(results.length / pageSize) + 1;
          dispatch(
            nextAction({
              page: nextPage,
              size: pageSize,
              search: searchValue ?? '',
            }),
          );
        }
      },
      [dispatch, paginationData, nextDataAction, activeTab, paginationLoading],
    );

    const handleTabChange = (tab) => {
      if (disableVirtualScroll) {
        const optionContainer = document.querySelector(
          '.rc-virtual-list-holder',
        );
        if (!optionContainer) return;
        if (tab.id !== activeTabRef.current?.id) {
          activeTabScrollRef.current = optionContainer.scrollTop;
          optionContainer.scrollTop = 0;
        } else if (!isNil(activeTabScrollRef.current)) {
          setTimeout(() => {
            optionContainer.scrollTop = activeTabScrollRef.current;
          }, 0);
        }
      }

      if (searchValue) {
        handleRemoteSearch('');
        setSearchValue('');
      }
      tabChangedRef.current = true;
      onTabChange?.(tab);
      setActiveTab(tab);
    };

    const handleOptionChange = (newOption) => {
      activeTabRef.current = activeTab;
      if (searchValue) {
        handleSearchChange('');
      }
      onChange(newOption, activeTab);
    };

    const onDropdownVisibleChange = (isOpen) => {
      setOpen(isOpen);
      if (!isOpen) {
        setActiveTab(activeTabRef.current);
      }
    };

    const selectOptions = useMemo(() => {
      const currentOptions = activeTab ? activeTab.options : options;
      const optionsList = [];
      const isMultipleSelect =
        selectMode === 'multiple' && Array.isArray(selectedOption);
      const selectedOptionsHash = {};
      if (isRemoteSearchable && !searchValue) {
        if (isMultipleSelect) {
          optionsList.push(...selectedOption);
          optionsList.forEach((opt) => {
            selectedOptionsHash[opt.value] = true;
          });
        } else if (selectedOption?.value) {
          optionsList.push(selectedOption);
          selectedOptionsHash[selectedOption.value] = true;
        }
      }
      currentOptions.forEach(({ [labelKey]: label, [valueKey]: value }) => {
        if (!selectedOptionsHash[value]) {
          optionsList.push({
            value,
            label,
            disabled: disabledItems[value],
          });
        }
      });

      if (paginationLoading) {
        optionsList.push({
          value: 'loading',
          label: t('loadingDot'),
          disabled: true,
          className: 'pagination-loading',
        });
      }
      return optionsList;
    }, [
      options,
      disabledItems,
      labelKey,
      valueKey,
      activeTab,
      paginationLoading,
    ]);

    useImperativeHandle(ref, () => ({
      open,
      setOpen,
    }));

    const isOptionSelected = useMemo(() => {
      if (selectMode) return true;
      return (
        (typeof selectedOption === 'number' && !Number.isNaN(selectedOption)) ||
        (typeof selectedOption === 'string' && selectedOption.trim() !== '') || // Non-empty string
        (typeof selectedOption === 'object' && selectedOption?.value)
      );
    }, [selectedOption]);

    useEffect(() => {
      if (!selectTabs.length) return;
      let currentTab;
      if (!activeTab) {
        // eslint-disable-next-line prefer-destructuring
        currentTab = selectTabs[0];
      } else {
        currentTab = selectTabs.find((tab) => tab.id === activeTab.id);
      }
      setActiveTab(currentTab);
      activeTabRef.current = currentTab;
    }, [selectTabs]);

    return (
      <div
        style={wrapperStyles}
        className={`select-and-create-option-dropdown ${
          dropdownError ? 'invalid-dropdown' : ''
        } ${removeFocusStyle ? 'remove-default-focus-styles' : ''}
        ${wrapperClassName}`}
      >
        <Select
          className="antd-custom-select-dropdown"
          disabled={disabled}
          dropdownRender={(menu) => (
            <>
              {selectTabs.length ? (
                <div className="select-and-create-option-dropdown-tabs">
                  {selectTabs.map((tab) => (
                    <button
                      key={tab.id}
                      className={`select-tab ${
                        activeTab?.id === tab.id ? 'active' : ''
                      }`}
                      onClick={() => handleTabChange(tab)}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              ) : null}
              {menu}
              {showAddOption ? (
                <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <div
                    className={`add-option-container ${
                      isNewOptionLoading ? 'disabled-new-option' : ''
                    }`}
                  >
                    <div className="add-option">
                      <Input
                        disabled={isNewOptionLoading}
                        className="add-option-input"
                        placeholder={addOptionPlaceholder}
                        value={newOptionValue || ''}
                        onChange={({ target: { value } }) =>
                          handleNewOptionChange(value)
                        }
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        disabled={isNewOptionLoading}
                        type="text"
                        className="add-option-btn"
                        icon={<PlusOutlined />}
                        onClick={handleAddItem}
                      >
                        {addOptionLabel}
                      </Button>
                    </div>
                    <div>
                      {newOptionError ? (
                        <span className="control-error">{newOptionError}</span>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          dropdownStyle={dropdownStyle}
          filterOption={
            isRemoteSearchable
              ? false
              : (input, option) =>
                  option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          getPopupContainer={(target) =>
            useParentAsPopupContainer ? target.parentNode : popupContainerRef
          }
          labelInValue={labelInValue}
          listHeight={listHeight}
          loading={
            (paginationLoading && isEmpty(selectOptions)) || isNewOptionLoading
          }
          onChange={handleOptionChange}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onPopupScroll={handleScrollToEnd}
          onSearch={showSearch ? handleSearchChange : undefined}
          onSelect={onSelect}
          open={open}
          options={selectOptions}
          placeholder={placeholder || t('filters.select.option')}
          placement={placement}
          popupClassName="select-dropdown-options-container"
          popupMatchSelectWidth={popupMatchSelectWidth}
          searchValue={searchValue}
          showSearch={showSearch}
          style={styles}
          suffixIcon={suffixIcon !== undefined ? suffixIcon : undefined}
          value={isOptionSelected ? selectedOption : undefined}
          virtual={!disableVirtualScroll}
          {...(suffixIcon !== undefined ? { suffixIcon } : {})}
          {...(selectMode
            ? {
                mode: selectMode,
                maxTagCount: maxTagCount ?? 'responsive',
              }
            : {})}
        />
        {dropdownError ? (
          <span className="control-error">{dropdownError}</span>
        ) : null}
      </div>
    );
  },
);

export default SelectAndCreateOptionDropdown;
