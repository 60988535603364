/* eslint-disable no-else-return */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { singleParentEntitySelectAPICall } from 'apis/cxmeter';
import { PermissionDenied } from 'pages';
import { setParentEntity } from 'redux/authentication/authenticationSlice';
import {
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
  URLS,
} from 'utils/constants';
import { storeParentEntity } from 'utils/helpers';

export default function ProtectedRoute({
  Component,
  isSuperuser,
  isRestrictedToParentEntityAdmin,
  isRestrictedToEntityAdmin,
  isRestrictedToFinancialAdmin,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, parentEntity, isAppLoading } = useSelector(
    (state) => state.authentication,
  );

  useEffect(() => {
    if (!isAppLoading) {
      if (!isEmpty(user) && !user.is_active) {
        navigate(URLS.VERIFY_EMAIL_URL);
      }
      if (!isEmpty(user) && isEmpty(parentEntity)) {
        if (user.is_superuser) {
          navigate(URLS.ORGANIZATION_URL, { replace: true });
        } else {
          singleParentEntitySelectAPICall().then(({ orgs, selectedOrg }) => {
            if (orgs.length === 0) {
              navigate(URLS.REGISTER_ORG_URL, { replace: true });
            } else if (orgs.length > 1) {
              storeParentEntity(selectedOrg);
              dispatch(setParentEntity(selectedOrg));
              navigate(URLS.DASHBOARD_URL, { replace: true });
            }
          });
        }
      }
    }
  }, [parentEntity, user, isAppLoading]);

  if (isAppLoading) {
    return null;
  }

  if (isEmpty(user)) {
    return (
      <Navigate to={URLS.LOGIN_URL} state={{ redirectTo: location.pathname }} />
    );
  }

  if (isSuperuser) {
    if (user.is_superuser) {
      return <Component {...rest} />;
    }
    return <PermissionDenied />;
  }

  if (!isEmpty(user)) {
    if (isRestrictedToParentEntityAdmin && user.type === PARENT_ENTITY_ADMIN) {
      return <Component {...rest} />;
    } else if (isRestrictedToEntityAdmin && user.type === ENTITY_ADMIN) {
      return <Component {...rest} />;
    } else if (isRestrictedToFinancialAdmin && user.type === FINANCIAL_ADMIN) {
      return <Component {...rest} />;
    } else {
      return <PermissionDenied />;
    }
  }
}
