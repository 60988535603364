import React from 'react';

import WarningModal from 'components/ui/modal/WarningModal';

export default function SurveyEditedModal({
  onClose,
  isOpenModal,
  primaryAction,
  secondaryAction,
}) {
  return (
    <WarningModal
      onClose={onClose}
      isOpen={isOpenModal}
      bodyStyles={{ width: 550 }}
      title="Change Existing or Create New?"
      subTitle={
        <div style={{ maxHeight: 'calc(100% - 100px' }}>
          Would you like to update the original question/option or start a new
          one?
          <br />
          Starting a new question will discontinue the original question and old
          submissions will not be linked to the new question.
          <br />
          If you update the original, all past submissions will remain linked to
          this question.
        </div>
      }
      primaryActionTitle="Update Original"
      primaryAction={primaryAction}
      secondaryActionTitle="Start New"
      secondaryAction={secondaryAction}
    />
  );
}
