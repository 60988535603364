/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef } from 'react';

import { EditorState, Modifier } from 'draft-js';

import { RichTextEditor } from 'components/richTextEditor';
import SURVEY_LINK_DECORATOR from 'components/richTextEditor/Decorators';
import { Icon } from 'components/ui';
import {
  EMAIL_EDITOR_TOOLBAR_CONFIG,
  EMAIL_TEMPLATES_OVERLAY,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import { emailEditorBlockRenderFunc } from 'utils/helpers/emailsManagementHelper';
import {
  findSurveyLink,
  hasSelectedText,
  updateSurveyLink,
} from 'utils/helpers/richTextEditorHelpers';

import FeedbackFormSelector from '../FeedbackFormSelector';
import ToEmailInput from '../ToEmailInput';
import EmailModalInput from './EmailModalInput';
import EmailSchedule from './EmailSchedule';

function EmailComposition({
  formik,
  setCurrentOverlayPreview,
  currentOverlayPreview,
}) {
  const editorRef = useRef();
  const addLink = useRef(false);
  const { values, errors, touched } = formik;
  const {
    updateHTMLEditorState,
    html_message: htmlMessage,
    email_json_data: emailJsonData,
  } = values;

  const handleSurveyLink = async () => {
    const editorState = editorRef.current?.getEditorState();
    const newEditorState = updateSurveyLink(editorState);
    editorRef.current.updateState(newEditorState);
  };

  useEffect(() => {
    if (editorRef && updateHTMLEditorState) {
      editorRef.current.updateState(emailJsonData ?? htmlMessage);
      formik.setFieldValue('updateHTMLEditorState', false);
    }
  }, [updateHTMLEditorState]);

  const handleAttachFeedbackForm = () => {
    addLink.current = true;
    setCurrentOverlayPreview(FEEDBACK_TEMPLATES_OVERLAY);
  };

  const handleEmailBodyDataChange = (emailBody) => {
    formik.setFieldValue('email_json_data', emailBody);
    const hasSurveyLink = findSurveyLink(emailBody);
    if (!hasSurveyLink) {
      formik.setFieldValue('entity_experience', null);
    }
  };

  const canAttachFeedbackForm = useMemo(() => {
    const hasSurveyLink = findSurveyLink(emailJsonData);
    if (hasSurveyLink) return true;
    const editorState = editorRef.current?.getEditorState();
    const isSelectedText = editorState && hasSelectedText(editorState);
    return isSelectedText;
  }, [emailJsonData, editorRef.current]);

  useEffect(() => {
    if (!currentOverlayPreview && addLink.current) {
      addLink.current = false;
      handleSurveyLink();
    }
  }, [currentOverlayPreview]);

  return (
    <>
      <ToEmailInput formik={formik} isEmailModal />
      <EmailModalInput
        label="Subject"
        placeholder="Enter Subject"
        id="subject"
        formik={formik}
      />
      <div>
        <div className="email-body">
          <RichTextEditor
            ref={editorRef}
            showCustomLink
            setState={handleEmailBodyDataChange}
            content={values.email_json_data}
            toolbarConfig={EMAIL_EDITOR_TOOLBAR_CONFIG}
            toolbarOnFocus={false}
            customDecorators={[SURVEY_LINK_DECORATOR]}
            blockRendererFn={emailEditorBlockRenderFunc}
          />
        </div>
        {touched.email_json_data || errors.link_survey ? (
          <span className="control-error">
            {errors.link_survey ??
              (formik.submitCount > 0 ? errors.email_json_data : null)}
          </span>
        ) : null}
      </div>
      <div className="add-templates-btns">
        <div>
          <button
            className="cstm-btn tertiary-cstm-btn"
            onClick={() => setCurrentOverlayPreview(EMAIL_TEMPLATES_OVERLAY)}
          >
            <Icon type="news" />
            Import Template
          </button>
          <FeedbackFormSelector
            canAttachFeedbackForm={canAttachFeedbackForm}
            openFeedbackTemplates={handleAttachFeedbackForm}
            selectedFeedbackForm={values.entity_experience?.title}
          />
        </div>
        {touched.entity_experience && (
          <span className="control-error">{errors.entity_experience}</span>
        )}
      </div>
      <EmailSchedule formik={formik} />
    </>
  );
}

export default EmailComposition;
