import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

const regionValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(t('blank', 'regionName'), t('required', 'regionName'))
      .matches(onlyLettersAndSpacesRegex, t('lettersOnly', 'regionName'))
      .range(
        1,
        128,
        t('minLength', 'regionName', 1),
        t('maxLength', 'regionName', 128),
      ),
  });
};

export default regionValidationSchema;
