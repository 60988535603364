import { useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { omit } from 'lodash';

import MultiBranchListFilter from 'components/branchExpFilter/MultiBranchListFilter';
import { PasswordField } from 'components/passwordField';
import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { Modal } from 'components/ui';
import { createUser, getUserList, updateUser } from 'redux/users/usersActions';
import {
  ENTITY_ADMIN,
  PAGINATION_AND_SORTING_PARAMS,
  USER_ROLES_LIST,
} from 'utils/constants';
import {
  generateRandomPassword,
  isNumberOrParsableString,
} from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { userSchema } from 'utils/validations';

const INITIAL_FORM_STATE = {
  first_name: '',
  last_name: '',
  email: '',
  entities: [],
  type: '',
  password: '',
  confirm_password: '',
  is_active: true,
  showPasswordFields: false,
};

function UserModal({ handleModalClose, user, open, params, setParams }) {
  const userModalRef = useRef();
  const dispatch = useDispatchWithErrors();
  const [loading, setLoading] = useState(false);

  const getUserAction = (values) => () => {
    if (user.id) {
      const userData = values.showPasswordFields
        ? values
        : omit(values, ['password', 'confirm_password', 'showPasswordFields']);
      return updateUser({ id: user.id, data: userData });
    }
    return createUser(values);
  };

  const formik = useFormik({
    initialValues: { ...INITIAL_FORM_STATE, ...user },
    onSubmit: async ({ entities, ...formikValues }) => {
      setLoading(true);
      const entitiesIds = (entities ?? []).map((entity) => entity.value);
      const userAction = getUserAction({
        entities: entitiesIds,
        ...formikValues,
      });
      const { isError } = await dispatch(userAction(), formik);
      if (!isError) {
        if (!user.id) setParams({ ...PAGINATION_AND_SORTING_PARAMS });
        else dispatch(getUserList(params));
        handleModalClose();
      }
      setLoading(false);
    },
    validationSchema: userSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });
  const { values, touched, errors } = formik;

  const handleEntitiesChange = (entities) => {
    formik.setFieldValue('entities', entities);
  };

  useEffect(() => {
    formik.setValues({ ...INITIAL_FORM_STATE, ...user });
    if (!user.id) {
      // automatically generate password when adding users
      const generatedPassword = generateRandomPassword();
      formik.setFieldValue('password', generatedPassword);
      formik.setFieldValue('confirm_password', generatedPassword);
    }
    formik.setTouched({});
  }, [user]);

  return (
    open && (
      <Modal
        modalRef={userModalRef}
        open={open}
        onClose={handleModalClose}
        className="user-modal"
      >
        <Modal.Header
          title={user.id ? 'Update User' : 'Add User'}
          onClose={handleModalClose}
        />
        <Modal.Body>
          <article className="onboarding-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="control-group">
                <label
                  className={`control ${
                    touched.first_name && errors.first_name
                      ? 'control-invalid'
                      : ''
                  }`}
                >
                  <span className="required-field">
                    First Name <span>*</span>
                  </span>
                  <input
                    type="text"
                    name="first_name"
                    onChange={formik.handleChange}
                    value={values.first_name}
                  />
                  {touched.first_name && (
                    <span className="control-error">{errors.first_name}</span>
                  )}
                </label>
                <label
                  className={`control ${
                    touched.last_name && errors.last_name
                      ? 'control-invalid'
                      : ''
                  }`}
                >
                  <span className="required-field">
                    Last Name <span>*</span>
                  </span>
                  <input
                    type="text"
                    name="last_name"
                    onChange={formik.handleChange}
                    value={values.last_name}
                  />
                  {touched.last_name && (
                    <span className="control-error">{errors.last_name}</span>
                  )}
                </label>
              </div>

              <label
                className={`control ${
                  touched.email && errors.email ? 'control-invalid' : ''
                }`}
              >
                <span className="required-field">
                  Email<span>*</span>
                </span>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={values.email}
                />
                {touched.email && (
                  <span className="control-error">{errors.email}</span>
                )}
              </label>
              <div className="control-group user-role-container">
                <SelectAndCreateOptionDropdown
                  options={USER_ROLES_LIST}
                  selectedOption={values.type}
                  onChange={(v) => formik.setFieldValue('type', v)}
                  dropdownError={touched.type && errors.type}
                />
                {values.type === ENTITY_ADMIN && (
                  <MultiBranchListFilter
                    selectMode="multiple"
                    value={values.entities}
                    dropdownError={touched.entities && errors.entities}
                    onChange={handleEntitiesChange}
                    popupContainerRef={userModalRef.current}
                    useParentAsPopupContainer={false}
                    fetchLocations={false}
                  />
                )}
              </div>
              {isNumberOrParsableString(user.id) ? (
                <label className="checkbox user-modal-checkbox m-0">
                  <input
                    type="checkbox"
                    name="showPasswordFields"
                    onChange={formik.handleChange}
                    checked={values.showPasswordFields}
                  />
                  <span>Update Password</span>
                </label>
              ) : null}
              {!isNumberOrParsableString(user.id) ||
              (isNumberOrParsableString(user.id) &&
                values.showPasswordFields) ? (
                <div className="control-group">
                  <label
                    htmlFor="password"
                    className={`control ${
                      touched.password && errors.password
                        ? 'control-invalid'
                        : ''
                    }`}
                  >
                    <span className="required-field">
                      Password<span>*</span>
                    </span>
                    <PasswordField
                      value={values.password}
                      onChange={formik.handleChange}
                      id="password"
                      error={touched.password ? errors.password : ''}
                    />
                  </label>

                  <label
                    htmlFor="confirm_password"
                    className={`control ${
                      touched.confirm_password && errors.confirm_password
                        ? 'control-invalid'
                        : ''
                    }`}
                  >
                    <span className="required-field">
                      Confirm Password<span>*</span>
                    </span>
                    <PasswordField
                      value={values.confirm_password}
                      onChange={formik.handleChange}
                      id="confirm_password"
                      error={
                        touched.confirm_password ? errors.confirm_password : ''
                      }
                    />
                  </label>
                </div>
              ) : null}
              <label
                className={`checkbox user-modal-checkbox m-0 ${
                  touched.is_active && errors.is_active ? 'control-invalid' : ''
                }`}
              >
                <input
                  type="checkbox"
                  name="is_active"
                  onChange={formik.handleChange}
                  checked={values.is_active}
                />
                <span>Active</span>
                {touched.is_active && (
                  <span className="control-error">{errors.is_active}</span>
                )}
              </label>
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
            </form>
          </article>
        </Modal.Body>
        <Modal.Footer
          secondaryAction={handleModalClose}
          disableSecondaryAction={loading}
          isLoading={loading}
          primaryAction={formik.handleSubmit}
          primaryLabel={user.id ? 'Update User' : 'Add User'}
          // if creating a new user, isActive must be checked
          // if updating the user, isActive can be set to false
          disablePrimaryAction={loading || (!user.id && !values.is_active)}
        />
      </Modal>
    )
  );
}

export default UserModal;
