import React, { useState } from 'react';

import { isNil } from 'lodash';

import {
  BOTH_PREVIEW,
  PERCENTAGE_PREVIEW,
  VALUES_PREVIEW,
} from 'utils/constants/compareFeedbacks';

import SectionBanner from './SectionBanner';

function ComparisonSection({
  selectedComparisonFilters,
  title,
  data,
  previewType,
}) {
  const [openSections, setOpenSections] = useState(
    Object.fromEntries(data.map((_, idx) => [idx, true])),
  );

  const toggleSection = (section) => {
    setOpenSections((prevState) => {
      return {
        ...prevState,
        [section]: !prevState[section],
      };
    });
  };

  return (
    <div className="comparison__content-container">
      {data.map((question, idx) => {
        return (
          <div key={idx} className="comparison__content-header">
            <SectionBanner
              title={title}
              section={question.title}
              id={idx}
              toggleSection={toggleSection}
              isOpen={openSections[idx]}
            />
            {question.data.map((answer, qIdx) => {
              return (
                openSections[idx] && (
                  <div
                    className="comparison__content"
                    style={{
                      gridTemplateColumns: `minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) ${
                        selectedComparisonFilters > 2
                          ? 'minmax(100px, 1fr)'
                          : '40px'
                      }`,
                    }}
                    key={`${answer.key} - ${qIdx}`}
                  >
                    <p className="font-body">{answer.key}</p>
                    {answer.values.map((count, index) => {
                      const percentages = answer.percentages ?? [];
                      const currentPercentage = percentages[index];
                      const percentage = isNil(currentPercentage.value)
                        ? null
                        : `${currentPercentage.value}%`;

                      let hasGreaterFeedback = count.isGoodValue;
                      if (previewType.id === PERCENTAGE_PREVIEW) {
                        hasGreaterFeedback = currentPercentage.isGoodValue;
                      } else if (previewType.id === BOTH_PREVIEW) {
                        hasGreaterFeedback = answer.hasEqualPercentage
                          ? count.isGoodValue
                          : currentPercentage.isGoodValue;
                      }

                      return (
                        <div key={index} className="comparison__values">
                          <div className="compare__values-content">
                            <p
                              className={`font-body ${
                                hasGreaterFeedback ? 'high-feedback-value' : ''
                              }`}
                            >
                              {previewType.id === VALUES_PREVIEW ? (
                                <span>{count.value ?? '-'}</span>
                              ) : null}
                              {previewType.id === PERCENTAGE_PREVIEW ? (
                                <span>{percentage ?? '-'}</span>
                              ) : null}
                              {previewType.id === BOTH_PREVIEW ? (
                                <span>
                                  {isNil(count.value) && isNil(percentage) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {count.value ?? '-'} , {percentage ?? '-'}
                                    </>
                                  )}
                                </span>
                              ) : null}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default ComparisonSection;
