import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getCurrentReview,
  resolveFeedback,
} from 'redux/reviewsListing/reviewsListingActions';
import { RESOLVED_STATUS } from 'utils/constants';

function ReviewResolutionSection({ review }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.reviews.currentReview);
  const handleResolution = () => {
    if (isLoading) return;
    dispatch(resolveFeedback({ feedbackId: review.id })).then(() => {
      dispatch(getCurrentReview(review.id));
    });
  };

  return (
    <div className="feedback-resolution">
      <button
        type="button"
        className="button button-success button-filled button-full resolve-button"
        aria-busy={isLoading ? true : null}
        onClick={handleResolution}
        disabled={isLoading || review?.status === RESOLVED_STATUS}
      >
        {review?.status === RESOLVED_STATUS ? (
          <div>
            <FontAwesomeIcon icon={faCheck} />
            <p>{t('feedbacks.markResolved')}</p>
          </div>
        ) : (
          <p>{t('feedbacks.markResolve')}</p>
        )}
      </button>
    </div>
  );
}

export default ReviewResolutionSection;
