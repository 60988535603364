import * as Yup from 'yup';

import i18next from 'i18next';
import { emailRegex } from 'utils/constants/regex';

const { t } = i18next;

Yup.addMethod(
  Yup.string,
  'requiredTrimmed',
  function (
    blankSpacesMessage = t('fieldErrors.generic.blank'),
    emptyMessage = t('fieldErrors.generic.required'),
  ) {
    return this.test('requiredTrimmed', (value, { createError, path }) => {
      // Check for empty string
      if (!value || value === '') {
        return createError({ path, message: emptyMessage });
      }
      // Check for only blank spaces
      if (value && value.trim().length === 0) {
        return createError({ path, message: blankSpacesMessage });
      }
      return true;
    });
  },
);

Yup.addMethod(
  Yup.string,
  'range',
  function (
    min = 2,
    max = 50,
    minMessage = t('fieldErrors.generic.tooShort'),
    maxMessage = t('fieldErrors.generic.tooLong'),
  ) {
    return this.test('range', (value, { createError, path }) => {
      // Return true if value is empty (handle required validation separately)
      if (!value || value === '') {
        return true;
      }
      // Check for Minimum
      if ((value ?? '').trim().length < min)
        return createError({ path, message: minMessage });
      // Check for Maximum
      if ((value ?? '').trim().length > max)
        return createError({ path, message: maxMessage });
      return true;
    });
  },
);

Yup.addMethod(
  Yup.string,
  'customEmail',
  function (message = t('fieldErrors.invalidEmail')) {
    return this.test('customEmail', message, (value, { createError, path }) => {
      // Return true if value is empty (handle required validation separately)
      if (!value) {
        return true;
      }
      if (!emailRegex.test(value)) {
        return createError({ path, message });
      }
      if ((value ?? '').trim().length > 128) {
        return createError({
          path,
          message: t('fieldErrors.maxEmailLimit', {
            count: 128,
          }),
        });
      }

      return true;
    });
  },
);

export default Yup;
