import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import _ from 'lodash';

import PaymentBillingDetails from 'components/paymentBillingsDetails/PaymentBillingsDetails';
import PaymentCardDetail from 'components/paymentCardDetails/PaymentCardDetails';
import { Modal } from 'components/ui';
import { createPaymentMehtod } from 'redux/payments/paymentsActions';
import { buildAddress, setCardErrors } from 'utils/helpers/paymentHelpers';
import {
  billingContactValidationSchema,
  paymentValidationSchema,
} from 'utils/validations/paymentValidations';

export default function PaymentCardModal({
  isOpenModal,
  onClose,
  editPaymentMethod,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const cardNumberElement = elements?.getElement(CardNumberElement);
  const expElement = elements?.getElement(CardExpiryElement);
  const cvvElement = elements?.getElement(CardCvcElement);

  const dispatch = useDispatch();
  const {
    countries: { data: countries },
    states: { data: states },
    cities: { data: cities },
  } = useSelector((state) => state.demographics);
  const { isPaymentMethodLoading } = useSelector((state) => state.payments);

  const paymentMethodRef = useRef();
  const [step, setStep] = useState(1);
  const [stripeLoading, setStripeLoading] = useState(false);

  const paymentFormik = useFormik({
    initialValues: {
      cardholderName: '',
      cvv: '',
      exp: '',
      cardNumber: '',
    },
    onSubmit: async (values) => {
      paymentFormik.validateForm().then(async (errors) => {
        if (_.isEmpty(errors)) {
          const stripeDetails = {
            type: 'card',
            card: cardNumberElement,
            billing_details: {
              name: paymentFormik.values.cardholderName,
              address: buildAddress(countries, states, cities, {
                countryId: values.country,
                cityId: values.city,
                stateId: values.state,
                zip_code: values.zip_code,
                street_address: values.street_address,
              }),
            },
          };
          setStripeLoading(true);
          const { paymentMethod, error } = await stripe.createPaymentMethod(
            stripeDetails,
          );
          if (error) {
            toast.error(error?.message ?? error.type);
          } else {
            paymentMethodRef.current = paymentMethod;
            setStep(2);
          }
          setStripeLoading(false);
        }
      });
    },
    validateOnChange: true,
    validate: (v) => {
      let error = {};
      try {
        paymentValidationSchema.validateSync(v, {
          abortEarly: false,
        });
      } catch (e) {
        error = _.get(e, 'inner', []).reduce((acc, o) => {
          acc[o.path] = _.get(o, 'errors[0]', null);
          return acc;
        }, {});
      }
      const cardErrors = setCardErrors(elements);
      error = { ...error, ...cardErrors };
      return _.pickBy(error, (o) => o !== null);
    },
  });

  const billingFormik = useFormik({
    initialValues: {
      street_address: '',
      country: '',
      state: '',
      city: '',
      zip_code: '',
    },
    onSubmit: async (values) => {
      dispatch(
        createPaymentMehtod({
          replacingCard: editPaymentMethod,
          data: {
            payment_method_id: paymentMethodRef.current.id,
            street_address: values.street_address,
            city: parseInt(values.city, 10),
            zip_code: values.zip_code,
            is_default: editPaymentMethod
              ? editPaymentMethod.is_default
              : false,
          },
        }),
      ).then(() => {
        onClose();
      });
    },
    validateOnChange: true,
    validationSchema: billingContactValidationSchema,
  });

  useEffect(() => {
    if (isOpenModal) {
      setStep(1);
      cardNumberElement?.clear();
      expElement?.clear();
      cvvElement?.clear();

      paymentFormik.setFieldValue(
        'cardholderName',
        editPaymentMethod?.card_holder_name || '',
      );
      paymentFormik.setFieldValue(
        'cardNumber',
        `**** **** **** ${String(editPaymentMethod?.last_4_digits || '****')}`,
      );
      paymentFormik.setFieldValue(
        'exp',
        `${editPaymentMethod?.expiry_month || '01'} / ${String(
          editPaymentMethod?.expiry_year || '2024',
        ).slice(2)}`,
      );
      const billing = editPaymentMethod?.billing_address;
      if (billing) {
        billingFormik.setFieldValue('street_address', billing.street_address);
        billingFormik.setFieldValue('country', billing.country.id);
        billingFormik.setFieldValue('city', billing.city.id);
        billingFormik.setFieldValue('state', billing.state.id);
        billingFormik.setFieldValue('zip_code', billing.zip_code);
      } else {
        billingFormik.resetForm();
      }
    } else {
      paymentFormik.resetForm();
      billingFormik.resetForm();
    }
  }, [isOpenModal]);

  const handleModalClose = () => {
    onClose();
  };

  const handleNextStep = () => {
    if (step === 1) {
      paymentFormik.handleSubmit();
    } else if (step === 2) {
      billingFormik.handleSubmit();
    }
  };

  const handleBackStep = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 1) {
      onClose();
    }
  };

  const loading = stripeLoading || isPaymentMethodLoading;

  return (
    <Modal open={isOpenModal}>
      <Modal.Header
        onClose={handleModalClose}
        title={
          step === 1
            ? 'Update Payment Method'
            : 'Update payment billing address'
        }
      />
      <Modal.Body>
        {step === 1 ? (
          <PaymentCardDetail formik={paymentFormik} />
        ) : (
          <PaymentBillingDetails formik={billingFormik} />
        )}
      </Modal.Body>
      <Modal.Footer
        isLoading={loading}
        secondaryAction={handleBackStep}
        disableSecondaryAction={loading}
        secondaryLabel={step === 1 ? 'Cancel' : 'Back'}
        primaryLabel={
          step === 1 ? 'Next' : editPaymentMethod ? 'Update' : 'Add'
        }
        primaryAction={handleNextStep}
        disablePrimaryAction={loading}
      />
    </Modal>
  );
}
