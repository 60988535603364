import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useClickOutside from 'utils/hooks/useClickOutside';

import Notification from '../../../../assets/icons/icon_notification.svg';
import NotificationCenter from './NotificationCenter';

export default function NotificationBell() {
  const { t } = useTranslation();
  const { isActiveOrg } = useSelector((state) => state.authentication);
  const {
    notifications: { data: notifications },
  } = useSelector((state) => state.notifications);

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);

  const clickOutsideRef = useClickOutside({
    onClickOutside: () => setIsOpen(false),
  });

  useEffect(() => {
    const unReadNotifications = notifications.results.filter(
      (notification) => !notification.is_read,
    );
    setCount(unReadNotifications.length);
  }, [notifications]);

  return (
    <>
      <button
        ref={clickOutsideRef}
        type="button"
        className="notification-bell"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          className="cxmeter-nav-icon"
          src={Notification}
          alt="notification"
        />
        <span className="cxmeter-nav-icon-label">{t('notifications')}</span>
        {count ? <span className="notification-counter">{count}</span> : null}
      </button>
      {isActiveOrg ? (
        <NotificationCenter open={isOpen} setOpen={setIsOpen} />
      ) : null}
    </>
  );
}
