import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { getDropdownLocations } from 'redux/paginatedDropdowns/paginatedDropdownsActions';

/**
 * @author
 * @function BranchList
 * */

function BranchListFilter({ placeholder, ...rest }) {
  const { t } = useTranslation();
  const { data: locations, loading } = useSelector(
    (state) => state.paginatedDropdowns.locations,
  );

  return (
    <SelectWithCustomSuffixIcon
      id="branch-list"
      name="branch-name"
      currentFilterClassName="branch-list-filter"
      options={locations.results}
      paginationData={locations}
      nextDataAction={getDropdownLocations}
      isPaginationLoading={loading}
      placeholder={placeholder ?? t('filters.select.location')}
      {...rest}
    />
  );
}

export default BranchListFilter;
