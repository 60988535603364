import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createOrgAPICall,
  singleParentEntitySelectAPICall,
} from 'apis/cxmeter';
import { setParentEntity } from 'redux/authentication/authenticationSlice';
import { URLS } from 'utils/constants';
import {
  getFullURL,
  showErrorToast,
  storeParentEntity,
  toFormData,
} from 'utils/helpers';

export default function useOrganizationSubmitHandler() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (data) {
      try {
        setLoading(true);
        const formdata = toFormData(data);
        formdata.append(
          'last_onboarding_url',
          getFullURL(URLS.BRANCH_LIST_URL),
        );
        await createOrgAPICall(formdata);
        const selectedPE = await singleParentEntitySelectAPICall();
        dispatch(setParentEntity(selectedPE.selectedOrg));
        storeParentEntity({ ...selectedPE.selectedOrg });
        toast.success('Organization created successfully');
        navigate(URLS.BRANCH_LIST_URL, { replace: true });
      } catch (error) {
        setLoading(false);
        showErrorToast(error);
      }
    }
  };

  return { isLoading, onSubmit };
}
