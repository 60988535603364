import * as Yup from 'yup';

import translateFieldError from './validationTranslations';

const t = translateFieldError;

const resetPasswordSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .required(t('required', 'newPassword'))
      .min(8, t('minLengthPassword', 'newPassword', 8))
      .max(128, t('maxLengthPassword', 'newPassword', 128))
      .matches(/\D/, t('numbersOnlyNotAllowed', 'newPassword')),
    confirmPassword: Yup.string()
      .required(t('required', 'confirmPassword'))
      .oneOf(
        [Yup.ref('password'), null],
        t('passwordsMustMatch', 'confirmPassword'),
      ),
  });
};

export default resetPasswordSchema;
