import {
  ContentState,
  EditorState,
  Modifier,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { isEmpty } from 'lodash';

export const uploadImageCallBack = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve({ data: { link: reader.result } });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const hasSelectedText = (editorState) => {
  const selectionState = editorState.getSelection();
  if (selectionState.isCollapsed()) {
    return false;
  }
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();
  const selectedText = currentContent
    .getBlockForKey(anchorKey)
    .getText()
    .slice(startOffset, endOffset);

  return selectedText.length > 0;
};

export const findSurveyLink = (content) => {
  if (!content) return false;
  let entities = content.entityMap;
  if (content instanceof ContentState) {
    entities = convertToRaw(content).entityMap;
  }
  if (isEmpty(entities)) return false;
  return !!Object.values(entities).find(
    (entity) => entity.type === 'LINK' && entity.data.isSurveyLink,
  );
};

export const updateSurveyLink = (editorState) => {
  let contentState = editorState.getCurrentContent();
  const isSelectedText = hasSelectedText(editorState);

  const selection = editorState.getSelection();
  if (!isSelectedText) {
    return editorState;
  }
  const hasSurveyLink = findSurveyLink(contentState);
  if (hasSurveyLink) {
    const contentRaw = contentState.getBlocksAsArray();

    // Loop through all blocks to remove old survey links
    contentRaw.forEach((block) => {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            return entity.getType() === 'LINK' && entity.getData().isSurveyLink;
          }
          return false;
        },
        (start, end) => {
          const blockSelection = selection.merge({
            anchorKey: block.getKey(),
            anchorOffset: start,
            focusKey: block.getKey(),
            focusOffset: end,
          });

          contentState = Modifier.applyEntity(
            contentState,
            blockSelection,
            null,
          );
        },
      );
    });
  }

  // Create new survey link entity
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: '',
    isSurveyLink: true,
  });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newContentState = Modifier.applyEntity(
    contentStateWithEntity,
    selection,
    entityKey,
  );

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'apply-entity',
  );

  return newEditorState;
};

export const getFormattedState = (newContent) => {
  let editorState;
  if (!newContent) {
    editorState = EditorState.createEmpty();
  } else if (newContent instanceof EditorState) {
    editorState = newContent;
  } else if (typeof newContent === 'string') {
    const contentBlock = htmlToDraft(newContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    editorState = EditorState.createWithContent(contentState);
  } else if (newContent.blocks && newContent.entityMap) {
    editorState = EditorState.createWithContent(convertFromRaw(newContent));
  } else {
    editorState = EditorState.createWithContent(newContent);
  }
  return editorState;
};

export const copyContentStateWithPrefix = (content, prefix = '') => {
  let contentState = content;
  if (!(content instanceof ContentState)) {
    if (typeof content === 'string') {
      contentState = ContentState.createFromText(
        typeof content === 'string' ? content : '',
      );
    } else {
      contentState = ContentState.createFromBlockArray(content);
    }
  }
  const firstBlockKey = contentState.getFirstBlock().getKey();
  const selection = contentState.getSelectionAfter().merge({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: firstBlockKey,
    focusOffset: 0,
  });

  // Add the prefix using Modifier
  const newContentState = Modifier.insertText(contentState, selection, prefix);
  return newContentState;
};
