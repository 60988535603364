import React from 'react';
import { useTranslation } from 'react-i18next';

import WarningModal from 'components/ui/modal/WarningModal';

export default function SimilarityConfirmModal({
  isOpenModal,
  primaryAction,
  secondaryAction,
}) {
  const { t } = useTranslation();
  return (
    <WarningModal
      isOpen={isOpenModal}
      bodyStyles={{ width: 550 }}
      title={t('formBuilder.similarity.popup.title')}
      subTitle={t('formBuilder.similarity.popup.text')}
      primaryActionTitle={t('formBuilder.similarity.popup.primaryButton')}
      primaryAction={primaryAction}
      secondaryActionTitle={t('formBuilder.similarity.popup.secondaryButton')}
      secondaryAction={secondaryAction}
    />
  );
}
