import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { storeParentEntity } from 'utils/helpers';

import {
  changeBranchStatus,
  getBranchAssignments,
  getIndustryTypes,
  getLocalization,
  getOrg,
  getOrgApiKey,
  updateOrg,
  updateOrgApiKey,
  updateOrgConfig,
} from './settingsActions';

const INITIAL_STATE = {
  isLoading: false,
  org: {},
  industryTypes: {
    loading: false,
    data: [],
  },
  branchAssignments: [],
  localization: {
    isLoading: false,
    data: [],
  },
  orgApiKey: {
    data: null,
    loading: false,
  },
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetBranchAssignments: (state) => {
      state.branchAssignments = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrg.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrg.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOrg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.org = action.payload;
    });
    builder.addCase(changeBranchStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeBranchStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeBranchStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranchAssignments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranchAssignments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranchAssignments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.branchAssignments = action.payload;
    });
    builder.addCase(getLocalization.pending, (state) => {
      state.localization.isLoading = true;
    });
    builder.addCase(getLocalization.rejected, (state) => {
      state.localization.isLoading = false;
    });
    builder.addCase(getLocalization.fulfilled, (state, action) => {
      state.localization.isLoading = false;
      state.localization.data = action.payload;
    });
    builder.addCase(getIndustryTypes.pending, (state) => {
      state.industryTypes.loading = true;
    });
    builder.addCase(getIndustryTypes.rejected, (state) => {
      state.industryTypes.loading = false;
    });
    builder.addCase(getIndustryTypes.fulfilled, (state, action) => {
      state.industryTypes.loading = false;
      state.industryTypes.data = action.payload;
    });

    builder.addMatcher(
      isAnyOf(updateOrg.pending, updateOrgConfig.pending),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(updateOrg.rejected, updateOrgConfig.rejected),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(updateOrg.fulfilled, updateOrgConfig.fulfilled),
      (state, action) => {
        state.isLoading = false;
        storeParentEntity({ ...action.payload });
      },
    );

    builder.addMatcher(
      isAnyOf(getOrgApiKey.pending, updateOrgApiKey.pending),
      (state) => {
        state.orgApiKey.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(getOrgApiKey.rejected, updateOrgApiKey.rejected),
      (state) => {
        state.orgApiKey.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(getOrgApiKey.fulfilled, updateOrgApiKey.fulfilled),
      (state, action) => {
        state.orgApiKey.loading = false;
        state.orgApiKey.data = action.payload;
      },
    );

    return builder;
  },
});

export const { resetBranchAssignments } = settingsSlice.actions;
export default settingsSlice;
