import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';
import { addressInfoSchema } from './common';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

export const billingInfo = () => {
  return {
    name: Yup.string().requiredTrimmed(
      t('blank', 'name'),
      t('required', 'name'),
    ),
    email: Yup.string().required(t('required', 'email')).customEmail(),
  };
};

export const paymentDetails = {
  cardholderName: Yup.string()
    .matches(onlyLettersAndSpacesRegex, t('lettersOnly', 'cardholderName'))
    .requiredTrimmed(
      t('blank', 'cardholderName'),
      t('required', 'cardholderName'),
    ),
};

export const billingInfoValidationSchema = () =>
  Yup.object().shape({
    ...billingInfo(),
  });

export const billingContactValidationSchema = () =>
  Yup.object().shape({
    ...addressInfoSchema(),
  });

export const paymentValidationSchema = () =>
  Yup.object().shape({
    ...paymentDetails,
  });

export const paymentAndBillingValidationSchema = () =>
  Yup.object().shape({
    ...paymentDetails,
    ...addressInfoSchema(),
  });

export const isNumberKey = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
};
