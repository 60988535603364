import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getStates } from 'redux/demographics/demographicsActions';

export default function StateDropDown({
  onChange,
  value,
  countryId,
  error,
  placeholder,
  ...rest
}) {
  const dispatch = useDispatch();
  const { data: states, loading } = useSelector(
    (state) => state.demographics.states,
  );

  useEffect(() => {
    if (!countryId) return;
    dispatch(getStates({ countryId }));
  }, [countryId]);

  return (
    <SelectAndCreateOptionDropdown
      options={states}
      isPaginationLoading={loading}
      selectedOption={value || undefined}
      dropdownError={error}
      onChange={onChange}
      placeholder={placeholder ?? 'Select State'}
      disabled={!countryId}
      {...rest}
    />
  );
}
