import i18next from 'i18next';
import { CHECKBOX } from 'utils/constants/questionTypes';
import {
  allowedNameCharactersRegex,
  containsLettersAndNumberRegex,
} from 'utils/constants/regex';
import { isNumberOrParsableString } from 'utils/helpers';
import {
  getPlainTextFromContentState,
  isChoicesQuestion,
} from 'utils/helpers/surveyHelpers';

import Yup from './Yup';
import { getInvalidNameErrorMessage } from './common';
import translateFieldError from './validationTranslations';

const { t } = i18next;

const translateGenericError = (errorKey, count = null) =>
  t(`formBuilder.genericErrors.${errorKey}`, {
    count,
  });

const tfe = translateFieldError;

const getTextEditorErrors = (fieldName, editorText, min, max) => {
  const editorPlainText = getPlainTextFromContentState(editorText);
  let error = '';
  if (min > 0 && (!editorPlainText || editorPlainText === '')) {
    error = tfe('emptyNotAllowed', fieldName);
  } else if (min > 0 && editorPlainText && editorPlainText.trim() === '') {
    error = tfe('blank', fieldName);
  } else if (min && editorPlainText.trim().length < min) {
    error = tfe('minLength', fieldName, min);
  } else if (max && editorPlainText.trim().length > max) {
    error = tfe('maxLength', fieldName, min);
  }
  return error;
};

const optionSchema = Yup.array().of(
  Yup.mixed().test('no-blank-option', (option, { createError, path, from }) => {
    const parentQuestion = from[0]?.value;
    // If Parent question is Inactive then validation is not required.
    if (!parentQuestion.is_active) return true;

    if (!parentQuestion || !isChoicesQuestion(parentQuestion.question_type))
      return true;
    const optionError = getTextEditorErrors('option', option?.label, 1, 256);
    if (optionError)
      return createError({
        message: {
          error: optionError,
          questionId: parentQuestion?.id,
        },
        path,
      });
    return true;
  }),
);

const questionSchema = Yup.array().of(
  Yup.object()
    .shape({
      label: Yup.mixed(),
      question_type: Yup.string().required(),
      options: optionSchema,
      max_number_of_choices: Yup.number(),
    })
    .test('custom-question-validation', (question, { createError, path }) => {
      // If question is Inactive then validation is not required.
      if (!question.is_active) return true;
      const questionType = question.question_type;
      let questionError = '';
      const questionTextError = getTextEditorErrors(
        'question',
        question?.label,
        1,
        256,
      );
      if (questionTextError) {
        questionError = questionTextError;
      } else if (
        isChoicesQuestion(questionType) &&
        question.options.length < 1
      ) {
        questionError = translateGenericError('minimumOneOptionRequired', 1);
      } else if (questionType === CHECKBOX) {
        if (
          question.max_number_of_choices < 1 ||
          !Number.isInteger(question.max_number_of_choices)
        ) {
          questionError = translateGenericError('maxAllowedChoicesRequired');
        } else if (question.options.length < 3) {
          questionError = translateGenericError('minimumOptionsRequired', 3);
        } else if (question.options.length < question.max_number_of_choices) {
          questionError = translateGenericError('lessThanMaximumNotAllowed');
        } else if (
          question.is_required &&
          question.options.length < question.min_number_of_choices
        ) {
          questionError = translateGenericError('lessThanMinimumNotAllowed');
        } else if (
          question.is_required &&
          question.max_number_of_choices < question.min_number_of_choices
        ) {
          questionError = translateGenericError('minGreaterThanMax');
        }
      }
      if (questionError) {
        return createError({
          message: { error: questionError, questionId: question.id },
          path: `${path}.label`,
        });
      }
      return true;
    }),
);

const sectionSchema = Yup.array().of(
  Yup.object().shape({
    questions: questionSchema,
  }),
);

const getQuestionKey = (question) => {
  let label = question.label ?? '';
  if (question.label?.getPlainText) {
    label = question.label?.getPlainText();
  }
  return `${question.question_type}_${label.trim().toLowerCase()}`;
};

const surveySchema = () =>
  Yup.object()
    .shape({
      title: Yup.string()
        .requiredTrimmed(
          tfe('blank', 'surveyTitle'),
          tfe('required', 'surveyTitle'),
        )
        .matches(allowedNameCharactersRegex, getInvalidNameErrorMessage())
        .matches(
          containsLettersAndNumberRegex,
          tfe('onlySpecialCharactersNotAllowed', 'surveyTitle'),
        )
        .range(
          1,
          256,
          tfe('minLength', 'surveyTitle', 1),
          tfe('maxLength', 'surveyTitle', 256),
        ),
      experience: Yup.mixed().test(
        'valid-experience',
        tfe('required', 'experience'),
        (experience) => {
          if (isNumberOrParsableString(experience)) return true;
          return experience?.id;
        },
      ),
      sections: sectionSchema,
      welcome_text: Yup.mixed().test(
        'custom-welcome-text-validation',
        (welcomeText) => {
          if (welcomeText === null || welcomeText === undefined) return true;
          const welcomePlainText = getPlainTextFromContentState(welcomeText);
          return welcomePlainText.trim().length <= 256;
        },
      ),
      respondent_question: Yup.object().shape({
        label: Yup.mixed().test(
          'respondent-question-text-validation',
          (respondentQuestion, { createError, path, parent }) => {
            if (!parent.is_active) return true;
            const respondentQuestionError = getTextEditorErrors(
              'respondentQuestion',
              respondentQuestion,
              1,
              256,
            );

            if (respondentQuestionError) {
              return createError({
                message: respondentQuestionError,
                path,
              });
            }
            return true;
          },
        ),
        name_placeholder: Yup.string().when('is_active', {
          is: true,
          then: Yup.string()
            .requiredTrimmed(
              tfe('blank', 'namePlaceholder'),
              tfe('required', 'namePlaceholder'),
            )
            .range(
              1,
              24,
              tfe('minLength', 'namePlaceholder', 1),
              tfe('maxLength', 'namePlaceholder', 24),
            ),
          otherwise: Yup.string(),
        }),
        email_placeholder: Yup.string().when('is_active', {
          is: true,
          then: Yup.string()
            .requiredTrimmed(
              tfe('blank', 'emailPlaceholder'),
              tfe('required', 'emailPlaceholder'),
            )
            .range(
              1,
              24,
              tfe('minLength', 'emailPlaceholder', 1),
              tfe('maxLength', 'emailPlaceholder', 24),
            ),
          otherwise: Yup.string(),
        }),
        is_active: Yup.boolean(),
      }),
    })
    .test('custom-unique-question-labels-validation', ({ sections = [] }) => {
      const duplicateQuestionCount = {};

      sections.forEach((section) => {
        const question = section.questions[0];
        const key = getQuestionKey(question);
        if (!duplicateQuestionCount[key]) {
          duplicateQuestionCount[key] = 0;
        }
        duplicateQuestionCount[key] += 1;
      });
      const errors = [];
      sections.forEach((section, i) => {
        const question = section.questions[0];
        const path = `sections[${i}].questions[0].label`;
        const key = getQuestionKey(question);
        if (duplicateQuestionCount[key] > 1) {
          errors.push(
            new Yup.ValidationError(
              {
                error: translateGenericError('duplicateQuestionsNotAllowed'),
                questionId: question.id,
              },
              null,
              path,
            ),
          );
        }
      });
      if (errors.length > 0) {
        throw new Yup.ValidationError(errors);
      }
      return true;
    });

export default surveySchema;
