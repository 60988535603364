import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { URLS } from 'utils/constants';
import {
  getPathNameFromFullURL,
  isLocationChildRoute,
  isPaymentChildRoute,
} from 'utils/helpers';

import usePopStateListener from './usePopStateListener';

export default function useOnboardingRedirect() {
  const lastCompletedStep = useSelector((state) =>
    getPathNameFromFullURL(
      state.authentication.parentEntity?.last_onboarding_url,
    ),
  );
  const token = useSelector((state) => state.authentication.token);
  const isLoading = useSelector((state) => state.authentication.isAppLoading);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isRedirecting, setIsRedirecting] = useState(true); // New state for blocking rendering

  const historyChanged = usePopStateListener();

  useEffect(() => {
    if (!isLoading) {
      if (lastCompletedStep === null) {
        if (pathname.startsWith(URLS.SIGNUP_URL)) {
          navigate(URLS.DASHBOARD_URL);
        }
      } else if (token && !lastCompletedStep) {
        navigate(URLS.REGISTER_ORG_URL, { replace: true });
      } else if (
        pathname !== lastCompletedStep &&
        lastCompletedStep &&
        !isLocationChildRoute(lastCompletedStep, pathname) &&
        !isPaymentChildRoute(lastCompletedStep, pathname)
      ) {
        navigate(getPathNameFromFullURL(lastCompletedStep), { replace: true });
      }

      setIsRedirecting(false);
    }
  }, [isLoading, historyChanged]);

  return { isLoading, isRedirecting, lastCompletedStep };
}
