import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { Modal } from 'components/ui';
import { getPaymentPlans, upgradePlan } from 'redux/payments/paymentsActions';

import './_ChangePaymentPlanModal.scss';

export default function ChangePaymentPlanModal({ onClose, isOpenModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isPaymentMethodLoading,
    paymentPlans: { plans, isPlansLoading },
  } = useSelector((s) => s.payments);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState();

  const modalCloseHandler = () => {
    setSelectedPlan(null);
    onClose();
  };

  const upgradePlanHandler = () => {
    dispatch(upgradePlan(selectedPlan.id)).then(({ payload }) => {
      if (payload) {
        setSubscriptionError(payload);
      } else {
        modalCloseHandler();
      }
    });
  };

  const selectNewPlanHandler = (plan) => {
    if (plan.price_difference !== null) {
      setSelectedPlan(plan);
      setSubscriptionError(undefined);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      dispatch(getPaymentPlans('price_difference=true'));
    } else {
      setSubscriptionError(null);
    }
  }, [isOpenModal]);

  return (
    <Modal open={isOpenModal} onClose={modalCloseHandler}>
      <Modal.Header title="Change Plan" onClose={modalCloseHandler} />
      <Modal.Body>
        {isPlansLoading ? 'Loading...' : null}
        <div className="plans-container">
          <div className="plans">
            {(plans ?? []).map((plan) => (
              <div
                key={plan.id}
                className={`plan ${
                  selectedPlan?.id === plan.id ? 'selected-plan' : ''
                }
              ${plan.price_difference === null ? 'current-plan' : ''}`}
              >
                {plan.price_difference === null ? (
                  <span className="current-plan-chip">Current</span>
                ) : null}
                <div>
                  <img
                    width={40}
                    height={40}
                    src="/assets/icons/icon-payment.svg"
                    alt="icon"
                    className="mb-1"
                  />
                  <h5 className="my-2">{plan.title}</h5>
                  {plan.overall_cost ? (
                    <small className="m-0">${plan.overall_cost}</small>
                  ) : (
                    <div className="d-flex flex-column">
                      {plan.cost_per_user ? (
                        <small className="m-0">
                          $
                          {t('settings.payments.plans.amountPerUser', {
                            amount: plan.cost_per_user,
                          })}
                        </small>
                      ) : null}
                      {plan.cost_per_branch ? (
                        <small className="m-0">
                          $
                          {t('settings.payments.plans.amountPerLocation', {
                            amount: plan.cost_per_branch,
                          })}
                        </small>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="features">
                  {plan?.max_users ? (
                    <p>
                      {t('settings.payments.plans.maxUsers', {
                        number: plan.max_users,
                      })}
                    </p>
                  ) : (
                    <p>{t('settings.payments.plans.unlimitedUsers')}</p>
                  )}
                  {plan?.max_branches ? (
                    <p>
                      {t('settings.payments.plans.maxLocations', {
                        number: plan.max_branches,
                      })}
                    </p>
                  ) : (
                    <p>{t('settings.payments.plans.unlimitedLocations')}</p>
                  )}
                  {plan?.is_trial_allowed && (
                    <p>
                      {t('settings.payments.plans.trialPeriod', {
                        days: plan.trial_period_days,
                      })}
                    </p>
                  )}
                </div>
                {plan.price_difference === null ? null : (
                  <div>
                    <button
                      className={`button button-full ${
                        selectedPlan?.id === plan.id
                          ? 'button-primary'
                          : 'button-secondary '
                      }`}
                      type="button"
                      onClick={() => selectNewPlanHandler(plan)}
                    >
                      {selectedPlan?.id === plan.id ? 'Selected' : 'Select'}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <hr className="m-0" />
          <div className="estimation">
            <p>{t('settings.payments.plans.estimatedSubTotal')}</p>
            <h5>${selectedPlan?.price_difference ?? 0}</h5>
            <small>{t('settings.payments.plans.note')}</small>
          </div>
          <div className="txt-error" style={{ textAlign: 'right' }}>
            {subscriptionError}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        disablePrimaryAction={isPaymentMethodLoading || isEmpty(selectedPlan)}
        isLoading={isPaymentMethodLoading}
        primaryLabel={t('settings.payments.plans.submit')}
        primaryAction={upgradePlanHandler}
        secondaryLabel={t('settings.payments.plans.cancel')}
        secondaryAction={modalCloseHandler}
        disableSecondaryAction={isPaymentMethodLoading}
      />
    </Modal>
  );
}
