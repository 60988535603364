import dayjs from 'dayjs';
import { ContentState } from 'draft-js';

import i18next from 'i18next';
import {
  REPEAT_CUSTOM,
  REPEAT_MONTHLY,
  REPEAT_WEEKLY,
} from 'utils/constants/manageEmails';
import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import { findSurveyLink } from 'utils/helpers/richTextEditorHelpers';

import Yup from './Yup';
import './common';
import translateFieldError from './validationTranslations';

const { t } = i18next;

const tfe = translateFieldError;

const tge = (errorKey, param) =>
  t(`settings.manageEmails.genericErrors.${errorKey}`, param);

const EmptyMembersValidation = (values, { createError }) => {
  const { members, members_file: membersFile, groups } = values;
  let isMembersPresent = false;
  isMembersPresent = members.find((m) => m.email);
  if (groups && !isMembersPresent) {
    isMembersPresent = groups.find((g) => g.id);
  }
  if (!isMembersPresent && !membersFile) {
    return createError({
      message: tge('emptyMembers', {
        groups: groups ? ', a group' : '',
      }),
      path: 'members_file',
    });
  }
  return true;
};

const TextEditorValidation = (validateSurveyLink = true) =>
  Yup.mixed().test('email-body-validation', (body, { createError, path }) => {
    const requiredEmailBody = tge('requiredEmailBody');
    if (!body || (typeof body === 'string' && body.trim() === '')) {
      return createError({
        message: requiredEmailBody,
        path,
      });
    }
    if (typeof body !== 'string' && body instanceof ContentState) {
      const bodyPlainText = body.getPlainText();
      if (bodyPlainText.trim() === '') {
        return createError({ message: requiredEmailBody, path });
      }
      if (!validateSurveyLink) return true;
      const isSurveyLinkPresent = findSurveyLink(body);
      if (!isSurveyLinkPresent) {
        return createError({
          message: tge('shouldContainLink'),
          path,
        });
      }
    }

    return true;
  });

export const inviteGroupValidationSchema = () =>
  Yup.object()
    .shape({
      name: Yup.string()
        .requiredTrimmed(
          tfe('blank', 'groupName'),
          tfe('required', 'groupName'),
        )
        .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'groupName'))
        .range(
          1,
          128,
          tfe('minLength', 'groupName', 1),
          tfe('maxLength', 'groupName', 128),
        ),
      members: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email(tfe('invalidEmailFormat'))
              .required(tfe('required', 'email')),
          }),
        )
        .required(tfe('required', 'members')),
      members_file: Yup.mixed()
        .nullable()
        .test(
          'fileType',
          tge('onlyCSVAllowed'),
          (value) => !value || (value && value.type === 'text/csv'),
        ),
    })
    .test('non-empty-members', EmptyMembersValidation);

export const inviteEmailValidationSchema = () =>
  Yup.object()
    .shape({
      id: Yup.mixed().notRequired(),
      subject: Yup.string()
        .requiredTrimmed(tfe('blank', 'subject'), tfe('required', 'subject'))
        .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'subject'))
        .range(
          1,
          256,
          tfe('minLength', 'subject', 1),
          tfe('maxLength', 'subject', 256),
        ),
      members: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email(tfe('invalidEmailFormat'))
              .required(tfe('required', 'email')),
          }),
        )
        .required(tfe('required', 'members')),
      groups: Yup.array(),
      members_file: Yup.mixed()
        .nullable() // Allows null or undefined values
        .test(
          'fileType',
          tge('onlyCSVAllowed'),
          (value) => !value || (value && value.type === 'text/csv'),
        ),
      entity_experience: Yup.mixed()
        .required(tfe('required', 'feedbackForm'))
        .test(
          'valid-feedback',
          tfe('required', 'feedbackForm'),
          (entityExperience) => {
            const id = entityExperience?.id ?? entityExperience;
            return typeof id === 'string' || typeof id === 'number';
          },
        ),
      email_json_data: TextEditorValidation(),
      updateHTMLEditorState: Yup.boolean(),
      show_email_schedule: Yup.boolean(),
      scheduled_at: Yup.object()
        .when('show_email_schedule', {
          is: true,
          then: Yup.object().shape({
            date: Yup.mixed().required(tfe('required', 'scheduleEmailDate')),
            time: Yup.mixed().required(tfe('required', 'scheduleEmailTime')),
          }),
          otherwise: Yup.object().notRequired(),
        })
        .test('date-time-check', tge('oneHourFutureTime'), function (value) {
          const { date, time } = value;
          const { id, show_email_schedule: showEmailSchedule } = this.parent;
          if (id || !showEmailSchedule) return true;
          const selectedDateTime = dayjs(
            `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format(
              'HH:mm',
            )}`,
          );
          const nextHour = dayjs().add(1, 'hour');
          return selectedDateTime.isAfter(nextHour);
        }),
      show_reminder_schedule: Yup.boolean(),
      reminder_schedules: Yup.object().when('show_reminder_schedule', {
        is: true,
        then: Yup.object().shape({
          reminder_type: Yup.string()
            .required(tfe('required', 'reminderType'))
            .oneOf(
              [REPEAT_WEEKLY, REPEAT_MONTHLY, REPEAT_CUSTOM],
              tfe('invalid', 'reminderType'),
            ),

          reminder_time: Yup.mixed().required(tfe('required', 'reminderTime')),
          custom_reminder_day: Yup.array()
            .of(
              Yup.string().oneOf(
                [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ],
                tfe('invalid', 'day'),
              ),
            )
            .when('reminder_type', {
              is: 'Custom',
              then: Yup.array()
                .min(1, tge('requiredCustomReminderDay'))
                .required(tfe('required', 'customReminderDays')),
              otherwise: Yup.array(),
            }),
          ends_at: Yup.mixed()
            .required(tfe('required', 'endDate'))
            .test('is-date', tge('validEndDate'), (value) =>
              dayjs(value).isValid(),
            )
            .test('is-future', tge('futureEndDate'), (value) =>
              dayjs(value).isAfter(dayjs()),
            )
            .test('is-within-one-year', tge('oneYearEndDate'), (value) =>
              dayjs(value).isBefore(dayjs().add(1, 'year')),
            ),
        }),
        otherwise: Yup.object().notRequired(),
      }),
    })
    .test('non-empty-members', EmptyMembersValidation);

export const inviteTemplateValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(
        tfe('blank', 'templateName'),
        tfe('required', 'templateName'),
      )
      .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'templateName'))
      .range(
        1,
        128,
        tfe('minLength', 'templateName', 1),
        tfe('maxLength', 'templateName', 128),
      ),
    entity_experience: Yup.mixed()
      .required(tfe('required', 'feedbackForm'))
      .test(
        'valid-feedback',
        tfe('required', 'feedbackForm'),
        (entityExperience) => {
          const id = entityExperience?.id ?? entityExperience;
          return typeof id === 'string' || typeof id === 'number';
        },
      ),
    email_json_data: TextEditorValidation(false),
  });
