import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { faArchive, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import {
  archiveNotificationsAction,
  getNotificationsAction,
  updateNotificationsAction,
} from 'redux/notification/notificationActions';
import { URLS } from 'utils/constants';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';

import './notification.scss';

export default function NotificationCenter({ open, setOpen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const {
    notifications: { data: notifications, loading },
  } = useSelector((state) => state.notifications);

  const handleClickNotification = (notification) => {
    setOpen(false);
    if (notification?.text) {
      navigate({
        pathname: URLS.REVIEWS_LISTING_URL,
        search: createSearchParams({
          experience: notification.experience?.id,
          experience_name: 'Google Map Reviews',
        }).toString(),
      });
      return;
    }
    if (location.pathname === URLS.REVIEWS_LISTING_URL) {
      searchParams.set('openFeedback', notification.object_id);
      setSearchParams(searchParams);
    } else {
      navigate({
        pathname: URLS.REVIEWS_LISTING_URL,
        search: createSearchParams({
          openFeedback: notification.object_id,
        }).toString(),
      });
    }
  };

  const onClickArchive = (id) => dispatch(archiveNotificationsAction(id));

  // get notifications
  useEffect(() => {
    dispatch(getNotificationsAction());
  }, []);

  // once notification centre has been opened, send a call to mark all unseen as read
  useEffect(() => {
    const unseenNotifications = notifications.results.filter(
      (notification) => !notification.is_read,
    );
    if (open && unseenNotifications?.length > 0) {
      dispatch(
        updateNotificationsAction({
          mark_read_ids: unseenNotifications.map(
            (notification) => notification.id,
          ),
        }),
      );
    }
  }, [open]);

  const handleNextNotifications = () => {
    if (notifications.next) {
      const params = new URL(notifications.next).searchParams;
      const lastViewedId = params.get('last_viewed_id');
      dispatch(getNotificationsAction({ last_viewed_id: lastViewedId }));
    }
  };

  const { containerRef } = useInfiniteScroll({
    fetchData: handleNextNotifications,
    hasMore: !!notifications.next,
    loading,
  });

  return (
    <article
      role="presentation"
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
      className={`notification-center ${open ? 'notification-open' : ''}`}
    >
      {loading && !notifications.next ? (
        <small className="spinner-loader">
          <FontAwesomeIcon icon={faSpinner} />
        </small>
      ) : (
        <ul>
          {notifications.results.length === 0 ? (
            <li className="notification-empty">
              <h3>{t('navigation.notificationCentre.caughtUp')}</h3>
            </li>
          ) : (
            <>
              {notifications.results.map((notification) => (
                <li
                  role="presentation"
                  key={notification.id}
                  className={`notification notification-${notification.segment.name}`}
                  onClick={() => handleClickNotification(notification)}
                >
                  <img
                    src={
                      notification?.text
                        ? `/assets/icons/icon_google.svg`
                        : `/assets/icons/icon_${notification.segment.name}.svg`
                    }
                    alt="happy"
                  />
                  <div className="notification__content">
                    <p>
                      {notification?.text
                        ? notification.text
                        : `${t('navigation.notificationCentre.text', {
                            name:
                              notification?.customer?.name || t('anonymous'),
                          })} ${dayjs(notification.created_at).fromNow()}`}
                    </p>
                  </div>
                  {notification.is_archived ? (
                    <FontAwesomeIcon
                      icon={faArchive}
                      className="notification-archive"
                      onClick={() => onClickArchive(notification.id)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="notification-archive"
                      icon={faArchive}
                    />
                  )}
                </li>
              ))}
              {loading ? (
                <li>
                  <p className="m-0" style={{ color: '#de0024' }}>
                    {t('loadingDot')}
                  </p>
                </li>
              ) : null}
            </>
          )}
        </ul>
      )}
    </article>
  );
}
