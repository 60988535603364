import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDropdownExperiencesAPICall,
  getDropdownLocationsAPICall,
  getDropdownRegionsAPICall,
} from 'apis/paginatedDropdowns';
import { DEFAULT_PAGINATED_DROPDOWN_SIZE } from 'utils/constants';
import { showErrorToast } from 'utils/helpers';

export const getDropdownRegions = createAsyncThunk(
  'paginatedDropdowns/getDropdownRegions',
  async (params) =>
    getDropdownRegionsAPICall({
      size: params?.size ?? DEFAULT_PAGINATED_DROPDOWN_SIZE,
      ...params,
    }).catch(showErrorToast),
);

export const getDropdownLocations = createAsyncThunk(
  'paginatedDropdowns/getDropdownLocations',
  async (params) =>
    getDropdownLocationsAPICall({
      size: params?.size ?? DEFAULT_PAGINATED_DROPDOWN_SIZE,
      ...params,
    }).catch(showErrorToast),
);

export const getDropdownExperiences = createAsyncThunk(
  'paginatedDropdowns/getDropdownExperiences',
  async (params) =>
    getDropdownExperiencesAPICall({
      size: params?.size ?? DEFAULT_PAGINATED_DROPDOWN_SIZE,
      ...params,
    }).catch(showErrorToast),
);
