import { useEffect, useState } from 'react';

import './dropdown.scss';

function DropdownMenu({ items }) {
  return (
    <>
      {items.map(
        (
          { label, onClick, icon, disabled, className = '', children },
          index,
        ) => (
          <li key={`${label}-${index}`}>
            <button
              type="button"
              className={`cxmeter-dropdown-menu-item ${className}`}
              onClick={onClick}
              disabled={disabled}
            >
              {children ?? (
                <span>
                  {icon}
                  <span className="label">{label}</span>
                </span>
              )}
            </button>
          </li>
        ),
      )}
    </>
  );
}

export default function Dropdown({ children, items, dropdownClass }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(
        () =>
          document.addEventListener('click', () => setIsOpen(false), {
            once: true,
          }),
        10,
      );
    }
  }, [isOpen]);

  return (
    <article
      className={`cxmeter-dropdown ${
        isOpen ? 'cxmeter-dropdown-open' : ''
      } ${dropdownClass}`}
    >
      <button
        type="button"
        className="cxmeter-dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
      </button>
      {/* eslint-disable-next-line */}
      <ul className="cxmeter-dropdown-menu" onClick={() => setIsOpen(false)}>
        <DropdownMenu items={items} />
      </ul>
    </article>
  );
}
