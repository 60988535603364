import { useRef, useState } from 'react';

import { Table } from 'antd';

import ReviewPanel from 'components/reviewsListing/ReviewPanel';
import { attachKeysToDataSource } from 'utils/helpers';
import { getReceivedInboxTableColumns } from 'utils/helpers/emailsManagementHelper';

function ReceivedInbox({
  data: { received: receivedInvites = [] } = {},
  loading,
}) {
  const [showReviewPanel, setShowReviewPanel] = useState(false);
  const reviewIdRef = useRef(null);

  const showReviewHandler = (rec) => {
    reviewIdRef.current = rec.feedback_id;
    setShowReviewPanel(true);
  };

  return (
    <>
      <Table
        className="left-align receive-inbox-table"
        loading={loading}
        columns={getReceivedInboxTableColumns(showReviewHandler)}
        scroll={{ x: 'max-content' }}
        dataSource={attachKeysToDataSource(receivedInvites)}
      />
      <ReviewPanel
        isOpen={showReviewPanel}
        setIsOpen={setShowReviewPanel}
        reviewId={reviewIdRef.current}
        reviewIndex={0}
        totalReviews={1}
        showNav={false}
      />
    </>
  );
}
export default ReceivedInbox;
