import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';

import './_AppQRCodes.scss';

export default function AppQRCodes() {
  const { t } = useTranslation();

  return (
    <div className="app-qr-codes">
      <div>
        <img
          src="/assets/images/app-store-qr.png"
          className="img-fluid"
          alt="app-store"
        />
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <FaAppStoreIos size={40} />
          <span>{t('appStore')}</span>
        </div>
      </div>
      <div>
        <img
          src="/assets/images/play-store-qr.png"
          className="img-fluid"
          alt="play-store"
        />
        <div className="d-flex gap-2 justify-content-center align-items-center">
          <FaGooglePlay size={40} />
          <span>{t('playStore')}</span>
        </div>
      </div>
    </div>
  );
}
