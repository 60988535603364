import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import CityDropDown from 'components/demographics/cityDropDown/CityDropDown';
import CountryDropDown from 'components/demographics/countryDropDown/CountryDropDown';
import RegionsDropDown from 'components/demographics/regionsDropdown/RegionsDropdown';
import StateDropDown from 'components/demographics/stateDropDown/StateDropDown';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import {
  onCountryChangeAction,
  onStateChangeAction,
} from 'redux/demographics/demographicsSlice';

import GoogleReviewCard from './GoogleReviews/GoogleReviewsCard';
import GoogleReviewsPriceModal from './GoogleReviews/GoogleReviewsPriceModal';
import UnlockGoogleReviewsCard from './GoogleReviews/UnlockGoogleReviewsCard';

export function LocationForm({ formik, isOnboarding }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { values, errors, touched } = formik ?? {};

  const handleCountryChange = (countryId) => {
    formik.setValues({ ...values, country: countryId, state: '', city: '' });
    dispatch(onCountryChangeAction());
  };
  const handleStateChange = (stateId) => {
    formik.setValues({ ...values, state: stateId, city: '' });
    dispatch(onStateChangeAction());
  };

  return (
    <div
      className={`location-info-form-container ${
        isOnboarding ? 'onboarding-info-form' : ''
      }`}
    >
      <FormikLabeledInput
        formik={formik}
        inputName="name"
        placeholder={t('inputFields.locationName')}
      />
      <div>
        <p style={{ fontWeight: 600 }}>{t('inputFields.address')}</p>
        <div
          className={`location-address-container ${
            isOnboarding ? 'onboarding-location-address-container' : ''
          }`}
        >
          <FormikLabeledInput
            formik={formik}
            inputName="street_address"
            placeholder={t('inputFields.streetAddress')}
            wrapperClassName="location-street-address"
          />
          <RegionsDropDown
            placeholder={`${t('selectOption.region')} *`}
            value={values.region}
            onChange={(id) => formik.setFieldValue('region', id)}
            error={touched.region ? errors.region : null}
            wrapperClassName="region-dropdown"
            listHeight={170}
          />
          <CountryDropDown
            placeholder={`${t('selectOption.country')} *`}
            value={values.country}
            onChange={handleCountryChange}
            error={touched.country ? errors.country : null}
            listHeight={170}
          />
          <StateDropDown
            placeholder={`${t('selectOption.state')} *`}
            onChange={handleStateChange}
            value={values.state}
            countryId={values.country}
            error={touched.state ? errors.state : null}
            listHeight={170}
          />
          <CityDropDown
            placeholder={`${t('selectOption.city')} *`}
            onChange={(id) => formik.setFieldValue('city', id)}
            value={values.city}
            stateId={values.state}
            error={touched.city ? errors.city : null}
            listHeight={170}
          />
          <FormikLabeledInput
            formik={formik}
            inputName="zip_code"
            placeholder={t('inputFields.zipCode')}
          />
        </div>
      </div>
    </div>
  );
}

export function GoogleReviewsLocationForm({ formik }) {
  const { parentEntity } = useSelector((state) => state.authentication);
  const { allowed_analytics: { analytics } = {} } = parentEntity ?? {};
  const [isOpenGoogleReviewsPriceModal, setIsOpenGoogleReviewsPriceModal] =
    useState(false);

  const { values, isSubmitting } = formik ?? {};

  useEffect(() => {
    if (
      isEmpty(formik.errors) &&
      isSubmitting &&
      values.google_place_id &&
      values.google_place_id !== values.previousGooglePlaceId
    ) {
      setIsOpenGoogleReviewsPriceModal(true);
    }
  }, [isSubmitting]);

  return (
    <>
      {analytics?.google_reviews ? (
        <GoogleReviewCard formik={formik} />
      ) : (
        <UnlockGoogleReviewsCard />
      )}
      <LocationForm formik={formik} />
      {isOpenGoogleReviewsPriceModal ? (
        <GoogleReviewsPriceModal
          formik={formik}
          onClose={() => setIsOpenGoogleReviewsPriceModal(false)}
        />
      ) : null}
    </>
  );
}
