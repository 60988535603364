import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SAAS_AGREEMENT_LAST_MODIFIED } from 'config';

import { getLocationsV2 } from 'redux/branchComparison/branchComparisonActions';

import './_saasAgreement.scss';

function SaasAgreement() {
  const dispatch = useDispatch();
  const { user, parentEntity } = useSelector((s) => s.authentication);
  const { locations } = useSelector((s) => s.branchComparison);
  const [displayLocation, setDisplayLocation] = useState(null);

  const today = new Date();
  const date = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const displayDate = `${month} ${date}, ${year}`;

  useEffect(() => {
    dispatch(getLocationsV2());
  }, []);

  useEffect(() => {
    if (locations?.data?.length) {
      const locationStr = `${locations.data[0].name}, ${locations.data[0].street_address}, ${locations.data[0].city.name} ${locations.data[0].zip_code}`;
      setDisplayLocation(locationStr);
    }
  }, [locations]);

  return (
    <div className="saas">
      <div className="saas__header">
        <strong className="saas__header-subtitle">
          Last Modified:&nbsp;
          {SAAS_AGREEMENT_LAST_MODIFIED}
        </strong>
      </div>
      <section className="saas__section">
        <h1>Introduction</h1>
        <p>
          This Software as a Service Agreement (the <strong>“Agreement”</strong>
          ), effective as of {displayDate}
          (the <strong>“Effective Date”</strong>), is by and between CXMeter
          Inc., a Texas limited liability company located at 2035 Central Cir,
          Suite # 201 McKinney, TX 75069 (<strong>“Provider”</strong> or{' '}
          <strong>“CXMeter Inc.”</strong>), and {parentEntity?.name},{' '}
          {displayLocation} (<strong>“Customer”</strong>).
        </p>
        <p>
          WHEREAS, Provider provides access to its software-as-a-service
          offerings to its customers called CXMeter, which is a platform that
          collects and process End User customer satisfaction data for Customers
          who may utilize such information for improving their services
          purposes;
        </p>
        <p>
          WHEREAS, Customer desires to access certain software-as-a-service
          offerings described herein, and Provider desires to provide Customer
          access to such offerings, subject to the terms and conditions set
          forth in this Agreement
        </p>
        <p>
          NOW, THEREFORE, in consideration of the mutual covenants, terms, and
          conditions set forth herein, and for other good and valuable
          consideration, the receipt and sufficiency of which are hereby
          acknowledged, the parties agree as follows:
        </p>
      </section>
      <section className="saas__section">
        <h1>1. Definitions</h1>
        <p>
          <strong>“Access Credentials”</strong> means any user name,
          identification number, password, license or security key, security
          token, PIN, or other security code, method, technology, or device,
          used alone or in combination, to verify an individual&apos;s identity
          and authorization to access and use the Services.
        </p>
        <p>
          <strong>“Action”</strong> means any claim, action, cause of action,
          demand, lawsuit, arbitration, inquiry, audit, notice of violation,
          proceeding, litigation, citation, summons, subpoena, or investigation
          of any nature, civil, criminal, administrative, regulatory, or other,
          whether at law, in equity, or otherwise.
        </p>
        <p>
          <strong>“Affiliate”</strong> of a Person means any other Person that
          directly or indirectly, through one or more intermediaries, controls,
          is controlled by, or is under common control with, such Person.
        </p>
        <p>
          <strong>“Agreement”</strong> has the meaning set forth in the
          preamble.
        </p>
        <p>
          <strong>“Authorized Users”</strong> means Customer&apos;s employees,
          consultants, contractors, and agents (a) who are authorized by
          Customer to access and use the Services under the rights granted to
          Customer pursuant to this Agreement; and (b) for whom access to the
          Services has been purchased hereunder.
        </p>
        <p>
          <strong>“Availability Requirement”</strong> has the meaning set forth
          in 95.1.
        </p>
        <p>
          <strong>“Available”</strong> has the meaning set forth in 95.1.
        </p>
        <p>
          <strong>“Backup Policy”</strong> has the meaning set forth in 96.
        </p>
        <p>
          <strong>“Confidential Information”</strong> has the meaning set forth
          in 129.1.
        </p>
        <p>
          <strong>“Customer”</strong> has the meaning set forth in the preamble.
        </p>
        <p>
          <strong>
            “Customer&apos;s Designated Technical Support Personnel”
          </strong>{' '}
          means the designated primary contact person at the Customer who shall
          be listed in Exhibit B. If there is a change to this person, Customer
          shall provide the name and contract information for the replacement
          personnel within 24 hours.
        </p>
        <p>
          <strong>“Customer Data”</strong> means information, data, and other
          content, in any form or medium, that is collected, downloaded, or
          otherwise received, directly or indirectly, from Customer or an
          Authorized User by or through the Services or that incorporates or is
          derived from the Processing of such information, data, or content by
          or through the Services. For the avoidance of doubt, Customer Data
          does not include Resultant Data or any other information reflecting
          the access or use of the Services by or on behalf of Customer or any
          Authorized User.
        </p>
        <p>
          <strong>“Customer Failure”</strong> has the meaning set forth in 94.2.
        </p>
        <p>
          <strong>“Customer Indemnitee”</strong> has the meaning set forth in
          1512.1.
        </p>
        <p>
          <strong>“Customer Systems”</strong> means the Customer&apos;s
          information technology infrastructure, including computers, software,
          hardware, databases, electronic systems (including database management
          systems), and networks, whether operated directly by Customer or
          through the use of third-party services.
        </p>
        <p>
          <strong>“Disclosing Party”</strong> has the meaning set forth in
          129.1.
        </p>
        <p>
          <strong>“Effective Date”</strong> has the meaning set forth in the
          preamble.
        </p>
        <p>
          <strong>“End User”</strong> means third-parties that use the Services
          to provide End User Data to the Customer.
        </p>
        <p>
          <strong>“End User Data”</strong> means customer satisfaction feedback,
          responses to question, sensitive personal information and any other
          data submitted by an End User through the Service for Customer use.
        </p>
        <p>
          <strong>“Exceptions”</strong> has the meaning set forth in 95.1.
        </p>
        <p>
          <strong>“Feedback”</strong> means customer satisfaction, inputs,
          feedback, responses to question and other feedback submitted by an End
          User through the Service for Customer use.
        </p>
        <p>
          <strong>“Fees”</strong> has the meaning set forth in 118.1.
        </p>
        <p>
          <strong>“Force Majeure Event”</strong> has the meaning set forth in
          2015.9.
        </p>
        <p>
          <strong>“Harmful Code”</strong> means any software, hardware, or other
          technology, device, or means, including any virus, worm, malware, or
          other malicious computer code, the purpose or effect of which is to
          (a) permit unauthorized access to, or to destroy, disrupt, disable,
          distort, or otherwise harm or impede in any manner any (i) computer,
          software, firmware, hardware, system, or network; or (ii) any
          application or function of any of the foregoing or the security,
          integrity, confidentiality, or use of any data Processed thereby; or
          (b) prevent Customer or any Authorized User from accessing or using
          the Services or Provider Systems as intended by this Agreement.
          Harmful Code does not include any Provider Disabling Device.
        </p>
        <p>
          <strong>“Indemnitee”</strong> has the meaning set forth in 1612.3.
        </p>
        <p>
          <strong>“Indemnitor”</strong> has the meaning set forth in 1612.3.
        </p>
        <p>
          <strong>“Initial Term”</strong> has the meaning set forth in 1714.1.
        </p>
        <p>
          <strong>“Intellectual Property Rights”</strong> means any and all
          registered and unregistered rights granted, applied for, or otherwise
          now or hereafter in existence under or related to any patent,
          copyright, trademark, trade secret, database protection, or other
          intellectual property rights laws, and all similar or equivalent
          rights or forms of protection, in any part of the world.
        </p>
        <p>
          <strong>“Law”</strong> means any statute, law, ordinance, regulation,
          rule, code, order, constitution, treaty, common law, judgment, decree,
          or other requirement of any federal, state, local, or foreign
          government or political subdivision thereof, or any arbitrator, court,
          or tribunal of competent jurisdiction.
        </p>
        <p>
          <strong>“Losses”</strong> means any and all losses, damages,
          deficiencies, claims, actions, judgments, settlements, interest,
          awards, penalties, fines, costs, or expenses of whatever kind,
          including reasonable attorneys&apos; fees and the costs of enforcing
          any right to indemnification hereunder and the cost of pursuing any
          insurance providers.
        </p>
        <p>
          <strong>“Person”</strong> means an individual, corporation,
          partnership, joint venture, limited liability entity, governmental
          authority, unincorporated organization, trust, association, or other
          entity.
        </p>
        <p>
          <strong>“Personal Information”</strong> means information that
          Customer provides or for which Customer provides access to Provider,
          or information which Provider creates or obtains on behalf of
          Customer, in accordance with this Agreement that: (i) directly or
          indirectly identifies an individual (including, for example, names,
          signatures, addresses, telephone numbers, email addresses, and other
          unique identifiers); or (ii) can be used to authenticate an individual
          (including, without limitation, employee identification numbers,
          government-issued identification numbers, passwords or PINs, user
          identification and account access credentials or passwords, financial
          account numbers, credit report information, student information,
          biometric, genetic, health, or health insurance data, answers to
          security questions, and other personal identifiers), in case of both
          subclauses (i) and (ii), including Sensitive Personal Information as
          defined herein). Customer&apos;s business contact information is not
          by itself Personal Information.
        </p>
        <p>
          <strong>“Process”</strong> means to take any action or perform any
          operation or set of operations that the SaaS Services are capable of
          taking or performing on any data, information, or other content,
          including to collect, receive, input, upload, download, record,
          reproduce, store, organize, compile, combine, log, catalog,
          cross-reference, manage, maintain, copy, adapt, alter, translate, or
          make other derivative works or improvements, process, retrieve,
          output, consult, use, perform, display, disseminate, transmit, submit,
          post, transfer, disclose, or otherwise provide or make available, or
          block, erase, or destroy.
        </p>
        <p>
          <strong>“Provider”</strong> has the meaning set forth in the preamble.
        </p>
        <p>
          <strong>“Provider Disabling Device”</strong> means any software,
          hardware, or other technology, device, or means (including any back
          door, time bomb, time out, drop dead device, software routine, or
          other disabling device) used by Provider or its designee to disable
          Customer&apos;s or any Authorized User&apos;s access to or use of the
          Services automatically with the passage of time or under the positive
          control of Provider or its designee.
        </p>
        <p>
          <strong>“Provider Indemnitee”</strong> has the meaning set forth in
          1512.2.
        </p>
        <p>
          <strong>“Provider Materials”</strong> means the Services,
          Specifications, and Provider Systems and any and all other
          information, data, documents, materials, works, and other content,
          devices, methods, processes, hardware, software, and other
          technologies and inventions, including any deliverables, technical or
          functional descriptions, requirements, plans, or reports, that are
          provided or used by Provider or any Subcontractor in connection with
          the Services or otherwise comprise or relate to the Services or
          Provider Systems. For the avoidance of doubt, Provider Materials
          include Resultant Data and any information, data, or other content
          derived from Provider&apos;s monitoring of Customer&apos;s access to
          or use of the Services, but do not include Customer Data.
        </p>
        <p>
          <strong>“Provider Personnel”</strong> means all individuals involved
          in the performance of Services as employees, agents, or independent
          contractors of Provider or any Subcontractor.
        </p>
        <p>
          <strong>“Provider Systems”</strong> means the information technology
          infrastructure used by or on behalf of Provider in performing the
          Services, including all computers, software, hardware, databases,
          electronic systems (including database management systems), and
          networks, whether operated directly by Provider or through the use of
          third-party services.
        </p>
        <p>
          <strong>“Receiving Party”</strong> has the meaning set forth in 129.1.
        </p>
        <p>
          <strong>“Reimbursable Expenses”</strong> has the meaning set forth in
          118.7.
        </p>
        <p>
          <strong>“Renewal Term”</strong> has the meaning set forth in 1714.2.
        </p>
        <p>
          <strong>“Representatives”</strong> means, with respect to a party,
          that party&apos;s and its Affiliates&apos; employees, officers,
          directors, consultants, agents, independent contractors, service
          providers, sublicensees, subcontractors, and legal advisors.
        </p>
        <p>
          <strong>“Resultant Data”</strong> means data and information related
          to Customer&apos;s use of the Services that is used by Provider in an
          aggregate and anonymized manner, including to compile statistical and
          performance information related to the provision and operation of the
          Services.
        </p>
        <p>
          <strong>“Scheduled Downtime”</strong> has the meaning set forth in
          95.2.
        </p>
        <p>
          <strong>“Sensitive Personal Information”</strong> means an
          individual&apos;s (i) government-issued identification number,
          including Social Security number, driver&apos;s license number, or
          state-issued identification number; (ii) financial account number,
          credit report information, or credit, debit, or other payment
          cardholder information, with or without any required security or
          access code, personal identification number, or password that permits
          access to the individual&apos;s financial account; or (iii) background
          information such as employment, criminal, or other history.
        </p>
        <p>
          <strong>“Service Allocation”</strong> has the meaning set forth in
          Error: Reference source not foundError: Reference source not found.
        </p>
        <p>
          <strong>“Service Credit”</strong> has the meaning set forth in Error:
          Reference source not foundError: Reference source not found.
        </p>
        <p>
          <strong>“Service Level Failure”</strong> has the meaning set forth in
          95.1.
        </p>
        <p>
          <strong>“Service Period”</strong> has the meaning set forth in 95.1.
        </p>
        <p>
          <strong>“Services”</strong> means the software-as-a-service offering
          described in Exhibit A.
        </p>
        <p>
          <strong>“Subcontractor”</strong> has the meaning set forth in 62.6.
        </p>
        <p>
          <strong>“Support Exhibit”</strong> has the meaning set forth in 95.4.
        </p>
        <p>
          <strong>“Support Services”</strong> has the meaning set forth in 95.4.
        </p>
        <p>
          <strong>“Term”</strong> has the meaning set forth in 1714.1/1714.2.
        </p>
        <p>
          <strong>“Third-Party Materials”</strong> means materials and
          information, in any form or medium, including any open-source or other
          software, documents, data, content, specifications, products,
          equipment, or components of or relating to the Services that are not
          proprietary to Provider.
        </p>
      </section>
      <section className="saas__section">
        <h1>2. Services</h1>
        <p>
          <u>2.1 Access and Use.</u> Subject to and conditioned on
          Customer&apos;s and its Authorized Users&apos; compliance with the
          terms and conditions of this Agreement, Provider hereby grants
          Customer a non-exclusive, non-transferable (except in compliance with
          2015.8) right to access and use the Services during the Term, solely
          for use by Authorized Users in accordance with the terms and
          conditions herein. Such use is limited to Customer&apos;s internal
          use. Provider shall provide to Customer the Access Credentials within
          a reasonable time following the Effective Date. The total number of
          Authorized Users will not exceed the number set forth in{' '}
          <strong>Exhibit B</strong>, except as expressly agreed to in writing
          by the parties and subject to any appropriate adjustment of the Fees
          payable hereunder.
        </p>
        <p>
          <u>2.2 Service and System Control.</u> Except as otherwise expressly
          provided in this Agreement, as between the parties: (a) Provider has
          and will retain sole control over the operation, provision,
          maintenance, and management of the Provider Materials; and (b)
          Customer has and will retain sole control over the operation,
          maintenance, and management of, and all access to and use of, the
          Customer Systems and End User Data, and sole responsibility for all
          access to and use of the Provider Materials by any Person by or
          through the Customer Systems or any other means controlled by Customer
          or any Authorized User, including any: (i) information, instructions,
          or materials provided by any of them to the Services or Provider; (ii)
          results obtained from any use of the Services or Provider Materials;
          and (iii) conclusions, decisions, or actions based on such use.
        </p>
        <p>
          <u>2.3 Reservation of Rights.</u> Nothing in this Agreement grants any
          right, title, or interest in or to (including any license under) any
          Intellectual Property Rights in or relating to, the Services, Provider
          Materials, or Third-Party Materials, whether expressly, by
          implication, estoppel, or otherwise. All right, title, and interest in
          and to the Services, the Provider Materials, and the Third-Party
          Materials are and will remain with Provider and the respective rights
          holders in the Third-Party Materials.
        </p>
        <p>
          <u>2.4 Service Management.</u> Each party shall, throughout the Term,
          maintain within its organization a service manager to serve as such
          party&apos;s primary point of contact for day-to-day communications,
          consultation, and decision-making regarding this Agreement. Each
          service manager shall be responsible for providing all day-to-day
          consents and approvals on behalf of such party under this Agreement.
          Each party shall ensure its service manager has the requisite
          organizational authority, skill, experience, and other qualifications
          to perform in such capacity. The parties&apos; initial service
          managers are identified in <strong>Exhibit B</strong>. Each party
          shall use commercially reasonable efforts to maintain the same service
          manager in place throughout the Term. If either party&apos;s service
          manager ceases to be employed by such party or such party otherwise
          wishes to replace its service manager, such party shall promptly name
          a new service manager by written notice to the other party.
        </p>
        <p>
          <u>2.5 Changes.</u> Provider reserves the right, in its sole
          discretion, to make any changes to the Services and Provider Materials
          that it deems necessary or useful to: (a) maintain or enhance: (i) the
          quality or delivery of Provider&apos;s services to its customers; (ii)
          the competitive strength of or market for Provider&apos;s services; or
          (iii) the Services&apos; cost efficiency or performance; or (b) to
          comply with applicable Law. Without limiting the foregoing, either
          party may, at any time during the Term, request in writing changes to
          the Services. The parties shall evaluate and, if agreed, implement all
          such requested changes at the discretion of the Provider.
        </p>
        <p>
          <u>2.6 Subcontractors.</u> Provider may from time to time in its
          discretion engage third parties to perform Services (each, a{' '}
          <strong>“Subcontractor”</strong>).
        </p>
        <p>
          <u>2.7 Suspension or Termination of Services.</u> Provider may,
          directly or indirectly, and by use of a Provider Disabling Device or
          any other lawful means, suspend, terminate, or otherwise deny
          Customer&apos;s, any Authorized User&apos;s, or any other
          Person&apos;s access to or use of all or any part of the Services or
          Provider Materials, without incurring any resulting obligation or
          liability, if: (a) Provider receives a judicial or other governmental
          demand or order, subpoena, or law enforcement request that expressly
          or by reasonable implication requires Provider to do so; or (b)
          Provider believes, in its good faith and reasonable, that: (i)
          Customer or any Authorized User has failed to comply with any material
          term of this Agreement, or accessed or used the Services beyond the
          scope of the rights granted or for a purpose not authorized under this
          Agreement or in any manner that does not comply with any material
          instruction; (ii) Customer or any Authorized User is, has been, or is
          likely to be involved in any fraudulent, misleading, or unlawful
          activities relating to or in connection with any of the Services, or
          is misusing Customer Data or using the same for unlawful purposes;
          (iii) this Agreement expires or is terminated; (iv) Customer violates
          Provider&apos;s privacy policy and/or terms of use published to the
          public, found on its website, or violates Section 2.9 or 2.10 below.
          This Section 2.7 does not limit any of Provider&apos;s other rights or
          remedies, whether at law, in equity, or under this Agreement.
        </p>
        <p>
          <u>2.8 Limited License of Customer Trademarks.</u> For the purposes of
          providing Services, Customer grants to Provider a limited,
          non-exclusive, revocable license to use Customer&apos;s trademarks for
          the sole purpose of identifying Customer as the entity capturing
          Customer Data to the public. Such Customer trademarks shall be
          utilized in the Services upon Customer&apos;s prior approval of
          placement and style. Provider agrees that it shall not use the
          Customer&apos;s trademarks or any marks that are confusingly similar,
          assert any right, license, or interest with respect to any of
          Customer&apos;s trademarks, or represent or suggest an affiliation
          between Customer and Provider that exceeds the scope of this
          Agreement.
        </p>
        <p>
          <u>2.9 Customer Satisfaction Measurement Purposes.</u> Customer
          understands and acknowledges that Provider is providing CXMeter
          Services for obtaining customer satisfaction data for improving
          Customer&apos;s own business services. Customer acknowledges and
          agrees that it is the sole decision maker as related to the engagement
          of its End Users through the Services, and is solely responsible for
          all aspects of the relationship with the End User. The Customer shall
          at all times use the Services and the Website in accordance with the
          applicable law and legislation and in particular, all applicable data
          protection, employment and anti-discrimination legislation, including
          laws related to black box laws. The Customer is solely responsible for
          the use of the Services. The Customer is also responsible for
          confirming: <br /> (a) End User Data; Provider is not an agent,
          representative, staffing agency, employer, or otherwise have any type
          of employment or independent contractor relationship with the End User
          obtained through the Provider&apos;s platform, and Customer shall not
          represent its relationship with the Provider in violation of this
          Section2.9.
          <br />
          (b) Feedback. If Customer or any of its employees or contractors or
          End Users sends or transmits any communications or materials to
          Licensor by mail, email, telephone, or otherwise, suggesting or
          recommending changes to the Software or Documentation, including
          without limitation, new features or functionality relating thereto, or
          any End User Feedback including comments, questions, suggestions,
          Provider is free to use such Feedback irrespective of any other
          obligation or limitation between the Parties governing such Feedback.
        </p>
        <p>
          <u>2.10 Customer Use of Services.</u> The Customer shall at all times
          use the Services in accordance with this Agreement, Terms of Use, and
          Privacy Policy displayed on the Provider&apos;s website at
          https://CXMeter.io/. The Customer shall ensure that its use of the
          Services and/or the Website (as defined in Exhibit A), including the
          submission of any information, data, images, videos, audio, files,
          links to external websites, communication between Customer and End
          Users, and all other material of any format: (a) comply with all
          applicable laws and legislations (b) do not infringe any intellectual
          property rights or other proprietary rights of any third party; (c)
          are not reasonably deemed to be: be offensive, illegal, inappropriate
          or in any way; promote racism, bigotry, hatred or physical harm of any
          kind against any group or individual; harass or advocate harassment of
          another person; display pornographic or sexually explicit material;
          promote any conduct that is abusive, threatening, obscene, defamatory
          or libelous; promote any illegal activities; provide instructional
          information about illegal activities, including violating someone
          else&apos;s privacy; create computer viruses or implement any form of
          software or scripts onto the Website that have the appearance of
          coming from a user or candidate (for the avoidance of doubt, this
          shall not apply to API use); promote or contain information that you
          know or believe to be inaccurate, false or misleading; engage in the
          promotion of contests, sweepstakes and pyramid schemes, without our
          prior written consent; exploit people in a sexual or violent manner;
          invade or violate any third party&apos;s right to privacy; publish
          positions that do not correspond to actual unfulfilled full
          time/part-time/hourly employment/contract positions; publish positions
          for multi-level marketing positions,; and transmit “junk mail,” or
          “chain letters,” or unsolicited mass mailing, messaging or “spamming.”
          Provider reserves the right to refuse to publish any Customer
          submissions, or to at any time remove or edit a feedback, including
          End User Data (in whole or in part), if Provider has reason to believe
          that the Customer&apos;s use of the Services and/or the Website
          breaches this Agreement, Terms of Use, or Privacy Policy.
        </p>
      </section>
      <section className="saas__section">
        <h1>3. Use Restrictions; Service Usage and Data Storage</h1>
        <p>
          <u>3.1 Use Restrictions.</u> Customer shall not, and shall not permit
          any other Person to, access or use the Services or Provider Materials
          except as expressly permitted by this Agreement and, in the case of
          Third-Party Materials, the applicable third-party license agreement.
          For purposes of clarity and without limiting the generality of the
          foregoing, Customer shall not, except as this Agreement expressly
          permits:
          <br />
          (a) permit any other Person other than Authorized Users to access the
          Services;
          <br />
          (b) copy, modify, or create derivative works or improvements of the
          Services or Provider Materials;
          <br />
          (c) rent, lease, lend, sell, sublicense, assign, distribute, publish,
          transfer, or otherwise make available any Services or Provider
          Materials to any Person, including on or in connection with the
          internet or any time-sharing, service bureau, software as a service,
          cloud, or other technology or service;
          <br />
          (d) reverse engineer, disassemble, decompile, decode, adapt, or
          otherwise attempt to derive or gain access to the source code of the
          Services or Provider Materials, in whole or in part;
          <br />
          (e) bypass or breach any security device or protection used by the
          Services or Provider Materials or access or use the Services or
          Provider Materials other than by an Authorized User through the use of
          his or her own then valid Access Credentials;
          <br />
          (f) input, upload, transmit, or otherwise provide to or through the
          Services or Provider Systems, any information or materials that are
          unlawful or injurious, or contain, transmit, or activate any Harmful
          Code;
          <br />
          (g) damage, destroy, disrupt, disable, impair, interfere with, or
          otherwise impede or harm in any manner the Services, Provider Systems,
          or Provider&apos;s provision of services to any third party, in whole
          or in part;
          <br />
          (h) remove, delete, alter, or obscure any trademarks, warranties, or
          disclaimers, or any copyright, trademark, patent, or other
          intellectual property or proprietary rights notices from any Services
          or Provider Materials, including any copy thereof;
          <br />
          (i) access or use the Services or Provider Materials in any manner or
          for any purpose that infringes, misappropriates, or otherwise violates
          any Intellectual Property Right or other right of any third party
          (including by any unauthorized access to, misappropriation, use,
          alteration, destruction, disclosure of the data of any other Provider
          customer, unlawful disclosure of End User Data and other End User
          information), or that violates any applicable Law;
          <br />
          (j) access or use the Services or Provider Materials for purposes of
          competitive analysis of the Services or Provider Materials, the
          development, provision, or use of a competing software service or
          product or any other purpose that is to the Provider&apos;s detriment
          or commercial disadvantage; or
          <br />
          (k) otherwise access or use the Services or Provider Materials beyond
          the scope of the authorization granted under this Section 3.1.
        </p>
      </section>
      <section className="saas__section">
        <h1>4. Customer Obligations</h1>
        <p>
          <u>4.1 Customer Systems and Cooperation.</u> Customer shall at all
          times during the Term: (a) set up, maintain, and operate in good
          repair and in accordance with the specifications all Customer Systems
          on or through which the Services are accessed or used; (b) provide
          Provider Personnel with such access to Customer&apos;s premises and
          Customer Systems as is necessary for Provider to perform the Services
          in accordance with the Availability Requirement and specifications;
          and (c) provide all cooperation and assistance as Provider may
          reasonably request to enable Provider to exercise its rights and
          perform its obligations under and in connection with this Agreement.
        </p>
        <p>
          <u>4.2 Effect of Customer Failure or Delay.</u> Provider is not
          responsible or liable for any delay or failure of performance caused
          in whole or in part by Customer&apos;s delay in performing, or failure
          to perform, any of its obligations under this Agreement (each, a
          <strong>“Customer Failure”</strong>).
        </p>
        <p>
          <u>4.3 Corrective Action and Notice.</u> If Customer becomes aware of
          any actual or threatened activity prohibited by 73.1, Customer shall,
          and shall cause its Authorized Users to, immediately: (a) take all
          reasonable and lawful measures within their respective control that
          are necessary to stop the activity or threatened activity and to
          mitigate its effects (including, where applicable, by discontinuing
          and preventing any unauthorized access to the Services and Provider
          Materials and permanently erasing from their systems and destroying
          any data to which any of them have gained unauthorized access); and
          (b) notify Provider of any such actual or threatened activity.
        </p>
      </section>
      <section className="saas__section">
        <h1>5. Service and Support</h1>
        <p>
          <u>5.1 Service.</u> Subject to the terms and conditions of this
          Agreement, Provider will use commercially reasonable efforts to make
          the Services available, however, Provider realizes that there may be
          technical issues that may arise. CXMeter&apos;s technical staff is
          available for support and troubleshooting by email or phone, and
          Customer&apos;s Designated Technical Support Personnel shall contact
          CXMeter to begin the troubleshooting process. When CXMeter provides
          notification of scheduled outages, such Customer Designated Technical
          Support Personnel shall promptly relay such notification in a timely
          manner to the Customer.
        </p>
        <p>
          <u>5.2 Support Process.</u> The support process begins when
          Customer&apos;s Designated Technical Support Personnel notifies
          CXMeter&apos;s technical staff of an issue with which Customer
          requires assistance and opens a case. Customer will provide the
          following information in order to ensure the issue may be correctly
          and efficiently assessed and resolved: without limitation, the
          Customer name, case number and a detailed description of the issue.
          CXMeter shall assign a severity level to an issue upon initiation of
          the case based on the defined severity levels described below in this
          document. Target resolution times are set forth below. CXMeter shall
          determine, at its discretion, based on availability of staff and
          experience, whether to allocate Customer&apos;s case to a named
          individual. In order to ensure continuity of service and professional
          call handling, CXMeter shall use reasonable efforts not to reassign
          technical staff members once they have been assigned to resolving a
          particular problem.
        </p>
        <p>
          <u>5.3 Scheduled Downtime.</u> Provider will use commercially
          reasonable efforts to: (a) schedule downtime for routine maintenance
          of the Services by give Customer at least 72 hours prior notice of all
          scheduled outages of the Services (
          <strong>“Scheduled Downtime”</strong>).
        </p>
        <p>
          <u>5.4 Service Support.</u> The Services include Provider&apos;s
          standard customer support services (
          <strong>“Support Services”</strong>) in accordance with the Provider
          service support schedule, copy of which is attached as
          <strong> Exhibit C</strong> the <strong>“Support Exhibit”</strong>).
          Provider may amend the Support Exhibit from time to time in its sole
          discretion. Customer may purchase enhanced support services separately
          at Provider&apos;s then current rates.
        </p>
      </section>
      <section className="saas__section">
        <h1>6. Data Backup</h1>
        <p>
          The Services do not replace the need for Customer to maintain regular
          data backups or redundant data archives. PROVIDER HAS NO OBLIGATION OR
          LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION,
          OR RECOVERY OF CUSTOMER DATA AND/OR CUSTOMER DATA AND END USER DATA.
          The Provider Systems are programmed to perform routine data backups as
          set out in Provider&apos;s backup policy in effect from time to time
          (the <strong>“Backup Policy”</strong>), a copy of which is attached
          here as <strong>Exhibit C</strong>. Upon request, Provider will
          deliver to Customer its then most current back-ups of Customer Data
          available to Provider, but has no other obligation or liability
          related to back-up of Customer Data.
        </p>
      </section>
      <section className="saas__section">
        <h1>7. Security</h1>
        <p>
          <u>7.1 Information Security.</u> Provider will employ security
          measures in accordance with Provider&apos;s data privacy and security
          policy. CXMeter uses third party hosting providers that provide
          reasonable industry measures designed to protect the operating
          environment of the Services against unauthorized physical access and
          the threats of fire, power, temperature, humidity and other physical
          forces. In addition to such hosting providers&apos; protections and
          other security measures that CXMeter will implement from time to time
          per industry standards, CXMeter provides the following additional
          measures: <br />
          (a) Customer Data and End User Data are encrypted when transferred;{' '}
          <br />
          (b) Customer Data and End User Data is stored on S3. Daily backups of
          the production system will be retained for at least one week, and a
          weekly backup will be retained for at least one month. <br />
          (c) Services, Customer Data, and End User Data are protected with
          several layers of security, including routing, service access
          separation, user access control, and auditing. <br />
          (d) CXMeter maintains controls consistent with the ISO 27001
          framework. <br />
          (e) The Customer Data and End Usrt Data are maintained in secure
          directories that require access authentication. <br />
          (f) CXMeter maintains antivirus protection software. In the event
          viruses, worms or similar problems are determined to have infected the
          Service, CXMeter will use commercially reasonable efforts to restore
          the Service as quickly as reasonably possible.
        </p>
        <p>
          <u>7.2 Data Breach Procedures.</u> Provider maintains a data breach
          plan in accordance prevailing industry standards and shall implement
          the procedures required under such data breach plan on the occurrence
          of a data breach.
        </p>
        <p>
          <u>7.3 Customer Control and Responsibility.</u> Customer has and will
          retain sole responsibility for: (a) all Customer Data and End User
          Data, including its content and use; (b) all information,
          instructions, and materials provided by or on behalf of Customer or
          any Authorized User in connection with the Services; (c)
          Customer&apos;s information technology infrastructure, including
          computers, software, databases, electronic systems (including database
          management systems), and networks, whether operated directly by
          Customer or through the use of third-party services (“Customer
          Systems”); (d) the security and use of Customer&apos;s and its
          Authorized Users&apos; Access Credentials; and (e) all access to and
          use of the Services and Provider Materials directly or indirectly by
          or through the Customer Systems or its or its Authorized Users&apos;
          Access Credentials, with or without Customer&apos;s knowledge or
          consent, including all results obtained from, and all conclusions,
          decisions, and actions based on, such access or use.
        </p>
        <p>
          <u>7.4 Access and Security.</u> Customer shall employ all physical,
          administrative, and technical controls, screening, and security
          procedures and other safeguards necessary to: (a) securely administer
          the distribution and use of all Access Credentials and protect against
          any unauthorized access to or use of the Services; and (b) control the
          content and use of Customer Data and End User Data, including the
          uploading or other provision of Customer Data for Processing by the
          Services.
        </p>
      </section>
      <section className="saas__section">
        <h1>8. Fees and Payment</h1>
        <p>
          <u>8.1 Fees.</u> Customer shall pay Provider the fees set forth in
          <strong> Exhibit A</strong>(<strong>“Fees”</strong>) in accordance
          with this 118.
        </p>
        <p>
          <u>8.2 Taxes.</u> All Fees and other amounts payable by Customer under
          this Agreement are exclusive of taxes and similar assessments. Without
          limiting the foregoing, Customer is responsible for all sales, use,
          and excise taxes, and any other similar taxes, duties, and charges of
          any kind imposed by any federal, state, or local governmental or
          regulatory authority on any amounts payable by Customer hereunder,
          other than any taxes imposed on Provider&apos;s income.
        </p>
        <p>
          <u>8.3 Payment.</u> Customer shall pay all Fees and Reimbursable
          Expenses on or prior to the due date within 30 days after the date of
          the invoice therefor. Customer shall make all payments hereunder in US
          dollars. Customer shall make payments electronically or to the address
          or account specified in
          <strong> Exhibit A</strong> or such other address or account as
          Provider may specify in writing from time to time.
        </p>
        <p>
          <u>8.4 Late Payment.</u> If Customer fails to make any payment when
          due then, in addition to all other remedies that may be available:{' '}
          <br />
          (a) Provider may charge a late fee on the past due amount at the rate
          of 1.5% per month calculated daily and compounded monthly or, if
          lower, the highest rate permitted under applicable Law; <br />
          (b) Customer shall reimburse Provider for all costs incurred by
          Provider in collecting any late payments or interest, including
          attorneys&apos; fees, court costs, and collection agency fees; and{' '}
          <br />
          (c) if such failure continues for 30 days following written notice
          thereof, Provider may suspend performance of the Services until all
          past due amounts and late fees have been paid, without incurring any
          obligation or liability to Customer or any other Person by reason of
          such suspension. If such failure continues for 45 days following the
          first written notice, Provider may terminate this Agreement and access
          to the Service, Customer Data, and End User Data.
        </p>
        <p>
          <u>8.5 No Deductions or Setoffs.</u> All amounts payable to Provider
          under this Agreement shall be paid by Customer to Provider in full
          without any setoff, recoupment, counterclaim, deduction, debit, or
          withholding for any reason (other than any deduction or withholding of
          tax as may be required by applicable Law).
        </p>
        <p>
          <u>8.6 Fee Increases.</u> Provider may increase Fees no more than once
          annually after the first contract year of the Initial Term, including
          any contract year of any Renewal Term, by providing written notice to
          Customer at least 30 calendar days prior to the commencement of such
          Renewal Term, and
          <strong> Exhibit A</strong> will be deemed amended accordingly.
        </p>
        <p>
          <u>8.7 Reimbursable Expenses.</u> Customer shall reimburse Provider
          for out-of-pocket expenses incurred by Provider in connection with
          performing the Services, including any reasonable travel and living
          expenses incurred by Provider&apos;s personnel related to the Service
          (<strong>“Reimbursable Expenses”</strong>).
        </p>
      </section>
      <section className="saas__section">
        <h1>9. Confidentiality</h1>
        <p>
          <u>9.1 Confidential Information.</u> In connection with this Agreement
          each party (as the <strong>“Disclosing Party”</strong>) may disclose
          or make available Confidential Information to the other party (as the{' '}
          <strong>“Receiving Party”</strong>). Subject to 129.2,
          <strong>“Confidential Information”</strong> means information in any
          form or medium (whether oral, written, electronic, or other) that the
          Disclosing Party considers confidential or proprietary, including
          information consisting of or relating to the Disclosing Party&apos;s
          technology, trade secrets, know-how, business operations, plans,
          strategies, customers, and pricing, and information with respect to
          which the Disclosing Party has contractual or other confidentiality
          obligations, in each case whether or not marked, designated, or
          otherwise identified as “confidential”. Without limiting the
          foregoing: all Provider Materials are the Confidential Information of
          Provider and the financial terms and existence of this Agreement are
          the Confidential Information of each of the parties.
        </p>
        <p>
          <u>9.2 Exclusions.</u> Confidential Information does not include
          information that the Receiving Party can demonstrate by written or
          other documentary records: (a) was rightfully known to the Receiving
          Party without restriction on use or disclosure prior to such
          information&apos;s being disclosed or made available to the Receiving
          Party in connection with this Agreement; (b) was or becomes generally
          known by the public other than by the Receiving Party&apos;s or any of
          its Representatives&apos; noncompliance with this Agreement; (c) was
          or is received by the Receiving Party on a non-confidential basis from
          a third party that, to the Receiving Party&apos;s knowledge, was not
          or is not, at the time of such receipt, under any obligation to
          maintain its confidentiality; or (d) the Receiving Party can
          demonstrate by written or other documentary records was or is
          independently developed by the Receiving Party without reference to or
          use of any Confidential Information.
        </p>
        <p>
          <u>9.3 Protection of Confidential Information.</u> As a condition to
          being provided with any disclosure of or access to Confidential
          Information, the Receiving Party shall: <br />
          (a) not access or use Confidential Information other than as necessary
          to exercise its rights or perform its obligations under and in
          accordance with this Agreement; <br />
          (b) except as may be permitted by and subject to its compliance with
          139.4, not disclose or permit access to Confidential Information other
          than to its Representatives who: (i) need to know such Confidential
          Information for purposes of the Receiving Party&apos;s exercise of its
          rights or performance of its obligations under and in accordance with
          this Agreement; (ii) have been informed of the confidential nature of
          the Confidential Information and the Receiving Party&apos;s
          obligations under this 129.3; and (iii) are bound by confidentiality
          and restricted use obligations at least as protective of the
          Confidential Information as the terms set forth in this 119; <br />
          (c) safeguard the Confidential Information from unauthorized use,
          access, or disclosure using at least the degree of care it uses to
          protect its most sensitive information and in no event less than a
          reasonable degree of care; <br />
          (d) promptly notify the Disclosing Party of any unauthorized use or
          disclosure of Confidential Information and take all reasonable
          steps/use its best efforts/cooperate with Disclosing Party to prevent
          further unauthorized use or disclosure; and <br />
          (e) ensure its Representatives&apos; compliance with, and be
          responsible and liable for any of its Representatives&apos;
          non-compliance with, the terms of this 119. <br />
          (f) Notwithstanding any other provisions of this Agreement, the
          Receiving Party&apos;s obligations under this 119 with respect to any
          Confidential Information that constitutes a trade secret under any
          applicable Law will continue until such time, if ever, as such
          Confidential Information ceases to qualify for trade secret protection
          under one or more such applicable Laws other than as a result of any
          act or omission of the Receiving Party or any of its Representatives.
        </p>
        <p>
          <u>9.4 Compelled Disclosures.</u> If the Receiving Party or any of its
          Representatives is compelled by applicable Law to disclose any
          Confidential Information then, to the extent permitted by applicable
          Law, the Receiving Party shall: (a) promptly, and prior to such
          disclosure, notify the Disclosing Party in writing of such requirement
          so that the Disclosing Party can seek a protective order or other
          remedy or waive its rights under 129.3; and (b) provide reasonable
          assistance to the Disclosing Party, at the Disclosing Party&apos;s
          sole cost and expense, in opposing such disclosure or seeking a
          protective order or other limitations on disclosure. If the Disclosing
          Party waives compliance or, after providing the notice and assistance
          required under this 139.4, the Receiving Party remains required by Law
          to disclose any Confidential Information, the Receiving Party shall
          disclose only that portion of the Confidential Information that, on
          the advice of the Receiving Party&apos;s outside legal counsel, the
          Receiving Party is legally required to disclose and, on the Disclosing
          Party&apos;s request, shall use commercially reasonable efforts to
          obtain assurances from the applicable court or other presiding
          authority that such Confidential Information will be afforded
          confidential treatment.
        </p>
      </section>
      <section className="saas__section">
        <h1>10. Intellectual Property Rights</h1>
        <p>
          <u>10.1 Provider Materials.</u> All right, title, and interest in and
          to the Provider Materials, including all Intellectual Property Rights
          therein, are and will remain with Provider and, with respect to
          Third-Party Materials, the applicable third-party providers own all
          right, title, and interest, including all Intellectual Property
          Rights, in and to the Third-Party Materials. Customer has no right,
          license, or authorization with respect to any of the Provider
          Materials except as expressly set forth in 52.1 or the applicable
          third-party license, in each case subject to 73.1. All other rights in
          and to the Provider Materials are expressly reserved by Provider. In
          furtherance of the foregoing, Customer hereby unconditionally and
          irrevocably grants to Provider an assignment of all right, title, and
          interest in and to the Resultant Data, including all Intellectual
          Property Rights relating thereto.
        </p>
        <p>
          <u>10.2 Customer Data.</u> As between Customer and Provider, Customer
          is and will remain the sole and exclusive owner of all right, title,
          and interest in and to all Customer Data, including all Intellectual
          Property Rights relating thereto, subject to the rights and
          permissions granted in 1310.3.
        </p>
        <p>
          <u>10.3 Consent to Use Customer Data.</u> Customer hereby irrevocably
          grants all such rights and permissions in or relating to Customer Data
          and End User Data as are necessary or useful to Provider, its
          Subcontractors, and the Provider Personnel to enforce this Agreement
          and exercise Provider&apos;s, its Subcontractors&apos;, and the
          Provider Personnel&apos;s rights and perform Provider&apos;s, its
          Subcontractors&apos;, and the Provider Personnel&apos;s obligations
          hereunder.
        </p>
        <p>
          <u>10.4 Feedback.</u> Customer hereby irrevocably grants all such
          rights and permissions in or relating to Feedback as are necessary or
          useful to Provider, its Subcontractors, and the Provider Personnel to
          enforce this Agreement and exercise Provider&apos;s, its
          Subcontractors&apos;, and the Provider Personnel&apos;s rights and
          perform Provider&apos;s, its Subcontractors&apos;, and the Provider
          Personnel&apos;s obligations hereunder or to improve its Service.
        </p>
      </section>
      <section className="saas__section">
        <h1>11. Representations and Warranties</h1>
        <p>
          <u>11.1 Mutual Representations and Warranties.</u> Each party
          represents and warrants to the other party that: <br />
          (a) it is duly organized, validly existing, and in good standing as a
          corporation or other entity under the Laws of the jurisdiction of its
          incorporation or other organization; <br />
          (b) it has the full right, power, and authority to enter into and
          perform its obligations and grant the rights, licenses, consents, and
          authorizations it grants or is required to grant under this Agreement;{' '}
          <br />
          (c) the execution of this Agreement by its representative whose
          signature is set forth at the end of this Agreement has been duly
          authorized by all necessary corporate or organizational action of such
          party; and <br />
          (d) when executed and delivered by both parties, this Agreement will
          constitute the legal, valid, and binding obligation of such party,
          enforceable against such party in accordance with its terms.
        </p>
        <p>
          <u>
            11.2 Additional Provider Representations, Warranties, and Covenants.
          </u>
          Provider represents, warrants, and covenants to Customer that Provider
          will perform the Services using personnel of required skill,
          experience, and qualifications and in a professional and workmanlike
          manner in accordance with generally recognized industry standards for
          similar services and will devote adequate resources to meet its
          obligations under this Agreement.
        </p>
        <p>
          <u>
            11.3 Additional Customer Representations, Warranties, and Covenants.
          </u>
          Customer represents, warrants, and covenants to Provider that Customer
          owns or otherwise has and will have the necessary rights and consents
          in and relating to the Customer Data so that, as received by Provider
          and Processed in accordance with this Agreement, they do not and will
          not infringe, misappropriate, or otherwise violate any Intellectual
          Property Rights, or any privacy or other rights of any third party or
          violate any applicable Law. Provider does not vet, verify the
          accuracy, correctness and completeness of, edit or modify any
          submissions or any other information, data and materials created, used
          and/or published by the Customer on the Website to determine whether
          they may result in any liability to any third party. The Customer
          hereby warrants that the Customer has the right to use all such
          information and material.
        </p>
        <p>
          <u>11.4 DISCLAIMER OF WARRANTIES.</u> EXCEPT FOR THE EXPRESS
          WARRANTIES SET FORTH IN 1411.1 AND 1411.2, ALL SERVICES AND PROVIDER
          MATERIALS ARE PROVIDED “AS IS.” PROVIDER SPECIFICALLY DISCLAIMS ALL
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM
          COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE
          FOREGOING, PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR
          PROVIDER MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF,
          WILL MEET CUSTOMER&apos;S OR ANY OTHER PERSON&apos;S REQUIREMENTS,
          OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE
          COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE
          SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL
          THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR
          WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY
          BETWEEN CUSTOMER AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE
          THIRD-PARTY MATERIALS.
        </p>
        <p>
          <u>11.5 END USER DATA.</u> PROVIDER DOES NOT VET, VERIFY THE ACCURACY,
          CORRECTNESS AND COMPLETENESS OF ANY DATA SUBMITTED BY END USERS,
          INCLUDING END USER DATA, AND IT IS THE RESPONSIBILITY OF THE CUSTOMER
          TO VERIFY THE ACCURACY OF SUCH INFORMATION BEFORE MAKING ANY BUSINESS
          DECISIONS. PROVIDER DOES NOT HAVE ANY CONTROL OF AND THEREFORE CANNOT
          REASONABLY ACCEPT ANY LIABILITY IN RESPECT OF THE BEHAVIOUR, RESPONSE
          OR ACTIONS OF THE END USERS. PROVIDER DOES NOT WARRANT, REPRESENT OR
          GUARANTEE THAT THE CUSTOMER WILL BE ABLE TO IMPROVE ITS BUSINESS USING
          THE SERVICES.
        </p>
      </section>
      <section className="saas__section">
        <h1>12. Indemnification</h1>
        <p>
          <u>12.1 Provider Indemnification.</u> Provider shall indemnify,
          defend, and hold harmless Customer and Customer&apos;s officers,
          directors, employees, agents, permitted successors, and assigns (each,
          a<strong>“Customer Indemnitee”</strong>) from and against any and all
          Losses incurred by Customer/Customer Indemnitee resulting from any
          Action by a third party (other than an Affiliate of a Customer
          Indemnitee) that Customer&apos;s or an Authorized User&apos;s use of
          the Services (excluding Customer Data and Third-Party Materials) in
          accordance with this Agreement infringes or misappropriates such third
          party&apos;s US Intellectual Property Rights/US patents, copyrights,
          or trade secrets, and will settle any such action or will pay any
          final judgment awarded against Customer, provided that: (i) CXMeter
          shall be notified promptly in writing by Customer of any notice of any
          such claim; (ii) CXMeter shall have the sole control of the defense of
          any action on such claim and all negotiations for its settlement or
          compromise and shall bear the costs of the same (save where one or
          more of the exclusions in subsection (ii) of this Section applies);
          and (iii) Customer shall cooperate fully at CXMeter&apos;s expense
          with CXMeter in the defense, settlement or compromise of such claim.
          If a claim described in this Section 12.1 hereof occurs or, in
          CXMeter&apos;s opinion, may occur, Customer shall permit CXMeter, at
          CXMeter&apos;s option and expense to: (i) procure for Customer the
          right to continue using the Service; (ii) modify the use of the
          Service so that it becomes non-infringing without materially impairing
          its functionality; or (iii) grant Customer a refund of any fees paid
          to CXMeter for such Services. This Section states CXMeter&apos;s sole
          and exclusive liability, and Customer&apos;s sole remedy, for any and
          all claims relating to infringement of any intellectual property
          right. The foregoing obligation does not apply to the extent that the
          alleged infringement arises from: <br />
          (a) Third-Party Materials, End User Data, or Customer Data; <br />
          (b) access to or use of the Provider Materials in combination with any
          hardware, system, software, network, or other materials or service not
          provided by Provider, unless otherwise expressly permitted by Provider
          in writing; <br />
          (c) modification of the Provider Materials other than: (i) by or on
          behalf of Provider; or (ii) with Provider&apos;s written approval in
          accordance with Provider&apos;s written specification; <br />
          (d) failure to timely implement any modifications, upgrades,
          replacements, or enhancements made available to Customer by or on
          behalf of Provider; or <br />
          (e) act, omission, or other matter described in 16(a), 16(b), 16(d),
          or 16(e), whether or not the same results in any Action against or
          Losses by any Provider Indemnitee.
        </p>
        <p>
          <u>12.2 Customer Indemnification.</u> Customer shall indemnify,
          defend, and hold harmless Provider and its Subcontractors and
          Affiliates, and each of its and their respective officers, directors,
          employees, agents, successors, and assigns (each, a{' '}
          <strong>“Provider Indemnitee”</strong>) from and against any and all
          Losses incurred by such Provider Indemnitee resulting from any Action
          by a third party (other than an Affiliate of a Provider Indemnitee)
          that arise out of or result from, or are alleged to arise out of or
          result from:
          <br />
          (a) Customer Data, including any Processing of Customer Data by or on
          behalf of Provider in accordance with this Agreement; <br />
          (b) Use of End User Data for any purpose, including but not limited to
          violations of privacy laws, local and federal employment laws, or the
          Fair Credit Reporting Act, or other such actions described in Section
          2.10; <br />
          (c) any other materials or information (including any documents, data,
          specifications, software, content, or technology) provided by or on
          behalf of Customer or any Authorized User, including Provider&apos;s
          compliance with any specifications or directions provided by or on
          behalf of Customer or any Authorized User to the extent prepared
          without any contribution by Provider; <br />
          (d) allegation of facts that, if true, would constitute
          Customer&apos;s breach of any of its representations, warranties,
          covenants, or obligations under this Agreement; or <br />
          (e) negligence/gross negligence or more culpable act or omission
          (including recklessness or willful misconduct) by Customer, any
          Authorized User, or any third party on behalf of Customer or any
          Authorized User, in connection with this Agreement and/or use of the
          Services.
        </p>
        <p>
          <u>12.3 Indemnification Procedure.</u> Each party shall promptly
          notify the other party in writing of any Action for which such party
          believes it is entitled to be indemnified pursuant to 1512.1 or
          1512.2, as the case may be. The party seeking indemnification (the{' '}
          <strong>“Indemnitee”</strong>) shall cooperate with the other party
          (the <strong>“Indemnitor”</strong>) at the Indemnitor&apos;s sole cost
          and expense. The Indemnitor shall promptly assume control of the
          defense and shall employ counsel reasonably acceptable to the
          Indemnitee to handle and defend the same, at the Indemnitor&apos;s
          sole cost and expense. The Indemnitee may participate in and observe
          the proceedings at its own cost and expense with counsel of its own
          choosing. The Indemnitor shall not settle any Action without the
          Indemnitee&apos;s prior written consent, which shall not be
          unreasonably withheld or delayed. If the Indemnitor fails or refuses
          to assume control of the defense of such Action, the Indemnitee shall
          have the right, but no obligation, to defend against such Action,
          including settling such Action after giving notice to the Indemnitor,
          in each case in such manner and on such terms as the Indemnitee may
          deem appropriate. The Indemnitee&apos;s failure to perform any
          obligations under this Section 12.3 will not relieve the Indemnitor of
          its obligations under this 1512, except to the extent that the
          Indemnitor can demonstrate that it has been prejudiced as a result of
          such failure.
        </p>
        <p>
          <u>12.4 Mitigation.</u> If any of the Services or Provider Materials
          are, or in Provider&apos;s opinion are likely to be, claimed to
          infringe, misappropriate, or otherwise violate any third-party
          Intellectual Property Right, or if Customer&apos;s or any Authorized
          User&apos;s use of the Services or Provider Materials is enjoined or
          threatened to be enjoined, Provider may, at its option and sole cost
          and expense: <br />
          (a) modify or replace the Services and Provider Materials, in whole or
          in part, to seek to make the Services and Provider Materials (as so
          modified or replaced) non-infringing, while providing materially
          equivalent features and functionality, in which case such
          modifications or replacements will constitute Services and Provider
          Materials, as applicable, under this Agreement; or <br />
          (b) by written notice to Customer, terminate this Agreement with
          respect to all or part of the Services and Provider Materials, and
          require Customer to immediately cease any use of the Services and
          Provider Materials or any specified part or feature thereof.
        </p>
        <p>
          <u>12.5 Sole Remedy.</u> THIS SECTION 12 SETS FORTH CUSTOMER&apos;S
          SOLE REMEDIES AND PROVIDER&apos;S SOLE LIABILITY AND OBLIGATION FOR
          ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES AND
          PROVIDER MATERIALS OR ANY SUBJECT MATTER OF THIS AGREEMENT INFRINGES,
          MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY
          RIGHTS OF ANY THIRD PARTY.
        </p>
      </section>
      <section className="saas__section">
        <h1>13. Limitations of Liability.</h1>
        <p>
          <u>13.1 EXCLUSION OF DAMAGES.</u> IN NO EVENT WILL PROVIDER OR ANY OF
          ITS LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN
          CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL
          OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS OF
          PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE;
          (b) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION, OR DELAY OF
          THE SERVICES; (c) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF DATA, OR
          BREACH OF DATA OR SYSTEM SECURITY; (d) COST OF REPLACEMENT GOODS OR
          SERVICES; (e) LOSS OF GOODWILL OR REPUTATION; OR (f) CONSEQUENTIAL,
          INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
          DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE
          POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
          OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED
          OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          <u>13.2 CAP ON MONETARY LIABILITY.</u> IN NO EVENT WILL THE COLLECTIVE
          AGGREGATE LIABILITY OF PROVIDER AND ITS LICENSORS, SERVICE PROVIDERS,
          AND SUPPLIERS ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER
          ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY,
          EXCEED THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT IN THE
          ONE YEAR PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE
          FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
          PURPOSE.
        </p>
      </section>
      <section className="saas__section">
        <h1>14. Term and Termination</h1>
        <p>
          <u>14.1 Initial Term.</u> The initial term of this Agreement commences
          as of the Effective Date and, unless terminated earlier pursuant any
          of the Agreement&apos;s express provisions, will continue in effect
          until the termination date specified in <strong>Exhibit A</strong>{' '}
          (the
          <strong>“Initial Term”</strong>). If no termination date is specified,
          this Agreement shall continue until terminated by either party
          pursuant to this Section 14.
        </p>
        <p>
          <u>14.2 Renewal Term.</u> This Agreement will automatically renew
          unless earlier terminated pursuant to this Agreement&apos;s express
          provisions or either party gives the other party written notice of
          non-renewal at least 30 days prior to the expiration of the
          then-current term (each a<strong>“Renewal Term”</strong> and,
          collectively, together with the Initial Term, the{' '}
          <strong>“Term”</strong>).
        </p>
        <p>
          <u>14.3 Termination.</u> In addition to any other express termination
          right set forth elsewhere in this Agreement: (a) Provider may
          terminate this Agreement, effective on written notice to Customer, if
          Customer: (i) fails to pay any amount when due hereunder, and such
          failure continues more than 45 days after Provider&apos;s delivery of
          written notice thereof; or (ii) breaches any of its obligations under
          Section 2.9, Section 2.10, 73.1 , 107.3, 119, or as otherwise
          specified in this Agreement; (b) either party may terminate this
          Agreement, effective on written notice to the other party, if the
          other party materially breaches this Agreement, and such breach: (i)
          is incapable of cure; or (ii) being capable of cure, remains uncured
          30 days after the non-breaching party provides the breaching party
          with written notice of such breach; and (c) either party may terminate
          this Agreement, effective immediately upon written notice to the other
          party, if the other party: (i) becomes insolvent or is generally
          unable to pay, or fails to pay, its debts as they become due; (ii)
          files, or has filed against it, a petition for voluntary or
          involuntary bankruptcy or otherwise becomes subject, voluntarily or
          involuntarily, to any proceeding under any domestic or foreign
          bankruptcy or insolvency Law; (iii) makes or seeks to make a general
          assignment for the benefit of its creditors; or (iv) applies for or
          has appointed a receiver, trustee, custodian, or similar agent
          appointed by order of any court of competent jurisdiction to take
          charge of or sell any material portion of its property or business.
        </p>
        <p>
          <u>14.4 Effect of Termination or Expiration.</u> Upon any expiration
          or termination of this Agreement, except as expressly otherwise
          provided in this Agreement: (a) all rights, licenses, consents, and
          authorizations granted by either party to the other hereunder will
          immediately terminate; (b) Provider shall immediately cease all use of
          any Customer Data or Customer&apos;s Confidential Information and (i)
          promptly return to Customer, or at Customer&apos;s written request
          destroy, all documents and tangible materials containing, reflecting,
          incorporating, or based on Customer Data or Customer&apos;s
          Confidential Information; and (ii) permanently erase all Customer Data
          and Customer&apos;s Confidential Information from all systems Provider
          directly or indirectly controls, provided that, for clarity,
          Provider&apos;s obligations under this Section 14.4(b) do not apply to
          any Resultant Data; (c) Customer shall immediately cease all use of
          any Services or Provider Materials and (i) promptly return to
          Provider, or at Provider&apos;s written request destroy, all documents
          and tangible materials containing, reflecting, incorporating, or based
          on any Provider Materials or Provider&apos;s Confidential Information;
          (ii) permanently erase all Provider Materials and Provider&apos;s
          Confidential Information from all systems Customer directly or
          indirectly controls; and (iii) certify to Provider in a signed written
          instrument that it has complied with the requirements of this Section
          14.4(c); (d) Provider may disable all Customer and Authorized User
          access to the Provider Materials; (e) if Provider terminates this
          Agreement pursuant to 17(a) or 18(b), all Fees that would have become
          payable had the Agreement remained in effect until expiration of the
          Term will become immediately due and payable, and Customer shall pay
          such Fees, together with all previously-accrued but not yet paid Fees
          and Reimbursable Expenses, on receipt of Provider&apos;s invoice
          therefor.
        </p>
        <p>
          <u>14.5 Surviving Terms.</u> The provisions set forth in the following
          sections, and any other right or obligation of the parties in this
          Agreement that, by its nature, should survive termination or
          expiration of this Agreement, will survive any expiration or
          termination of this Agreement: 73.1, 119, 1411.4, 1512, 1713, 1814.4,
          this 1814.5, and 1915.
        </p>
      </section>
      <section className="saas__section">
        <h1>15. Miscellaneous</h1>
        <p>
          <u>15.1 Further Assurances.</u> On a party&apos;s reasonable request,
          the other party shall, at the requesting party&apos;s sole cost and
          expense, execute and deliver all such documents and instruments, and
          take all such further actions, as may be necessary to give full effect
          to this Agreement.
        </p>
        <p>
          <u>15.2 Relationship of the Parties.</u> The relationship between the
          parties is that of independent contractors. Nothing contained in this
          Agreement shall be construed as creating any agency, partnership,
          joint venture, or other form of joint enterprise, employment, or
          fiduciary relationship between the parties, and neither party shall
          have authority to contract for or bind the other party in any manner
          whatsoever.
        </p>
        <p>
          <u>15.3 Public Announcements.</u> Neither party shall issue or release
          any announcement, statement, press release, or other publicity or
          marketing materials relating to this Agreement or, unless expressly
          permitted under this Agreement, otherwise use the other party&apos;s
          trademarks, service marks, trade names, logos, domain names, or other
          indicia of source, association, or sponsorship, in each case, without
          the prior written consent of the other party, which consent shall not
          be unreasonably withheld, provided, however, that Provider may,
          without Customer&apos;s consent, include Customer&apos;s name and
          other indicia in its lists of Provider&apos;s current or former
          customers of Provider in promotional and marketing materials.
        </p>
        <p>
          <u>
            15.4 Notices. Except as otherwise expressly set forth in this
            Agreement, any notice, request, consent, claim, demand, waiver, or
            other communications under this Agreement have legal effect only if
            in writing and addressed to a party as follows (or to such other
            address or such other person that such party may designate from time
            to time in accordance with this Section 15.4):
          </u>
        </p>
        <p>
          If to Provider: 2035 Central Cir, #201, McKinney, TX 75069 <br />
          Email: support@cxmeter.io <br />
          Attention: Legal Officer
        </p>
        <p>
          If to Customer: {displayLocation} <br />
          Email: {user.email} <br />
          Attention: {user.first_name} {user.last_name}
        </p>
        <p>
          Notices sent in accordance with this Section 15.4 will be deemed
          effectively given: (a) when received, if delivered by hand, with
          signed confirmation of receipt; (b) when received, if sent by a
          nationally recognized overnight courier, signature required; (c) when
          sent, if by facsimile or email, in each case, with confirmation of
          transmission), if sent during the addressee&apos;s normal business
          hours, and on the next business day, if sent after the
          addressee&apos;s normal business hours; and (d) on the third after the
          date mailed by certified or registered mail, return receipt requested,
          postage prepaid.
        </p>
        <p>
          <u>15.5 Interpretation.</u> For purposes of this Agreement: (a) the
          words “include,” “includes,” and “including” are deemed to be followed
          by the words “without limitation”; (b) the word “or” is not exclusive;
          (c) the words “herein,” “hereof,” “hereby,” “hereto,” and “hereunder”
          refer to this Agreement as a whole; (d) words denoting the singular
          have a comparable meaning when used in the plural, and vice-versa; and
          (e) words denoting any gender include all genders. Unless the context
          otherwise requires, references in this Agreement: (x) to sections,
          exhibits, schedules, attachments, and appendices mean the sections of,
          and exhibits, schedules, attachments, and appendices attached to, this
          Agreement; (y) to an agreement, instrument, or other document means
          such agreement, instrument, or other document as amended,
          supplemented, and modified from time to time to the extent permitted
          by the provisions thereof; and (z) to a statute means such statute as
          amended from time to time and includes any successor legislation
          thereto and any regulations promulgated thereunder. The parties intend
          this Agreement to be construed without regard to any presumption or
          rule requiring construction or interpretation against the party
          drafting an instrument or causing any instrument to be drafted. The
          exhibits, schedules, attachments, and appendices referred to herein
          are an integral part of this Agreement to the same extent as if they
          were set forth verbatim herein.
        </p>
        <p>
          <u>15.6 Headings.</u> The headings in this Agreement are for reference
          only and do not affect the interpretation of this Agreement.
        </p>
        <p>
          <u>15.7 Entire Agreement.</u> This Agreement, together with the
          Provider&apos;s Terms of Use and Privacy Policy, any other documents
          incorporated herein by reference, constitutes the sole and entire
          agreement of the parties with respect to the subject matter of this
          Agreement and supersedes all prior and contemporaneous understandings,
          agreements, representations, and warranties, both written and oral,
          with respect to such subject matter. In the event of any inconsistency
          between the statements made in the body of this Agreement, the related
          exhibits, schedules, attachments, and appendices (other than an
          exception expressly set forth as such therein) and any other documents
          incorporated herein by reference, the following order of precedence
          governs: (a) first, this Agreement, excluding its exhibits, schedules,
          attachments, and appendices; (b) second, the exhibits, schedules,
          attachments, and appendices to this Agreement as of the Effective
          Date; and (c) third, any other documents incorporated herein by
          reference, including the Provider&apos;s Terms of Use and Privacy
          Policy.
        </p>
        <p>
          <u>15.8 Assignment.</u> Customer shall not assign or otherwise
          transfer any of its rights, or delegate or otherwise transfer any of
          its obligations or performance under this Agreement, in each case
          whether voluntarily, involuntarily, by operation of law, or otherwise,
          without Provider&apos;s prior written consent. For purposes of the
          preceding sentence, and without limiting its generality, any merger,
          consolidation, or reorganization involving Customer (regardless of
          whether Customer is a surviving or disappearing entity) will be deemed
          to be a transfer of rights, obligations, or performance under this
          Agreement for which Provider&apos;s prior written consent is required.
          No assignment, delegation, or transfer will relieve Customer of any of
          its obligations or performance under this Agreement. Any purported
          assignment, delegation, or transfer in violation of this Section 15.8
          is void. This Agreement is binding upon and inures to the benefit of
          the parties hereto and their respective successors and permitted
          assigns.
        </p>
        <p>
          <u>15.9 Force Majeure.</u> In no event will Provider be liable or
          responsible to Customer or Applicant, or be deemed to have defaulted
          under or breached this Agreement, for any failure or delay in
          fulfilling or performing any term of this Agreement, when and to the
          extent such failure or delay is caused by any circumstances beyond
          Provider reasonable control (a
          <strong>“Force Majeure Event”</strong>), including acts of God, flood,
          fire, earthquake or explosion, war, terrorism, invasion, riot or other
          civil unrest, epidemics and pandemics, embargoes or blockades in
          effect on or after the date of this Agreement, national or regional
          emergency, strikes, labor stoppages or slowdowns or other industrial
          disturbances, passage of Law or any action taken by a governmental or
          public authority, including imposing an embargo, export or import
          restriction, quota, or other restriction or prohibition or any
          complete or partial government shutdown, or national or regional
          shortage of adequate power or telecommunications or transportation.
        </p>
        <p>
          <u>15.10 No Third-Party Beneficiaries.</u> This Agreement is for the
          sole benefit of the parties hereto and their respective successors and
          permitted assigns and nothing herein, express or implied, is intended
          to or shall confer upon any other Person any legal or equitable right,
          benefit, or remedy of any nature whatsoever under or by reason of this
          Agreement.
        </p>
        <p>
          <u>15.11 Amendment and Modification; Waiver.</u> No amendment to or
          modification of or rescission, termination, or discharge of this
          Agreement is effective unless it is in writing, identified as an
          amendment to or rescission, termination, or discharge of this
          Agreement and signed by an authorized representative of each party. No
          waiver by any party of any of the provisions hereof shall be effective
          unless explicitly set forth in writing and signed by the party so
          waiving. Except as otherwise set forth in this Agreement, no failure
          to exercise, or delay in exercising, any rights, remedy, power, or
          privilege arising from this Agreement will operate or be construed as
          a waiver thereof; nor shall any single or partial exercise of any
          right, remedy, power, or privilege hereunder preclude any other or
          further exercise thereof or the exercise of any other right, remedy,
          power, or privilege.
        </p>
        <p>
          <u>15.12 Severability.</u> If any term or provision of this Agreement
          is invalid, illegal, or unenforceable in any jurisdiction, such
          invalidity, illegality, or unenforceability shall not affect any other
          term or provision of this Agreement or invalidate or render
          unenforceable such term or provision in any other jurisdiction. Upon
          such determination that any term or other provision is invalid,
          illegal, or unenforceable, the parties hereto shall negotiate in good
          faith to modify this Agreement so as to effect the original intent of
          the parties as closely as possible in a mutually acceptable manner in
          order that the transactions contemplated hereby be consummated as
          originally contemplated to the greatest extent possible.
        </p>
        <p>
          <u>15.13 Governing Law; Submission to Jurisdiction.</u> This Agreement
          is governed by and construed in accordance with the internal laws of
          the State of Texas without giving effect to any choice or conflict of
          law provision or rule that would require or permit the application of
          the laws of any jurisdiction other than those of the State of Texas.
          Any legal suit, action, or proceeding arising out of or related to
          this Agreement or the licenses granted hereunder will be instituted
          exclusively in the federal or state courts of the United States or the
          courts of the State of Texas in each case located in the city of
          Dallas and/or County of Collin, and each party irrevocably submits to
          the exclusive jurisdiction of such courts in any such suit, action, or
          proceeding. Service of process, summons, notice, or other document by
          mail to such party&apos;s address set forth herein shall be effective
          service of process for any suit, action, or other proceeding brought
          in any such court.
        </p>
        <p>
          <u>15.14 Waiver of Jury Trial.</u> Each party irrevocably and
          unconditionally waives any right it may have to a trial by jury in
          respect of any legal action arising out of or relating to this
          Agreement or the transactions contemplated hereby.
        </p>
        <p>
          <u>15.15 Equitable Relief.</u> Customer acknowledges and agrees that a
          breach or threatened breach by Customer of any of its obligations
          under 119 or, in the case of Customer, 73.1, 94.3, or 107.3, would
          cause Provider irreparable harm for which monetary damages would not
          be an adequate remedy and that, in the event of such breach or
          threatened breach, Provider will be entitled to equitable relief,
          including a restraining order, an injunction, specific performance,
          and any other relief that may be available from any court, without any
          requirement to post a bond or other security, or to prove actual
          damages or that monetary damages are not an adequate remedy. Such
          remedies are not exclusive and are in addition to all other remedies
          that may be available at law, in equity, or otherwise.
        </p>
        <p>
          <u>15.16 Attorneys&apos; Fees.</u> In the event that any action, suit,
          or other legal or administrative proceeding is instituted or commenced
          by either party against the other party arising out of or related to
          this Agreement, the prevailing party is entitled to recover its
          attorneys&apos; fees and court costs from the non-prevailing party.
        </p>
        <p>
          <u>15.17 Counterparts.</u> This Agreement may be executed in
          counterparts, each of which is deemed an original, but all of which
          together are deemed to be one and the same agreement. A signed copy of
          this Agreement delivered by facsimile, email, or other means of
          electronic transmission is deemed to have the same legal effect as
          delivery of an original signed copy of this Agreement.
        </p>
      </section>
      <section className="saas__section">
        <br />
        <br />
        <p>
          IN WITNESS WHEREOF, the parties hereto have executed this Agreement as
          of the date first above written.
        </p>
        <div className="saas__signature">
          <div>
            <strong>CUSTOMER:</strong>
            <br />
            <strong>
              {user.first_name} {user.last_name}
            </strong>
            <p>
              By: {user.first_name} {user.last_name}
            </p>
            {/* <p>Title: [Title]</p> */}
            <p>Email: {user.email}</p>
          </div>
          <div>
            <strong>PROVIDER:</strong>
            <br />
            <strong>CXMeter Inc, A Texas Corporation</strong>
            <p>By: CXMeter Inc, A Texas Corporation</p>
            {/* <p>Title: [Title]</p> */}
            <p>Email: support@cxmeter.io</p>
          </div>
        </div>
      </section>
      <section className="saas__section">
        <div className="saas__section--exhibit">
          <h1>EXHIBIT A</h1>
          <h2>SERVICES AND FEES</h2>
        </div>
        <p>
          <br />
          (1) <strong>SERVICE:</strong> Services offered include access to a
          platform that collects and process Applicant data, information, and
          documents for Customers who may utilize such information for
          employment purposes.
          <br />
          (2) The website for the Services is{' '}
          <a href="https://cxmeter.io">https://cxmeter.io</a> (
          <strong>“Website”</strong>).
          <br />
          (3) <strong>FEE SCHEDULE:</strong> Provider will charge an advance
          monthly fee according to the package selected by Customer and its
          pricing. The pricing packages are described at{' '}
          <a href="https://www.cxmeter.io/pricing">
            https://www.cxmeter.io/pricing.
          </a>{' '}
          <br />
          (4) <strong>PAYMENT:</strong> Payment shall be submitted
          electronically using credit card. Customer authorizes the Provider to
          charge the Customer credit card saved on file on the 1st of every
          month. <br />
          (5) <strong>TERMINATION:</strong> This Agreement shall expire on the
          1st anniversary of the Effective Date, or {displayDate}.
        </p>
      </section>
      <section className="saas__section">
        <div className="saas__section--exhibit">
          <h1>EXHIBIT B</h1>
          <h2>AUTHORIZED USERS</h2>
        </div>
        <p>
          Below is the list of Customer&apos;s Authorized Users:
          <br />
          1. Customer&apos;s Designated Technical Support Personnel: [ENTER
          NAMES, EMAIL ADDRESSES, ETC.]
          <br />
          2. [ENTER NAMES, EMAIL ADDRESSES, ETC. of HR personnel and other
          internal members who have access to CXMeter]
        </p>
      </section>
      <section className="saas__section">
        <div className="saas__section--exhibit">
          <h1>EXHIBIT C</h1>
          <h2>SUPPORT</h2>
        </div>
        <p>
          <u>Severity, Initial Response Times and Resolution Targets:</u> Case
          severity levels and associated initial response and estimated
          resolution times are as set forth below:
        </p>
        <table className="saas__table">
          <thead>
            <tr>
              <th>Severity Level</th>
              <th>Definition</th>
              <th>Initial Response</th>
              <th>Target Resolution Time*</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Severity 1</th>
              <td>
                A problem that causes substantial downtime of the Service with
                no viable workaround available. Generally, requires 24X7
                availability of Customer&apos;s Designated Technical Support
                Personnel. CXMeter will work 24X7 until the issue is resolved or
                the Severity is lowered.{' '}
              </td>
              <td>1 Hour</td>
              <td>4 Hours</td>
            </tr>
            <tr>
              <th>Severity 2</th>
              <td>
                A problem in the Hosted System that results in the loss of
                critical functions of the Hosted Software or a limited number of
                Users cannot access the Services.
              </td>
              <td>3 Hours</td>
              <td>12 Hours</td>
            </tr>
            <tr>
              <th>Severity 3</th>
              <td>
                A problem that impacts Services operations and/or efficiency but
                Customer is still able to use the Service. An available
                workaround shall be developed and implemented.
              </td>
              <td>1 Business Day</td>
              <td>2 Business Days</td>
            </tr>
            <tr>
              <th>Severity 4</th>
              <td>
                A technical question about the Services that does not impact
                Customer&apos;s use of the Hosted Software.
              </td>
              <td>2 Business Days</td>
              <td>5 Business Days</td>
            </tr>
          </tbody>
        </table>
        <strong>Note:</strong>
        <ul>
          <li>
            - Severity 1 and Severity 2 problems can be communicated to CXMeter
            by emailing at{' '}
            <a href="mailto:support+critical@CXMeter.io">
              support+critical@CXMeter.io
            </a>
            .
          </li>
          <li>
            - Severity 3 and Severity 4 problems can be communicated to CXMeter
            by emailing the designated technical support personnel.
          </li>
        </ul>
        <p>
          * Target resolution times are measured from CXMeter&apos;s initial
          response and reflect the target resolution times for hosting problems
          only, based on CXMeter using diligent efforts to return Customer to
          production status.
        </p>
        <strong>(1) Uptime Percent Calculation</strong>
        <p>
          SLA&apos;s are measured by attempting to access the Service from
          locations throughout the world. The Service access confirms front end
          (user interface) and back end (database) availability. If the Service
          is accessible, it is considered up.
        </p>
        <p>
          If the Service is not accessible, it is considered down. A
          Customer&apos;s Service Uptime Percentage is calculated quarterly
          using the formula:{' '}
        </p>
        <table className="saas__table">
          <tbody>
            <tr>
              <th>Uptime Percentage</th>
              <td>
                (Minutes in the Quarter - Minutes of Excused Downtime - Minutes
                of Unexcused Downtime) / (Minutes in the Quarter - Minutes of
                Excused Downtime)
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Excused Downtime is a non-availability of the Service due to any of
          the causes listed below:
          <br />• Force majeure events.
          <br />• Data transmission failures outside the control of CXMeter not
          caused by CXMeter&apos;s negligence or willful misconduct.
          <br />• Downtime resulting from applications developed for or by
          Customer that are running on or interacting with the Hosted System.
          <br />• Downtime resulting from third party software utilized by the
          Customer that is not Hosted System and/or third-party software
          integrations developed by or for Customer.
          <br />• Downtime due to failure of the internet or Customer&apos;s
          network.
          <br />• Maintenance outages (including emergency maintenance outages),
          for which CXMeter will endeavor to give Customer as much notice as is
          reasonably practicable under the circumstances: ◦ Downtime due to
          Hosting Provider failure. ◦ Downtime due to Customer&apos;s actions or
          OS/Application changes of the Hosted System not due to bugs in
          Software. ◦ Downtime while Hosted Data is being restored.
        </p>
        <strong>(2) Backups</strong>
        <p>
          The table below lists the backups for all hosted CXMeter instances
          (unless otherwise noted in the Customer&apos;s contract) or specified
          in the application specific Service Description.
        </p>
        <table className="saas__table">
          <thead>
            <tr>
              <th>Service Type</th>
              <th>Backup Frequency</th>
              <th>Backup Retention Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Production</th>
              <td>
                Local every 24 hours
                <hr />
                Remote replicated within 24 hours of the backup
              </td>
              <td>
                Local 30 days
                <hr />
                Remote 0 days
              </td>
            </tr>
            <tr>
              <th>Non-Production (ex: development, QA, training)</th>
              <td>
                Local every 24 hours
                <hr />
                No Remote Backup
              </td>
              <td>
                Local 30 days
                <hr />
                None
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Local backups are stored at the same location as the running instance.
          Remote backups stored at a location outside of the primary data
          center&apos;s disaster radius and are typically utilized for disaster
          recovery. <br />
          If the Service is terminated, data will be archived for 30 days from
          the date of termination. After the 30-day period, the data will be
          destroyed and cannot be recovered. The Customer can request a copy of
          the archived data prior to or during the 30-day period. The request
          should be made through email (with confirmation of receipt) to your
          CXMeter service manager. <br />
          Recovered databases may contain URLs and file paths to locations
          within the CXMeter Cloud that are no longer accessible by the
          Customer.
        </p>
        <strong>Data Recovery and Data Recovery Requests</strong>
        <p>
          Backup recovery is required when data is either corrupted or deleted;
          or for specific application/project requests. It should be noted that
          data recovery can take anywhere from minutes to hours based on the
          type of data. A file can usually be restored quickly; however, a full
          database may take hours. <br />
          For Production instances, data recovery will be considered a severity
          1 event (loss of critical functionality or more than 10% of the Users
          cannot access the Service) unless the Service is down and there is no
          viable workaround (severity 0). For non-Production instances, data
          recovery will be considered a severity 3 event (Issue impacts
          operations and/or efficiency) and will be recovered during normal
          business hours.
        </p>
        <strong>Customer Data Recovery Requests</strong>
        <p>
          Data recovery (restoration of backed up data or database usually due
          to accidental deletion) requires a special request and is not
          considered part of normal Service delivery. The Service includes one
          Customer-initiated data recovery event per year. For each additional
          request, the Customer will be charged standard Services rates. <br />
          Note: All CXMeter Cloud initiated recoveries to manage delivery are
          included in the Service.
        </p>
        <strong>(3) Business Continuity and Disaster Recovery</strong>
        <p>
          A disaster scenario will be evaluated if the primary Service Delivery
          is down and is not expected to be restored within a minimum of 48
          hours. The actual Service downtime before declaring a disaster
          switchover will likely be greater than 24 hours. <br />
          The Disaster Recovery process is tested on a yearly basis by invoking
          the recovery plan for a representative test environment. The testing
          does not introduce faults into the production environment, nor does it
          require downtime of production environments. The test is proctored by
          the Disaster Recovery Group Lead. <br />
          The table below lists the Recovery Time and Point Objectives (RTO and
          RPO) and backups for all hosted CXMeter instances (unless otherwise
          noted in the Customer&apos;s contract) or specified in the application
          specific Service Description. Disaster recovery only covers Production
          instances.
        </p>
        <table className="saas__table">
          <thead>
            <tr>
              <th>Systems</th>
              <th>Recovery Point Objective</th>
              <th>Recovery Time Objective</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Production</th>
              <td>24 Hours</td>
              <td>5 Business Days</td>
            </tr>
            <tr>
              <th>Development, QA, others</th>
              <td>None</td>
              <td>None</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default SaasAgreement;
