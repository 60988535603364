import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

const editProfileValidationSchema = () => {
  return Yup.object().shape({
    first_name: Yup.string()
      .requiredTrimmed(t('blank', 'firstName'), t('required', 'firstName'))
      .matches(onlyLettersAndSpacesRegex, t('lettersOnly', 'firstName'))
      .range(
        2,
        64,
        t('minLength', 'firstName', 2),
        t('maxLength', 'firstName', 64),
      ),

    last_name: Yup.string()
      .requiredTrimmed(t('blank', 'lastName'), t('required', 'lastName'))
      .matches(onlyLettersAndSpacesRegex, t('lettersOnly', 'lastName'))
      .range(
        2,
        64,
        t('minLength', 'lastName', 2),
        t('maxLength', 'lastName', 64),
      ),
  });
};

export default editProfileValidationSchema;
