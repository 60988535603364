import { URLS } from '.';
import {
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
  TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
} from './urlConstants';

/// /////// FEEDBACK FORMS ////////////////

const CUSTOM_FORMS = 'custom-forms';
const TEMPLATES_FORMS = 'template-forms';
const SURVEY_DETAIL = 'survey-detail';
const FEEDBACK_FORMS_DETAIL_TAB = [
  {
    id: SURVEY_DETAIL,
    name: 'Survey Detail',
    hideSearchFilter: true,
    isSubTab: true,
  },
];

export const FEEDBACK_FORMS_TABS = [
  {
    id: CUSTOM_FORMS,
    route: CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
    name: 'Custom Forms',
    isActiveSubTab: false,
    subTabs: FEEDBACK_FORMS_DETAIL_TAB,
  },
  {
    id: TEMPLATES_FORMS,
    route: TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
    name: 'CXMeter Templates',
    isActiveSubTab: false,
    subTabs: FEEDBACK_FORMS_DETAIL_TAB,
  },
];

/// /////// SURVEYS ////////////////

const SURVEYS = 'surveys';

export const QR_LIST_TABS = [
  {
    id: SURVEYS,
    route: URLS.SURVEYS_URL,
    name: 'Surveys',
    isActiveSubTab: false,
    hideSearchFilter: true,
    subTabs: [],
  },
];

/// /////// LOCATIONS AND REGIONS ////////////////

export const REGIONS_TAB = 'regions';
export const LOCATIONS_TAB = 'locations';

export const LOCATIONS_AND_REGIONS_TABS = [
  {
    id: LOCATIONS_TAB,
    route: URLS.MANAGE_LOCATIONS_SETTINGS_URL,
    name: 'Locations',
    isActiveSubTab: false,
    subTabs: [
      {
        id: 'location-detail',
        heading: 'Location Information',
        subHeading: 'Manage and link customer experience with locations',
        hideSearchFilter: true,
        isSubTab: true,
      },
    ],
  },
  {
    id: REGIONS_TAB,
    route: URLS.MANAGE_REGIONS_SETTINGS_URL,
    name: 'Regions',
    isActiveSubTab: false,
    subTabs: [],
  },
];

/// /////// USERS ////////////////

const USERS = 'users';

export const USERS_TABS = [
  {
    id: USERS,
    route: URLS.USERS_URL,
    name: 'Users',
    isActiveSubTab: false,
    subTabs: [],
  },
];
