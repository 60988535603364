/* eslint-disable no-restricted-syntax */

/* eslint-disable react/jsx-filename-extension */
import { Link } from 'react-router-dom';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as ApartmentIcon } from 'assets/icons/icon_apartment.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/icon_locations.svg';
import { Icon } from 'components/ui';
import i18next from 'i18next';
import {
  CHANGE_PASSWORD,
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
  EDIT_USER_PROFILE_URL,
  FEEDBACK_FORMS_SETTINGS_URL,
  INVOICES_SETTINGS_URL,
  MANAGE_EMAILS_SETTINGS_INBOX_URL,
  MANAGE_EMAILS_SETTINGS_URL,
  MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL,
  MANAGE_LOCATIONS_SETTINGS_URL,
  PAYMENT_DASHBOARD_SETTINGS_URL,
  PAYMENT_PLANS_SETTINGS_URL,
  SURVEYS_URL,
  UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL,
  UPDATE_ORG_DETAILS_SETTINGS_URL,
  USERS_URL,
} from 'utils/constants/urlConstants';

const { t } = i18next;

export const getProfileSettingsMenuItems = () => [
  {
    key: 'edit-profile',
    label: (
      <Link
        className="setting-menu-link"
        to={EDIT_USER_PROFILE_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="account_circle" />
        <span>{t('profile.editProfile.title')}</span>
      </Link>
    ),
    activepaths: [EDIT_USER_PROFILE_URL],
  },
  {
    key: 'change-password',
    label: (
      <Link
        className="setting-menu-link"
        to={CHANGE_PASSWORD}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="key" />
        <span>{t('profile.changePassword.title')}</span>
      </Link>
    ),
    activepaths: [CHANGE_PASSWORD],
  },
];

export const getSettingsMenuItems = () => [
  {
    key: 'feedback-forms',
    label: (
      <Link
        className="setting-menu-link"
        to={CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="list_alt" />
        <span>{t?.('settings.feedbackForms.title')}</span>
      </Link>
    ),
    activepaths: [FEEDBACK_FORMS_SETTINGS_URL],
  },
  {
    key: 'qr-setup',
    label: (
      <Link
        className="setting-menu-link"
        to={SURVEYS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="qr_code" />
        <span>{t?.('settings.qrSetup.menuTitle')}</span>
      </Link>
    ),
    activepaths: [SURVEYS_URL],
  },
  {
    key: 'regions-and-locations',
    label: (
      <Link
        className="setting-menu-link"
        to={MANAGE_LOCATIONS_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <LocationIcon />
        <span>{t?.('settings.locations.title')}</span>
      </Link>
    ),
    activepaths: [MANAGE_LOCATIONS_AND_REGIONS_SETTINGS_URL],
  },
  {
    key: 'users',
    label: (
      <Link
        className="setting-menu-link"
        to={USERS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="group" />
        <span>{t?.('settings.users.title')}</span>
      </Link>
    ),
    activepaths: [USERS_URL],
  },
  {
    key: 'email-compaign',
    label: (
      <Link
        className="setting-menu-link"
        to={MANAGE_EMAILS_SETTINGS_INBOX_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon style={{ marginTop: 3 }} type="outgoing_mail" />
        <span>{t?.('settings.manageEmails.title')}</span>
      </Link>
    ),
    activepaths: [MANAGE_EMAILS_SETTINGS_URL],
  },
  {
    key: 'organization-settings',
    label: (
      <Link className="setting-menu-link" to={UPDATE_ORG_DETAILS_SETTINGS_URL}>
        <ApartmentIcon />
        <span>{t?.('settings.organization.title')}</span>
      </Link>
    ),
    children: [
      {
        key: 'organization-detail',
        label: (
          <Link
            className="setting-menu-child-item"
            to={UPDATE_ORG_DETAILS_SETTINGS_URL}
            state={{ isSettingMenuLink: true }}
          >
            <FontAwesomeIcon icon={faCircle} />
            <span>{t?.('settings.organization.details.menuTitle')}</span>
          </Link>
        ),
        activepaths: [UPDATE_ORG_DETAILS_SETTINGS_URL],
      },
      {
        key: 'organization-config',
        label: (
          <Link
            className="setting-menu-child-item"
            to={UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL}
            state={{ isSettingMenuLink: true }}
          >
            <FontAwesomeIcon icon={faCircle} />
            <span>{t?.('settings.organization.configurations.menuTitle')}</span>
          </Link>
        ),
        activepaths: [UPDATE_ORG_CONFIGURATIONS_SETTINGS_URL],
      },
    ],
  },
  {
    key: 'payment-dashboard',
    label: (
      <Link
        className="setting-menu-link"
        to={PAYMENT_DASHBOARD_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="account_balance" />
        <span>{t?.('settings.payments.menuTitle')}</span>
      </Link>
    ),
    activepaths: [PAYMENT_DASHBOARD_SETTINGS_URL, PAYMENT_PLANS_SETTINGS_URL],
  },
  {
    key: 'invoices',
    label: (
      <Link
        className="setting-menu-link"
        to={INVOICES_SETTINGS_URL}
        state={{ isSettingMenuLink: true }}
      >
        <Icon type="receipt_long" />
        <span>{t?.('settings.invoices.title')}</span>
      </Link>
    ),
    activepaths: [INVOICES_SETTINGS_URL],
  },
];

export const settingMenuOpenKeysList = getSettingsMenuItems().map(
  (menu) => menu.key,
);

export const findSettingsActiveMenu = (items, pathname) => {
  for (const item of items) {
    const isActivePath = (item.activepaths ?? []).find((path) =>
      pathname.includes(path),
    );
    if (isActivePath) {
      return item.key;
    }
    if (item.children) {
      const childKey = findSettingsActiveMenu(item.children, pathname);
      if (childKey) {
        return childKey;
      }
    }
  }
  return null;
};
