import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import { getInviteGroups } from 'redux/emailManagement/emailManagementActions';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';
import { getGroupsColumns } from 'utils/helpers/emailsManagementHelper';

function Groups() {
  const dispatch = useDispatch();
  const {
    inviteGroups: { data: inviteGroups, loading },
  } = useSelector((s) => s.emailManagement);

  const { params, onChangePagination } = useContext(SettingsPageContext);
  const {
    inviteGroupIdRef,
    setIsOpenGroupModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(ManageEmailsContext);

  const editInviteGroupHandler = (inviteGroup) => {
    inviteGroupIdRef.current = inviteGroup.id;
    setIsOpenGroupModal(true);
  };

  const composeEmailHandler = (group) => {
    inviteEmailDataRef.current = { invite: { groups: [group] } };
    setIsOpenSendEmailModal(true);
  };

  useEffect(() => {
    dispatch(getInviteGroups({ ...params }));
  }, [params]);

  return (
    <div>
      <Table
        className="left-align"
        scroll={{ x: 'max-content' }}
        columns={getGroupsColumns(editInviteGroupHandler, composeEmailHandler)}
        dataSource={attachKeysToDataSource(inviteGroups.results)}
        loading={loading}
        pagination={{
          ...getAntdPaginationProps({
            data: inviteGroups,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
    </div>
  );
}

export default Groups;
