import { Icon } from '..';

import './modal.scss';

function WarningModal({
  onClose,
  isOpen = true,
  className = '',
  title,
  subTitle,
  primaryAction,
  primaryActionTitle = 'Yes',
  primaryActionIcon,
  primaryActionLoading = false,
  disablePrimaryAction,
  secondaryAction,
  secondaryActionTitle = 'No',
  secondaryActionIcon,
  secondaryActionLoading = false,
  disableSecondaryAction,
  wrapperStyles = {},
  bodyStyles = {},
}) {
  return !isOpen ? null : (
    <div style={wrapperStyles} className={`warning-modal ${className}`}>
      <div className="modal-content" style={bodyStyles}>
        {onClose ? (
          <button onClick={onClose} className="close-modal-icon">
            <Icon type="close" />
          </button>
        ) : null}
        {title ? <h2 className="modal-title">{title}</h2> : null}
        {typeof subTitle === 'string' ? (
          <p className="modal-message">{subTitle}</p>
        ) : (
          <div>{subTitle}</div>
        )}
        {secondaryAction || primaryAction ? (
          <div className="modal-actions">
            {secondaryAction ? (
              <button
                disabled={disableSecondaryAction}
                aria-busy={secondaryActionLoading}
                onClick={secondaryAction}
                className="cstm-btn secondary-cstm-btn"
              >
                {secondaryActionIcon}
                {secondaryActionTitle}
              </button>
            ) : null}
            {primaryAction ? (
              <button
                disabled={disablePrimaryAction}
                aria-busy={primaryActionLoading}
                onClick={primaryAction}
                className="cstm-btn primary-cstm-btn"
              >
                {primaryActionIcon}
                <span>{primaryActionTitle}</span>
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default WarningModal;
