import { Tooltip } from 'antd';

function FeedbackFormSelector({
  selectedFeedbackForm,
  openFeedbackTemplates,
  canAttachFeedbackForm,
}) {
  return (
    <Tooltip
      getPopupContainer={(t) => t.parentElement}
      title={
        canAttachFeedbackForm ? '' : 'Please select a text to link survey.'
      }
    >
      <button
        disabled={!canAttachFeedbackForm}
        className={`cstm-btn tertiary-cstm-btn feedback-template-selector text-ellipsis ${
          selectedFeedbackForm ? 'selected' : ''
        }`}
        onClick={selectedFeedbackForm ? null : openFeedbackTemplates}
      >
        {selectedFeedbackForm ? null : (
          <img src="/assets/icons/icon_feedbackForm.svg" alt="Feedback" />
        )}
        <span className="text-ellipsis">
          {selectedFeedbackForm ?? 'Attach Feedback Form'}
        </span>
        {selectedFeedbackForm ? (
          <span
            role="presentation"
            onClick={openFeedbackTemplates}
            className="replace-feedback-form"
          >
            Replace
          </span>
        ) : null}
      </button>
    </Tooltip>
  );
}

export default FeedbackFormSelector;
