import * as Yup from 'yup';

import translateFieldError from './validationTranslations';

const t = translateFieldError;

const changePasswordSchema = (isGoogleUser) => {
  return Yup.object().shape({
    old_password: Yup.string().when([], {
      is: () => isGoogleUser === true,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(t('required', 'oldPassword')),
    }),
    new_password: Yup.string()
      .required(t('required', 'newPassword'))
      .min(8, t('minLengthPassword', 'newPassword', 8))
      .max(128, t('maxLengthPassword', 'newPassword', 128))
      .matches(/\D/, t('numbersOnlyNotAllowed', 'newPassword')),
    confirm_password: Yup.string()
      .required(t('required', 'confirmPassword'))
      .oneOf([Yup.ref('new_password'), null], t('passwordsMustMatch')),
  });
};

export default changePasswordSchema;
