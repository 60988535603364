import { useEffect, useRef } from 'react';

const useInfiniteScroll = ({ fetchData, hasMore, loading, threshold = 50 }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || loading || !hasMore) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Check if scrolled to the bottom within a threshold
      if (scrollHeight - scrollTop - clientHeight <= threshold) {
        fetchData();
      }
    };

    const element = containerRef.current;
    if (element) element.addEventListener('scroll', handleScroll);

    return () => {
      if (element) element.removeEventListener('scroll', handleScroll);
    };
  }, [fetchData, hasMore, loading]);

  return { containerRef };
};

export default useInfiniteScroll;
