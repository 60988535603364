import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { entityListAPICallV2 } from 'apis/cxmeter';
import { fetchPaymentCostsApiCall } from 'apis/payments';
import { OrderSummary } from 'components';
import { LocationThumbnail } from 'components/locationThumbnail';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { PAYMENT_SETUP_STEP, URLS } from 'utils/constants';
import { showErrorToast } from 'utils/helpers';

export default function BranchActivation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [costs, setCosts] = useState();
  const [selectedBranches, setSelectedBranches] = useState([]);
  const {
    state: { paymentMethod, paymentInfo, ...state },
  } = useLocation();

  const selectBranch = (id) => {
    if (selectedBranches.includes(id)) {
      setSelectedBranches([...selectedBranches.filter((o) => o !== id)]);
    } else {
      setSelectedBranches([...selectedBranches, id]);
    }
  };

  const fetchCosts = async () => {
    try {
      setLoading(true);
      const costRes = await fetchPaymentCostsApiCall(state.plan.id);
      setCosts(costRes);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showErrorToast(e);
    }
  };

  const handleBranchActivation = () => {
    navigate(URLS.PAYMENT_URL, {
      state: {
        selectedBranches,
        plan: state.plan,
        costs,
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    entityListAPICallV2()
      .then((data) => {
        setLoading(false);
        setBranches(data);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!state?.plan) {
      navigate(URLS.PAYMENT_PLANS_URL);
    } else {
      fetchCosts();
    }
  }, []);

  useEffect(() => {
    setSelectedBranches(branches.map(({ id }) => id));
  }, [branches]);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={PAYMENT_SETUP_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.payment.selectLocations', {
            location: branches.length > 1 ? 'locations' : 'location',
          })}
        >
          <div className="branches">
            {branches.map((branch) => (
              <LocationThumbnail
                key={branch.id}
                onSelect={() => selectBranch(branch.id)}
                selected={selectedBranches.includes(branch.id)}
              >
                {branch.name}
              </LocationThumbnail>
            ))}
          </div>

          {selectedBranches.length > (state.plan.max_branches ?? Infinity) ? (
            <p className="control-error">
              {t('onboarding.payment.allowedLocations', {
                count: state.plan.max_branches,
              })}
            </p>
          ) : null}
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          isLoading={loading}
          onClickBack={() => navigate(-1)}
          disableContinue={
            selectedBranches.length === 0 ||
            selectedBranches.length > (state.plan.max_branches ?? Infinity)
          }
          onClickContinue={handleBranchActivation}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {costs ? (
          <OrderSummary
            plan={state.plan}
            costs={_.update(costs, 'items', (items) =>
              items.map((item) =>
                item.key === 'locations'
                  ? { ...item, qty: selectedBranches.length }
                  : item,
              ),
            )}
          />
        ) : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
