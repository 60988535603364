import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Locations from 'components/locationsAndRegions/Locations/Locations';
import RegionModal from 'components/locationsAndRegions/Regions/RegionModal';
import Regions from 'components/locationsAndRegions/Regions/Regions';
import { SettingsPageContext } from 'contexts';
import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import { LocationDetailForm } from 'pages';
import {
  LOCATIONS_AND_REGIONS_TABS,
  LOCATIONS_TAB,
  REGIONS_TAB,
} from 'utils/constants/settingsPage';
import { MANAGE_LOCATIONS_SETTINGS_URL } from 'utils/constants/urlConstants';

export const LocationAndRegionContext = createContext({});

function LocationAndRegionsData() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeTab } = useContext(SettingsPageContext);
  const [isOpenRegionModal, setIsOpenRegionModal] = useState(false);
  const fetchLocationsRef = useRef();
  const regionDataRef = useRef();

  const LocationAndRegionContextValues = useMemo(
    () => ({
      isOpenRegionModal,
      setIsOpenRegionModal,
      fetchLocationsRef,
      regionDataRef,
    }),
    [isOpenRegionModal, fetchLocationsRef, setIsOpenRegionModal, regionDataRef],
  );

  const createLocationHandler = () => {
    navigate(`${MANAGE_LOCATIONS_SETTINGS_URL}/new`);
  };

  return (
    <LocationAndRegionContext.Provider value={LocationAndRegionContextValues}>
      <SettingsPageLayout
        pageTitle={t('settings.locations.title')}
        pageSubTitle={t('settings.locations.subTitle')}
        tabs={LOCATIONS_AND_REGIONS_TABS}
      >
        {{
          generalAction: activeTab.isActiveSubTab ? null : (
            <div className="controls">
              <button
                className="cstm-btn secondary-cstm-btn"
                size="large"
                onClick={() => setIsOpenRegionModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('settings.locations.addRegion')}</span>
              </button>
              <button
                className="cstm-btn primary-cstm-btn"
                size="large"
                onClick={createLocationHandler}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('settings.locations.addLocation')}</span>
              </button>
            </div>
          ),
          currentTabContent: (
            <Routes>
              <Route index element={<Navigate to={LOCATIONS_TAB} replace />} />
              <Route path={LOCATIONS_TAB} element={<Locations />}>
                <Route path=":locationId" element={<LocationDetailForm />} />
              </Route>
              <Route path={REGIONS_TAB} element={<Regions />} />
              <Route
                path="*"
                element={<Navigate to={LOCATIONS_TAB} replace />}
              />
            </Routes>
          ),
        }}
      </SettingsPageLayout>
      {isOpenRegionModal ? <RegionModal /> : null}
    </LocationAndRegionContext.Provider>
  );
}

function LocationsAndRegions() {
  return (
    <SettingsPageContextProvider tabs={LOCATIONS_AND_REGIONS_TABS}>
      <LocationAndRegionsData />
    </SettingsPageContextProvider>
  );
}

export default LocationsAndRegions;
