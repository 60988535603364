import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Table } from 'antd';
import { get, omit } from 'lodash';
import qs from 'qs';

import { Chips, Filters } from 'components';
import ReviewPanel from 'components/reviewsListing/ReviewPanel';
import { getReviews } from 'redux/reviewsListing/reviewsListingActions';
import { REVIEWS_LISTING } from 'utils/constants';
import { OPTION_TYPE_MAPPING } from 'utils/constants/questionTypes';
import { attachKeysToDataSource, getFilters } from 'utils/helpers';
import { filtersFromQueryParams } from 'utils/helpers/reviewsHelpers';
import { useEffectAfterMount, usePageTitle } from 'utils/hooks';
import useOrdering from 'utils/hooks/useOrdering';

import { reviewListingColumns } from './reviewsListingColumns';

import './_reviewsListing.scss';

function ReviewsListing() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [filterStore, setFilterStore] = useState(() => {
    const params = Object.fromEntries(searchParams);
    return filtersFromQueryParams(params);
  });
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(null);
  const [showReviewPanelNav, setShowReviewPanelNav] = useState(true);
  const [pageControl, setPageControl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, count } = useSelector(
    (state) => state.reviews.reviewsList,
  );
  const [ordering, updateSorting, getOrderingForTable] = useOrdering([]);

  usePageTitle(REVIEWS_LISTING);

  const onView = (index) => {
    setCurrentReview(data[index].id);
    setCurrentReviewIndex(index);
    setShowReviewPanelNav(true);
    setShowDetailPanel(true);
  };

  const onFilterChange = (val) => {
    navigate(
      {
        search: qs.stringify({ ...getFilters(val), last_viewed_id: null }),
      },
      { replace: true },
    );
  };

  const parseExtras = (pl) => {
    let res = {};
    if (pl.type) {
      res = {
        [OPTION_TYPE_MAPPING[pl.type]]: pl.option_id,
        ...omit(pl, [
          'entity',
          'experience',
          'segment',
          'status',
          'start_date',
          'end_date',
          'option_id',
          'type',
        ]),
      };
    }
    return res;
  };

  const renameDateKeys = (filtersObj) => {
    if (get(filtersObj, 'start_date') && get(filtersObj, 'end_date')) {
      filtersObj['date_range[0]'] = get(filtersObj, 'start_date');
      filtersObj['date_range[1]'] = get(filtersObj, 'end_date');
    }
    return omit(filtersObj, ['start_date', 'end_date']);
  };

  const onSortChange = (pagination, filters, sorter) => {
    updateSorting(sorter);
  };

  useEffectAfterMount(() => {
    const params = Object.fromEntries(searchParams);
    setPageControl(null);
    setFilterStore({ ...filterStore, ...filtersFromQueryParams(params) });
  }, [searchParams]);

  useEffect(() => {
    const allFilters = {
      ...parseExtras(Object.fromEntries(searchParams)),
      ...renameDateKeys(getFilters(filterStore)),
    };
    if (!Object.fromEntries(searchParams).last_viewed_id) {
      delete allFilters.last_viewed_id;
    }
    dispatch(
      getReviews({
        ordering: `${ordering.join(',')}`,
        ...pageControl,
        ...allFilters,
      }),
    );
  }, [filterStore, ordering, pageControl]);

  useEffect(() => {
    if (showDetailPanel) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [showDetailPanel]);

  useEffect(() => {
    const openFeedbackId = searchParams.get('openFeedback');
    if (openFeedbackId) {
      setCurrentReview(openFeedbackId);
      setShowReviewPanelNav(false);
      setShowDetailPanel(true);
    }
  }, [searchParams]);

  return (
    <main>
      <section className="header">
        <div>
          <h1>{t('feedbacks.title')}</h1>
        </div>
      </section>

      <section className="filtersWrapper">
        <p> {t('filters.title')}</p>
        <Filters
          value={filterStore}
          doFetchFiltersData
          onChange={onFilterChange}
        />
      </section>
      <section className="chipsWrapper">
        <Chips filters={filterStore} onDelete={onFilterChange} />
      </section>

      <section className="tableWrapper">
        <Table
          loading={isLoading && { indicator: <Spinner /> }}
          columns={reviewListingColumns(onView, getOrderingForTable(ordering))}
          scroll={{ x: 'max-content' }}
          dataSource={attachKeysToDataSource(data)}
          onChange={onSortChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onView(rowIndex),
            };
          }}
          pagination={{
            current: pageControl?.page ?? 1,
            total: count,
            defaultPageSize: 10,
            onChange: (page, pageSize) =>
              setPageControl({ page, size: pageSize }),
            showSizeChanger: false,
          }}
          className="feedback-table"
        />

        <ReviewPanel
          isOpen={showDetailPanel}
          setIsOpen={setShowDetailPanel}
          reviewId={currentReview}
          reviewIndex={currentReviewIndex}
          totalReviews={data.length}
          handleView={onView}
          showNav={showReviewPanelNav}
        />
      </section>
    </main>
  );
}

export default ReviewsListing;
