import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';
import { isEmpty } from 'lodash';

import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import { listInvoices } from 'redux/payments/paymentsActions';
import { URLS } from 'utils/constants';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';
import useOrdering from 'utils/hooks/useOrdering';

import { invoicesColumns } from './columns';

import './_invoices.scss';

const INVOICES = 'invoices';

export const INVOICES_TABS = [
  {
    id: INVOICES,
    route: URLS.INVOICES_SETTINGS_URL,
    name: 'Invoices',
    isActiveSubTab: false,
    hideSearchFilter: true,
    subTabs: [],
  },
];

function Invoices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    invoices: { data: invoices, loading },
  } = useSelector((s) => s.payments);

  const [pageControl, setPageControl] = useState(null);
  const [ordering, updateSorting, getOrderingForTable] = useOrdering([]);

  const onInvoiceClick = (rec) => {
    window.open(rec.pdf_url, '_blank');
  };

  const onChange = (pagination, filters, sorter) => {
    if (!isEmpty(sorter)) {
      updateSorting(sorter);
    }
  };

  useEffect(() => {
    dispatch(
      listInvoices({
        ordering: `${ordering.join(',')}`,
        ...pageControl,
      }),
    );
  }, [ordering, pageControl]);

  return (
    <SettingsPageLayout
      pageTitle={t('settings.invoices.title')}
      pageSubTitle={t('settings.invoices.subTitle')}
      tabs={INVOICES_TABS}
    >
      {{
        currentTabContent: (
          <div className="invoice-list-table">
            <Table
              loading={loading}
              className="left-align receive-inbox-table"
              columns={invoicesColumns(
                onInvoiceClick,
                getOrderingForTable(ordering),
              )}
              dataSource={attachKeysToDataSource(invoices.results)}
              onChange={onChange}
              pagination={{
                ...getAntdPaginationProps({
                  data: invoices,
                  currentPage: pageControl?.page ?? 1,
                  onChangePagination: (page, pageSize) =>
                    setPageControl({ page, size: pageSize }),
                }),
              }}
            />
          </div>
        ),
      }}
    </SettingsPageLayout>
  );
}

function ManageInvoices() {
  return (
    <SettingsPageContextProvider tabs={INVOICES_TABS}>
      <Invoices />
    </SettingsPageContextProvider>
  );
}

export default ManageInvoices;
