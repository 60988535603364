import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useFormik } from 'formik';

import { LoginWithGoogle } from 'components';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { LOGIN, URLS } from 'utils/constants';
import { populateFormikErrors } from 'utils/helpers';
import { useLogin, usePageTitle } from 'utils/hooks';
import { loginSchema } from 'utils/validations';

function Login() {
  const { t } = useTranslation();
  const location = useLocation();
  const { loading, login } = useLogin();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await login(values);
      } catch (errors) {
        if (errors) {
          populateFormikErrors(errors, formik);
        }
      }
    },
    validationSchema: loginSchema,
  });

  usePageTitle(LOGIN);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Login">
          <div className="auth-content">
            <LoginWithGoogle />
            <div className="auth-divider">{t('or')}</div>
            <h2 className="onboarding-field-title">{t('login.withEmail')}</h2>
            <Form onSubmit={formik.handleSubmit} className="auth-form">
              <FormikLabeledInput
                formik={formik}
                type="email"
                inputName="email"
                placeholder={t('inputFields.emailAddress')}
              />
              <FormikLabeledInput
                formik={formik}
                type="password"
                inputName="password"
                placeholder={t('inputFields.password')}
              />
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <Form.Label className="textRight">
                <Link to={URLS.FORGOT_PASSWORD_URL}>
                  {t('login.forgotPassword')}
                </Link>
              </Form.Label>
              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                {t('login.cta')}
              </button>
            </Form>

            <span className="askLogin">
              {t('login.switchToSignUp')}{' '}
              <Link to={URLS.SIGNUP_URL}>{t('signup.title')}</Link>
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}

export default Login;
