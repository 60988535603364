import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Filters } from 'components';
import HorizantalTabs from 'components/tabs/HorizantalTabs';
import SettingsPageContext from 'contexts/SettingsPageContext';

import './_settingsPageLayout.scss';

function SettingsPageLayout({ tabs, pageTitle, pageSubTitle, children }) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    searchFilter,
    setSearchFilter,
    subTabsSearch,
    setSubTabsSearch,
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    subTabHandler,
    settingsLayoutContainerRef,
  } = useContext(SettingsPageContext);

  const handleBackAction = () => {
    const redirectTo = state?.redirectParams?.next; // To handle redirection for Location detail page.
    if (redirectTo) {
      navigate(redirectTo, {
        state,
      });
    } else {
      subTabHandler({ isActive: false });
    }
  };

  return (
    <div
      ref={settingsLayoutContainerRef}
      className="settings-page-layout-container"
    >
      <div className="settings-page-layout-header">
        <div className="heading">
          {activeTab?.isActiveSubTab ? (
            <div>
              <button
                className="cstm-btn text-cstm-btn"
                style={{ fontSize: 14 }}
                onClick={handleBackAction}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('back')}</span>
              </button>
              {activeSubTab.heading ? (
                <h3 style={{ marginTop: '0.75rem', marginBottom: '0.25rem' }}>
                  {activeSubTab.heading}
                </h3>
              ) : null}
              {activeSubTab.subHeading ? (
                <p style={{ marginBottom: '0.5rem' }}>
                  {activeSubTab.subHeading}
                </p>
              ) : null}
            </div>
          ) : (
            <>
              <h3>{pageTitle}</h3>
              <p>{pageSubTitle}</p>
            </>
          )}
        </div>
        {/* Page general actions container */}
        {children.generalAction}
      </div>
      {activeTab.isActiveSubTab ? (
        <HorizantalTabs
          tabs={activeTab.subTabs}
          setActiveTab={setActiveSubTab}
          activeTab={activeSubTab}
        />
      ) : (
        <HorizantalTabs
          tabs={tabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      )}
      {activeTab.hideSearchFilter || activeSubTab?.hideSearchFilter ? null : (
        <div className="settings-page-layout-table-search">
          <Filters
            value={activeTab.isActiveSubTab ? subTabsSearch : searchFilter}
            addMainWrapper={false}
            onChange={
              activeTab.isActiveSubTab ? setSubTabsSearch : setSearchFilter
            }
          />
          {/* Current Tab Actions container */}
          {children.currentTabActions}
        </div>
      )}
      <div className="settings-page-layout-tabs-content">
        {/* Current Tab Content */}
        {children.currentTabContent}
      </div>
    </div>
  );
}

export default SettingsPageLayout;
